import { injectReducer } from 'src/reducers/root'
import { INTRASTAT_ROUTE_PATH } from './constants'
import declarationRoute from './declaration'

export default store => ({
  // Intrastat declarations list is the default component
  path: INTRASTAT_ROUTE_PATH,
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: "intrastatList" */ './container'),
      import(/* webpackChunkName: "intrastatList" */ './reducer'),
      import(/* webpackChunkName: "intrastatList" */ './list/reducer'),
    ]).then(([containerModule, commonReducerModule, listReducerModule]) => {
      injectReducer(store, { key: 'intrastat.common', reducer: commonReducerModule.default })
      injectReducer(store, { key: 'intrastat.list', reducer: listReducerModule.default })
      next(null, containerModule.default)
    })
  },
  childRoutes: [
    declarationRoute(store),
  ],
})
