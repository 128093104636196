/* eslint-disable max-len */
import { defineMessages } from 'react-intl'
import commonPermitsMessages from '../messages'

const messages = defineMessages({
  messageCustomerHimselfTitle: {
    id: 'permits.messageCustomerHimselfTitle',
    description: '',
    defaultMessage: '?? Kuka tuottaa ohjelmiston?',
  },
  messageCustomerHimselfYes: {
    id: 'permits.messageCustomerHimselfYes',
    description: '',
    defaultMessage: '?? Hakija tuottaa ohjelmiston itse',
  },
  messageCustomerHimselfNo: {
    id: 'permits.messageCustomerHimselfNo',
    description: '',
    defaultMessage: '?? Hakija ostaa ohjelmiston muualta',
  },
  attention: {
    id: 'permits.attention',
    description: 'Attention text for general use',
    defaultMessage: '?? Attention',
  },
  nextStep: {
    id: 'permits.nextStep',
    description: 'Button for moving from one permits step to next one',
    defaultMessage: '?? Next',
  },
  previousStep: {
    id: 'permits.previousStep',
    description: 'Button for moving from one permits step to previous one',
    defaultMessage: '?? Previous',
  },
  exit: {
    id: 'permits.exit',
    description: 'Button for exiting from permits form',
    defaultMessage: '?? Exit',
  },
  exitPermit: {
    id: 'permits.exitPermit',
    description: 'Button for exiting from permits form',
    defaultMessage: '?? Exit Permit',
  },
  heading: {
    id: 'permits.heading',
    description: 'Main heading for permits app',
    defaultMessage: '?? Authorisation',
  },
  headingNew: {
    id: 'permits.heading.new',
    description: 'Main heading for permits form when creating new permit',
    defaultMessage: '?? Create authorisation',
  },
  applicationDraft: {
    id: 'permits.heading.applicationDraft',
    description: 'Main heading for draft application',
    defaultMessage: '?? Draft application',
  },
  headingNewApplication: {
    id: 'permits.heading.newApplication',
    description: 'Main heading for new application',
    defaultMessage: '?? First application',
  },
  headingAmendmentApplication: {
    id: 'permits.heading.amendmentApplication',
    description: 'Main heading for amendment application',
    defaultMessage: '?? Amendment application draft',
  },
  headingRenewalApplication: {
    id: 'permits.heading.renewalApplication',
    description: 'Main heading for renewal application',
    defaultMessage: '?? Application for renewal of the authorisation',
  },
  headingRevokeApplication: {
    id: 'permits.heading.revokeApplication',
    description: 'Main heading for revoke application',
    defaultMessage: '?? Application for revoke of the authorisation',
  },
  headingRejoinderApplication: {
    id: 'permits.heading.rejoinderApplication',
    description: 'Main heading for rejoinder application',
    defaultMessage: '?? Application for rejoinder of the authorisation',
  },
  headingApplication: {
    id: 'permits.heading.headingApplication',
    description: 'Main heading for submitted application',
    defaultMessage: '?? Authorisation application',
  },
  headingPermit: {
    id: 'permits.heading.headingPermit',
    description: 'Main heading for permit',
    defaultMessage: '?? Authorisation',
  },
  headingDecisionProposal: {
    id: 'permits.heading.headingDecisionProposal',
    description: 'Main heading for decision proposal',
    defaultMessage: '?? Decision proposal',
  },
  readOnlyHeading: {
    id: 'permits.readOnlyPanelHeading',
    description: 'Panel heading for read only permit view',
    defaultMessage: '?? Authorisation {permitId}',
  },
  status: {
    id: 'permits.status',
    description: 'permits status',
    defaultMessage: '?? Status',
  },
  fetchingPermit: {
    id: 'permits.permit.fetching',
    description: 'Information message for permit loading status',
    defaultMessage: '?? Fetching authorisation...',
  },
  statusIncorrect: {
    id: 'permits.headers.permitStatusIncorrect',
    description: 'Warning message for permits permit status incorrect',
    defaultMessage: '?? This authorisation has status incorrect.',
  },
  permitId: {
    id: 'permits.confirmation.permitId',
    description: 'Label for permits permit id',
    defaultMessage: '?? Statistical number',
  },
  dateCreated: {
    id: 'permits.confirmation.dateCreated',
    description: 'Label for permits permit creation date',
    defaultMessage: '?? Created',
  },
  stateDraft: {
    id: 'permits.heading.draft',
    description: 'Permit is in state draft',
    defaultMessage: '?? Draft',
  },
  errorFetchingPermit: {
    id: 'permits.headers.errorFetchingPermit',
    description: 'Message for error in fetching permit',
    defaultMessage: '?? Could not fetch permit data',
  },
  printView: {
    id: 'permits.summary.printView',
    description: 'Print view button label in summary',
    defaultMessage: '?? Print view',
  },
  print: {
    id: 'permits.print',
    description: 'Print button in permit',
    defaultMessage: '?? Print',
  },
  close: {
    id: 'permits.close',
    description: 'Close button in permit',
    defaultMessage: '?? Close',
  },
  submitForm: {
    id: 'permits.form.submit',
    description: 'Text in "submit form" button',
    defaultMessage: '?? Submit',
  },
  sameAsApplicationContactPersonText: {
    id: 'permits.form.sameAsApplicationContactPersonText',
    description: 'Text for radio button',
    defaultMessage: '?? Same as application contact person',
  },
  otherPerson: {
    id: 'permits.form.otherPerson',
    description: 'Text for radio button',
    defaultMessage: '?? Other person',
  },
  otherCompany: {
    id: 'permits.form.otherCompany',
    description: 'Text for radio button',
    defaultMessage: '?? Other company',
  },
  noInput: {
    id: 'permits.form.noInput',
    description: 'Text for radio button',
    defaultMessage: '?? Already informed',
  },
  customerNoEoriText: {
    id: 'permits.customer.noEoriText',
    description: 'Text in modal',
    defaultMessage: '?? Customer doesn\'t have EORI.',
  },
  continue: {
    id: 'permits.customer.continue',
    description: 'Text in modal button',
    defaultMessage: '?? Continue',
  },
  missingEori: {
    id: 'customer.missingEori',
    description: 'Message shown when customer EORI is missing',
    defaultMessage: '?? Missing EORI number',
  },
  loadingCustomerInformation: {
    id: 'customer.loading',
    description: 'Message shown when loading customer information',
    defaultMessage: '?? Loading customer information...',
  },
  loadingApplicationStructure: {
    id: 'permits.loadingApplicationStructure',
    description: 'Message shown when loading application structure',
    defaultMessage: '?? Loading application structure...',
  },
  loadingPermit: {
    id: 'permits.loadingPermit',
    description: 'Message shown when loading permit',
    defaultMessage: '?? Loading permit...',
  },
  initializeError: {
    id: 'permits.initializeError',
    description: 'Text in modal',
    defaultMessage: '?? An error occurred while initializing form. Please try again later.',
  },
  fetchCustomerError: {
    id: 'permits.fetchCustomerError',
    description: 'Text in modal',
    defaultMessage: '?? Could not fetch customer information.',
  },
  stepMissingRequiredFields: {
    id: 'permits.stepMissingRequiredFields',
    description: 'Text for missing fields notification',
    defaultMessage: '?? Missing required fields',
  },
  stepErrors: {
    id: 'permits.stepErrors',
    description: 'Text for missing fields notification',
    defaultMessage: '?? There are errors in page. Please fix them to continue.',
  },
  closeWithoutSaving: {
    id: 'permits.closeWithoutSaving',
    description: 'Text for close button',
    defaultMessage: '?? Close without saving',
  },
  saveAndClose: {
    id: 'permits.saveAndClose',
    description: 'Text for save draft button',
    defaultMessage: '?? Save application draft',
  },
  savingApplicationDraft: {
    id: 'permits.savingApplicationDraft',
    description: 'Text for loader message',
    defaultMessage: '?? Saving application draft...',
  },
  savedApplicationDraft: {
    id: 'permits.savedApplicationDraft',
    description: 'Text in notification message',
    defaultMessage: '?? Saved a draft of the application',
  },
  savingApplicationDraftErrors: {
    id: 'permits.savingApplicationDraftErrors',
    description: 'Text for notification message',
    defaultMessage: '?? An error occurred while saving application draft. Please try again later.',
  },
  mandatory: {
    id: 'form.mandatory',
    description: 'Title text for mandatory form field icon',
    defaultMessage: '?? Mandatory',
  },
  validationErrors: {
    id: 'permits.validationErrors',
    description: 'Text in notification',
    defaultMessage: '?? An error occurred while sending application. There is some validation errors in your application.',
  },
  applicationSent: {
    id: 'permits.applicationSent',
    description: 'Text in notification',
    defaultMessage: '?? Application sent succesfully.',
  },
  deleteApplicationDraft: {
    id: 'permits.form.deleteDraft',
    description: 'Text in button to delete application draft',
    defaultMessage: '?? Delete draft',
  },
  applicationDraftDeleted: {
    id: 'permits.applicationDraftDeleted',
    description: 'Text in notification',
    defaultMessage: '?? Application draft deleted',
  },
  deleteErrors: {
    id: 'permits.deleteErrors',
    description: 'Text in notification',
    defaultMessage: '?? An error occurred while deleting application. Please try again later.',
  },
  cancelApplication: {
    id: 'permits.form.cancelApplication',
    description: 'Text in button to cancel application',
    defaultMessage: '?? Cancel application',
  },
  copyApplication: {
    id: 'permits.copyApplicationButtonText',
    description: 'Text in button to copy application',
    defaultMessage: '?? Apply again',
  },
  fixApplication: {
    id: 'permits.fixApplicationButtonText',
    description: 'Text in button to fix application',
    defaultMessage: '?? Fix application',
  },
  applicationCancelled: {
    id: 'permits.applicationCancelled',
    description: 'Text in notification',
    defaultMessage: '?? Application draft cancelled',
  },
  cancelModalCancel: {
    id: 'permits.cancelModalCancel',
    description: 'Cancel button text in modal',
    defaultMessage: '?? No, go back',
  },
  cancelErrors: {
    id: 'permits.cancelErrors',
    description: 'Text in notification',
    defaultMessage: '?? An error occurred while cancelling application. Please try again later.',
  },
  closeWithoutSavingModalCancel: {
    id: 'permits.closeWithoutSavingModalCancel',
    description: 'Cancel button text in modal',
    defaultMessage: '?? Cancel',
  },
  closeWithoutSavingModalProceed: {
    id: 'permits.closeWithoutSavingModalProceed',
    description: 'Continue button text in modal',
    defaultMessage: '?? Yes, close application',
  },
  closeWithoutSavingModalTitle: {
    id: 'permits.closeWithoutSavingModalTitle',
    description: 'Title of modal',
    defaultMessage: '?? Application has unsaved changes',
  },
  closeWithoutSavingModalMessage: {
    id: 'permits.closeWithoutSavingModalMessage',
    description: 'Message of modal',
    defaultMessage: '?? Are you sure you want to close the permit application? Unsaved changes will not be saved',
  },
  copyErrors: {
    id: 'permits.copyErrors',
    description: 'Text in notification',
    defaultMessage: '?? An error occurred while copying application. Please try again later.',
  },
  submitErrors: {
    id: 'permits.submitErrors',
    description: 'Text in notification',
    defaultMessage: '?? An error occurred while submitting application. Please try again later.',
  },
  sendingRequiresAuthorization: {
    id: 'permits.form.sendingRequiresAuthorization',
    description: 'Text to show when user does not have authorization to send application',
    defaultMessage: '?? Sending it requires higher authorization level.',
  },
  permitTitle: {
    id: 'permits.permit.title',
    description: 'Title of permit page',
    defaultMessage: '?? Permit {referenceNumber}',
  },
  decisionProposalHeading: {
    id: 'permits.permit.decisionProposalHeading',
    description: 'Heading of decision proposal page',
    defaultMessage: '?? Decision proposal',
  },
  decisionProposalTitle: {
    id: 'permits.permit.decisionProposalTitle',
    description: 'Title of decision proposal page',
    defaultMessage: '?? Decision proposal',
  },
  decisionInfoAlertContent: {
    id: 'permits.permit.decisionInfoAlertContent',
    description: 'Permit page decision info alert content',
    defaultMessage: '?? You can give rejoinder before end date',
  },
  decisionWarningAlertContent: {
    id: 'permits.permit.decisionWarningAlertContent',
    description: 'Permit page decision warning alert content',
    defaultMessage: '?? End date has ended',
  },
  openDecisions: {
    id: 'permits.permit.openDecisions',
    description: 'Button label',
    defaultMessage: '?? Open decisions',
  },
  permitOccupantTitle: {
    id: 'permits.permit.OccupantTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Occupant',
  },
  decisionProposalOccupantTitle: {
    id: 'permits.permit.decisionProposalOccupantTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Occupant',
  },
  permitOccupantIdentificationTitle: {
    id: 'permits.permit.OccupantIdentificationTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Occupant ID',
  },
  permitPermitNameTitle: {
    id: 'permits.permit.permitNameTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Authorisation name (code)',
  },
  permitPermitTypeTitle: {
    id: 'permits.permit.permitTypeTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Type',
  },
  permitPermitApplicationIdTitle: {
    id: 'permits.permit.permitApplicationIdTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Application id',
  },
  permitPermitReferenceNumberTitle: {
    id: 'permits.permit.permitReferenceNumberTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Reference number',
  },
  permitPermitStatusTitle: {
    id: 'permits.permit.permitStatusTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Authorisation status',
  },
  permitPermitDecisionDateTitle: {
    id: 'permits.permit.permitDecisionDateTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Decision date',
  },
  permitPermitStartDateTitle: {
    id: 'permits.permit.permitStartDateTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Start date',
  },
  permitPermitEndDateTitle: {
    id: 'permits.permit.permitEndDateTitle',
    description: 'Title in permit page',
    defaultMessage: '?? End date',
  },
  permitPermitVersionTitle: {
    id: 'permits.permit.permitVersionTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Version of authorisation',
  },
  permitDecisionReferenceTitle: {
    id: 'permits.permit.decisionReferenceTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Decision or application',
  },
  permitDecisionPermitNameTitle: {
    id: 'permits.permit.decisionPermitNameTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Permit name',
  },
  permitApplicationIdOrDecisionReferenceNumberTitle: {
    id: 'permits.permit.applicationIdOrDecisionReferenceNumberTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Application id or decision reference number',
  },
  permitPermitDecisionReferenceNumberTitle: {
    id: 'permits.permit.permitDecisionReferenceNumberTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Decision reference number',
  },
  permitApplicationSubmitDateTitle: {
    id: 'permits.permit.permitApplicationSubmitDateTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Submit date of application',
  },
  permitRevocationDecisionReferenceHeading: {
    id: 'permits.permit.permitRevocationDecisionReferenceHeading',
    description: 'Heading in permit page',
    defaultMessage: '?? Reference permit or decision',
  },
  permitRevocationDecisionReferenceNameTitle: {
    id: 'permits.permit.permitRevocationDecisionReferenceNameTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Name of the decision',
  },
  permitRevocationDecisionReferenceNumberTitle: {
    id: 'permits.permit.permitRevocationDecisionReferenceNumberTitle',
    description: 'Title in permit page',
    defaultMessage: '?? Number of the decision',
  },
  labelPermitTypeRevocation: {
    id: 'permits.labelPermitTypeRevocation',
    description: 'Text shown on revocation type',
    defaultMessage: '?? Revocation',
  },
  labelPermitTypeDecisionProposal: {
    id: 'permits.labelPermitTypeDecisionProposal',
    description: 'Text shown on decision proposal type',
    defaultMessage: '?? Decision proposal',
  },
  labelPermitTypeRejoinder: {
    id: 'permits.labelPermitTypeRejoinder',
    description: 'Text shown on rejoinder type',
    defaultMessage: '?? Rejoinder',
  },
  labelPermitStatusDraft: {
    id: 'permits.labelPermitStatusDraft',
    description: 'Text shown on draft status',
    defaultMessage: '?? Draft',
  },
  labelPermitStatusApproved: {
    id: 'permits.labelPermitStatusApproved',
    description: 'Text shown on approved status',
    defaultMessage: '?? Approved',
  },
  labelPermitStatusActive: {
    id: 'permits.labelPermitStatusActive',
    description: 'Text shown on active status',
    defaultMessage: '?? Active',
  },
  labelPermitStatusSuspended: {
    id: 'permits.labelPermitStatusSuspended',
    description: 'Text shown on suspended status',
    defaultMessage: '?? Suspended',
  },
  labelPermitStatusEnded: {
    id: 'permits.labelPermitStatusEnded',
    description: 'Text shown on ended status',
    defaultMessage: '?? Ended',
  },
  labelPermitStatusAnnulled: {
    id: 'permits.labelPermitStatusAnnulled',
    description: 'Text shown on annulled status',
    defaultMessage: '?? Annulled',
  },
  labelPermitStatusRevoked: {
    id: 'permits.labelPermitStatusRevoked',
    description: 'Text shown on revoked status',
    defaultMessage: '?? Revoked',
  },
  labelDecisionProposalStatusProcessed: {
    id: 'permits.labelDecisionProposalStatusProcessed',
    description: 'Text shown on processed status',
    defaultMessage: '?? Processed',
  },
  labelDecisionProposalStatusDeadlineFuture: {
    id: 'permits.labelDecisionProposalStatusDeadlineFuture',
    description: 'Text shown on deadline future status',
    defaultMessage: '?? Rejoinder time left',
  },
  labelDecisionProposalStatusDeadlinePast: {
    id: 'permits.labelDecisionProposalStatusDeadlinePast',
    description: 'Text shown on deadline past status',
    defaultMessage: '?? No rejoinder time left',
  },
  openPdf: {
    id: 'permits.openPdf',
    description: 'Text in button',
    defaultMessage: '?? Open PDF',
  },
  openPermit: {
    id: 'permits.openPermit',
    description: 'Text in button',
    defaultMessage: '?? Open permit',
  },
  amendDecision: {
    id: 'permits.amendDecision',
    description: 'Text in button',
    defaultMessage: '?? Amend application',
  },
  amendDecisionModalTitle: {
    id: 'permits.amendDecisionModalTitle',
    description: 'Modal title',
    defaultMessage: '?? Amend application',
  },
  amendDecisionModalContentAlreadyInProcessing: {
    id: 'permits.amendDecisionModalContentAlreadyInProcessing',
    description: 'Modal content',
    defaultMessage: '?? Amend application already in processing',
  },
  amendDecisionModalContentDraftExists: {
    id: 'permits.amendDecisionModalContentDraftExists',
    description: 'Draft exists, modal content',
    defaultMessage: '?? Amend application draft exists',
  },
  amendDecisionModalContinueAlreadyInProcessing: {
    id: 'permits.amendDecisionModalContinueAlreadyInProcessing',
    description: 'Text in continue button',
    defaultMessage: '?? Continue',
  },
  amendDecisionModalContinueDraftExists: {
    id: 'permits.amendDecisionModalContinueDraftExists',
    description: 'Text in continue button',
    defaultMessage: '?? Continue',
  },
  amendDecisionModalCancel: {
    id: 'permits.amendDecisionModalCancel',
    description: 'Text in cancel button',
    defaultMessage: '?? Cancel',
  },
  renewDecisionError: {
    id: 'permits.renewDecisionError',
    description: 'Error message',
    defaultMessage: '?? Error while renewing decision.',
  },
  renewDecision: {
    id: 'permits.renewDecision',
    description: 'Text in button',
    defaultMessage: '?? Renew application.',
  },
  revokeDecisionError: {
    id: 'permits.revokeDecisionError',
    description: 'Error message',
    defaultMessage: '?? Error while revoking decision.',
  },
  revokeDecision: {
    id: 'permits.revokeDecision',
    description: 'Text in button',
    defaultMessage: '?? Revoke application.',
  },
  replyDecision: {
    id: 'permits.replyDecision',
    description: 'Text in button',
    defaultMessage: '?? Reply decision proposal (vastine)',
  },
  selectGroupPopularTitle: {
    id: 'permits.selectGroupPopularTitle',
    description: 'Title for popular group in select',
    defaultMessage: '?? Popular',
  },
  selectGroupOtherTitle: {
    id: 'permits.selectGroupOtherTitle',
    description: 'Title for other group in select',
    defaultMessage: '?? Other',
  },
  radioSelectionYes: {
    id: 'permits.radioSelectionYes',
    description: 'Text for selection in radio',
    defaultMessage: '?? Yes',
  },
  radioSelectionNo: {
    id: 'permits.radioSelectionNo',
    description: 'Text for selection in radio',
    defaultMessage: '?? No',
  },
  infoElementTitleAccountsLocation: {
    id: 'permits.infoElementTitleAccountsLocation',
    description: 'Title for info element',
    defaultMessage: '?? Accounts location',
  },
  infoElementTitleRecordsLocation: {
    id: 'permits.infoElementTitleRecordsLocation',
    description: 'Title for info element',
    defaultMessage: '?? Records location',
  },
  customsProcedureTypeInfoTextShort: {
    id: 'permits.customsProcedureTypeInfoTextShort',
    description: 'Text for customs procedure type component',
    defaultMessage: '?? Choose one or more customs procedures type.',
  },
  customsProcedureTypeInfoTextLong: {
    id: 'permits.customsProcedureTypeInfoTextLong',
    description: 'Text for customs procedure type component',
    defaultMessage: '?? Choose one or more customs procedures type.',
  },
  infoAreaKiLoTitle: {
    id: 'permits.infoAreaKiLoTitle',
    description: 'Title for info area',
    defaultMessage: '?? Add KiLo description to application as an attachment.',
  },
  sentApplicationTitle: {
    id: 'permits.sentApplicationTitle',
    description: 'Title for modal',
    defaultMessage: '?? Authorisation application',
  },
  sentApplicationContinue: {
    id: 'permits.sentApplicationContinue',
    description: 'Text for continue button',
    defaultMessage: '?? OK',
  },
  uploadingAttachment: {
    id: 'permits.uploadingAttachment',
    description: 'Text for loader',
    defaultMessage: '?? Uploading attachment...',
  },
  decisionFilename: {
    id: 'permits.decisionFilename',
    description: 'Text for decision filename',
    defaultMessage: '?? Authorisation_decision',
  },
  permitDoesNotHavePDF: {
    id: 'permits.permitDoesNotHavePDF',
    description: 'Text in notification',
    defaultMessage: '?? Authorisation does not have PDF',
  },
  amendDecisionFailed: {
    id: 'permits.amendDecisionFailed',
    description: 'Text in notification',
    defaultMessage: '?? There was an error amending decision. Please try again later.',
  },
  missingGuaranteeText: {
    id: 'permits.missingGuaranteeLevel',
    description: 'Text for missing guarantee level.',
    defaultMessage: '?? Guarantee level missing.',
  },
  liabilityGuaranteeLevel: {
    id: 'permits.liabilityGuaranteeLevel',
    description: 'Text for liability guarantee level',
    defaultMessage: '?? Liability guarantee level',
  },
  debtGuaranteeLevel: {
    id: 'permits.debtGuaranteeLevel',
    description: 'Text for debt guarantee level',
    defaultMessage: '?? Debt guarantee level',
  },
  amendmentApplicationRelatedToDecision: {
    id: 'permits.amendmentApplicationRelatedToDecision',
    description: 'Text in label',
    defaultMessage: '?? Amendment application related to decision',
  },
  isAppliedByPdf: {
    id: 'permits.isAppliedByPdf',
    description: 'Modal text if an electronic change application is not possible with the selected application type',
    defaultMessage: '?? This permit type requires the change application to be made with a PDF application',
  },
  applicationPages: {
    id: 'permits.navigation.applicationPages',
    description: 'Accessibility heading for the side navigation',
    defaultMessage: 'Application pages',
  },
  checkGRNNumber: {
    id: 'permits.checkGRNNumber',
    description: 'Error message for invalid GRN number',
    defaultMessage: 'Check GRN number',
  },
  sameAsAccountsLocationText: {
    id: 'permits.form.sameAsAccountsLocationText',
    description: 'Text for checkbox',
    defaultMessage: '?? Same as accounts location',
  },
  renewalApplicationRelatedToDecision: {
    id: 'permits.renewalApplicationRelatedToDecision',
    description: 'Text in label',
    defaultMessage: '?? Renewal application related to decision',
  },
  revokeApplicationRelatedToDecision: {
    id: 'permits.revokeApplicationRelatedToDecision',
    description: 'Text in label',
    defaultMessage: '?? Revoke application related to decision',
  },
  rejoinderApplicationRelatedToDecision: {
    id: 'permits.rejoinderApplicationRelatedToDecision',
    description: 'Text in label',
    defaultMessage: '?? Rejoinder application related to decision',
  },
  errorModalTitle: {
    id: 'notifications.errorModalTitle',
    description: 'General modal title for error',
    defaultMessage: '?? Error',
  },
  checkAtLeastOne: {
    id: 'permits.validation.checkAtLeastOne',
    description: 'Error message when at least one checkbox needs to be checked',
    defaultMessage: 'At least one box must be checked',
  },
  otherReliefError_TAT: {
    id: 'permits.validation.otherReliefError_TAT',
    description: 'Error message when other relief is used',
    defaultMessage: 'Mandatory information. Tell us on what basis you are applying for a relief permit.',
  },
  otherReliefError_TAP: {
    id: 'permits.validation.otherReliefError_TAP',
    description: 'Error message when other relief is used',
    defaultMessage: 'Mandatory information. Tell us on what basis you are applying for a exception permit.',
  },
  mandatoryApplicationBasis: {
    id: 'permits.validation.mandatoryApplicationBasis',
    description: 'Error message when option relief is used',
    defaultMessage: 'Mandatory information. Tell us on what basis you are applying for a relief permit.',
  },
  mandatoryApplicationBasisCheckbox: {
    id: 'permits.validation.mandatoryApplicationBasisCheckbox',
    description: 'Error message when checkbox relief is used',
    defaultMessage: 'Mandatory information. Tell us on what basis you are applying for a relief permit.',
  },
  ttAMuuLabel: {
    id: 'permits.repetitiveComponents.TtA_muu',
    description: 'Custom label for TtA_muu article',
    defaultMessage: 'Something other, what?',
  },
  reliefQuantityPlaceholder: {
    id: 'permits.goods.reliefQuantityPlaceholder',
    description: 'Custom placeholder for relief quantity',
    defaultMessage: 'Something other, what?',
  },
  representativeInformation: {
    id: 'permits.representativeInformation',
    description: 'Header for representative information modal',
    defaultMessage: '?? Representative information',
  },
  messageDeclarantProviderIdentificationDesc: {
    id: 'permits.messageDeclarantProviderIdentificationDesc',
    description: 'Hint text for the provider\'s EORI number',
    defaultMessage: 'Provide the EORI number',
  },
  navigationViewExtended: {
    id: 'permits.navigation.navigationViewExtended',
    description: 'Aria label for step navigation view in extended mode',
    defaultMessage: 'Extended view',
  },
})

export default {
  ...commonPermitsMessages,
  ...messages,
}
