import { chain, includes, find, set, has, get } from 'lodash'
import { uniqueId } from 'src/utils'
import { PERMIT_FIELD_TYPES_AS_CODESETS } from './constants'
import {
  getPersonalApplicantData,
  getBusinessApplicantData,
  getSelectedAuthorization,
  getSelectedDelegateCompanyFromAuthentication,
  isApplicantPerson,
  getAuth,
  getBusinessRepresentativeData,
  getPersonalRepresentativeData,
} from '../../../utils/auth'
import { getStore } from '../../../store'

export function getGroupedInfoElementsByGroupName(structure, infoElementsInGroup) {
  return chain(structure.infoElements)
    .filter(infoElementInStructure => includes(infoElementsInGroup, infoElementInStructure.code))
    .value()
}

export function getInfoElementsFromApplicationData(applicationData, generateId = uniqueId) {
  const infoElements = []
  if (!has(applicationData, 'groupValues')) {
    return infoElements
  }
  applicationData.groupValues.forEach((groups) => {
    groups.groupedValues.forEach((groupedValue) => {
      const groupId = generateId()
      groupedValue.infoElements.forEach((infoElement) => {
        infoElements.push({
          ...infoElement,
          groupId,
        })
      })
    })
  })
  return infoElements
}

export function replaceApplicationInfoElementData(targetApplicationData, sourceApplicationData, infoElementName) {
  if (findInfoElementFromApplicationData(targetApplicationData, infoElementName) &&
    findInfoElementFromApplicationData(sourceApplicationData, infoElementName)) {
    let sourceInfoElementValues = null
    sourceApplicationData.groupValues.forEach((groups) => {
      groups.groupedValues.forEach((groupedValue) => {
        groupedValue.infoElements.forEach((infoElement) => {
          if (infoElement.code === infoElementName) {
            sourceInfoElementValues = groupedValue.infoElements
          }
        })
      })
    })
    let currentGroupValuePath = null
    targetApplicationData.groupValues.forEach((groups, groupValueIndex) => {
      groups.groupedValues.forEach((groupedValue, groupedValueIndex) => {
        groupedValue.infoElements.forEach((infoElement) => {
          if (infoElement.code === infoElementName) {
            currentGroupValuePath = `groupValues[${groupValueIndex}].groupedValues[${groupedValueIndex}]`
          }
        })
      })
    })
    const replacedApplicationData = set(
      targetApplicationData,
      `${currentGroupValuePath}.infoElements`,
      sourceInfoElementValues
    )
    return replacedApplicationData
  }

  // Return target application when specific infoElement is not found from target or source
  return targetApplicationData
}

export function findInfoElementFromApplicationData(applicationData, infoElementCode) {
  const infoElementsInApplication = getInfoElementsFromApplicationData(applicationData)
  return find(infoElementsInApplication, { code: infoElementCode })
}

export function createApplicantGroupedValue(infoeElementCode, fieldValues) {
  if (!infoeElementCode) {
    return null
  }

  return ({
    infoElements: [
      {
        code: infoeElementCode,
        fieldValues,
      },
    ],
  })
}


function getFromInfoElementByCode(infoElement, code) {
  return infoElement?.code === code
}

export function getFromGroupValuesByGroupCode(data, groupCode) {
  return data?.groupValues?.find(val => val?.groupCode === groupCode)
}

export function getFromGroupedValuesByInfoElementCode(groupedValues, infoElementCode) {
  return groupedValues?.reduce((element, groupedValue) => getFromInfoElementsByInfoElementCode(groupedValue?.infoElements, infoElementCode) || element, null)
}

export function getFromInfoElementsByInfoElementCode(infoElements, infoElementCode) {
  return infoElements?.find(infoElement => getFromInfoElementByCode(infoElement, infoElementCode))
}

export function getFromFieldValuesByCode(fieldValues, code) {
  return fieldValues?.find(fieldValue => fieldValue?.code === code)
}

export function hasRepresentativeIdentification(data) {
  const applicant = getFromGroupValuesByGroupCode(data, 'applicant')

  if (!applicant) {
    return null
  }


  const representativeInfoElement = getFromGroupedValuesByInfoElementCode(applicant?.groupedValues, 'representative')

  if (representativeInfoElement) {
    const representativeIdentificationFieldValue = getFromFieldValuesByCode(representativeInfoElement?.fieldValues, 'representativeIdentification')

    if (representativeIdentificationFieldValue?.value) {
      return representativeIdentificationFieldValue.value
    }
  }


  const representativeDetaislInfoElement = getFromGroupedValuesByInfoElementCode(applicant?.groupedValues, 'representativeDetails')

  if (!representativeDetaislInfoElement) {
    return null
  }

  const representativeIdentificationFieldValue = getFromFieldValuesByCode(representativeDetaislInfoElement?.fieldValues, 'representativeIdentification')
  return representativeIdentificationFieldValue?.value || null
}

export function getApplicantNameFromAskare(customerId, customer) {
  return customer?.customerInformation?.[customerId]?.fullName
}

export function getApplicantAddressFromAskare(representativeId, customer) {
  return customer?.customerInformation?.[representativeId]?.addresses?.[0]
}

export function getRepresentativeNameFromAskare(representativeId, customer) {
  return customer?.representativeInformation?.[representativeId]?.fullName
}

export function getApplicantNameFromPermit(formApi) {
  return get(formApi, 'values.authorizationOrDecisionHolder.authorizationOrDecisionHolderName')
}

export function getRepresentativeNameFromPermit(formApi) {
  return get(formApi, 'values.representativeDetails.representativeName')
}

export function getRepresentativeAddressFromAskare(representativeId, customer) {
  return customer?.representativeInformation?.[representativeId]?.addresses?.[0]
}

export function getPermitRepresentativeNameFromAskare(permitRepresentativeId, customer) {
  return customer?.permitRepresentativeInformation?.[permitRepresentativeId]?.fullName
}

export function getPermitRepresentativeAddressFromAskare(permitRepresentativeId, customer) {
  return customer?.permitRepresentativeInformation?.[permitRepresentativeId]?.addresses?.[0]
}

export function mapApplicantAskareAddressToPermitAddress(name, askareAddress) {
  if (!name || !askareAddress) {
    return null
  }

  return {
    authorizationOrDecisionHolderName: name,
    authorizationOrDecisionHolderAddress: askareAddress.address,
    authorizationOrDecisionHolderPostalCode: askareAddress.zipCode,
    authorizationOrDecisionHolderCity: askareAddress.city,
    authorizationOrDecisionHolderCountryCode: askareAddress.countryCode,

  }
}

export function mapRepresentativeAskareAddressToPermitAddress(name, askareAddress) {
  if (!name || !askareAddress) {
    return null
  }

  return {
    representativeName: name,
    representativeAddress: askareAddress.address,
    representativePostalCode: askareAddress.zipCode,
    representativeCity: askareAddress.city,
    representativeCountryCode: askareAddress.countryCode,

  }
}

export function populateEmptyPermitAddress() {
  return {
    representativeName: null,
    representativeAddress: null,
    representativePostalCode: null,
    representativeCity: null,
    representativeCountryCode: null,

  }
}

const DATA_ACCESS_RESTRICTION_UNKNOWN_VALUE = 'Tuntematon'
const DATA_ACCESS_RESTRICTION_UNKNOWN_POSTAL_CODE_VALUE = '00000'
const DATA_ACCESS_RESTRICTION_UNKNOWN_COUNTRY_CODE_VALUE = 'FI'

function mapPersonAuthorizationOrDecisionHolder(applicant) {
  const hasPersonalDataAccessRestriction = applicant.hasPersonalDataAccessRestriction

  const details = {
    authorizationOrDecisionHolderName: get(applicant, 'name'),
    authorizationOrDecisionHolderAddress: hasPersonalDataAccessRestriction ? DATA_ACCESS_RESTRICTION_UNKNOWN_VALUE : get(applicant, 'address'),
    authorizationOrDecisionHolderPostalCode: hasPersonalDataAccessRestriction ? DATA_ACCESS_RESTRICTION_UNKNOWN_POSTAL_CODE_VALUE : get(applicant, 'zipCode'),
    authorizationOrDecisionHolderCity: hasPersonalDataAccessRestriction ? DATA_ACCESS_RESTRICTION_UNKNOWN_VALUE : get(applicant, 'city'),
    authorizationOrDecisionHolderCountryCode: hasPersonalDataAccessRestriction ? DATA_ACCESS_RESTRICTION_UNKNOWN_COUNTRY_CODE_VALUE : get(applicant, 'countryCode'),
  }

  const applicantDetails = []

  applicantDetails.push({
    code: 'authorizationOrDecisionHolderIdentification',
    value: get(applicant, 'authorizationOrDecisionHolderIdentification'),
    applicantType: get(applicant, 'applicantType'),
  })

  // Contact information
  for (const [code, value] of Object.entries(details)) {
    applicantDetails.push({ code, value })
  }

  return applicantDetails
}

function mapPersonRepresentativeDetails(representative) {
  const hasPersonalDataAccessRestriction = representative.hasPersonalDataAccessRestriction

  const details = {
    representativeName: get(representative, 'name'),
    representativeAddress: hasPersonalDataAccessRestriction ? DATA_ACCESS_RESTRICTION_UNKNOWN_VALUE : get(representative, 'address'),
    representativePostalCode: hasPersonalDataAccessRestriction ? DATA_ACCESS_RESTRICTION_UNKNOWN_POSTAL_CODE_VALUE : get(representative, 'zipCode'),
    representativeCity: hasPersonalDataAccessRestriction ? DATA_ACCESS_RESTRICTION_UNKNOWN_VALUE : get(representative, 'city'),
    representativeCountryCode: hasPersonalDataAccessRestriction ? DATA_ACCESS_RESTRICTION_UNKNOWN_COUNTRY_CODE_VALUE : get(representative, 'countryCode'),
  }

  const representativeDetails = []
  representativeDetails.push({
    code: 'representativeIdentification',
    value: get(representative, 'representativeIdentification'),
    applicantType: get(representative, 'applicantType'),
  })

  // Contact information
  for (const [code, value] of Object.entries(details)) {
    representativeDetails.push({ code, value })
  }

  return representativeDetails
}

function createPersonDraftObj(applicationTypeCode, applicant, representative, locale) {
  const applicantGroupedValues = []

  if (applicant.authorizationOrDecisionHolderIdentification) {
    // 3/2 authorizationOrDecisionHolder
    const authorizationOrDecisionHolder = mapPersonAuthorizationOrDecisionHolder(applicant)
    applicantGroupedValues.push(createApplicantGroupedValue('authorizationOrDecisionHolder', authorizationOrDecisionHolder))
  }

  if (representative.representativeIdentification) {
    // 3/3 representativeDetails
    const representativeDetails = mapPersonRepresentativeDetails(representative)
    applicantGroupedValues.push(createApplicantGroupedValue('representativeDetails', representativeDetails))
  }

  return {
    applicationTypeCode,
    language: locale.toUpperCase(),
    groupValues: [
      {
        groupCode: 'applicant',
        groupedValues: applicantGroupedValues,
      },
    ],
  }
}

function createBusinessDraftObj(applicationTypeCode, applicant, representative, customer, locale) {
  const applicantGroupedValues = []

  if (applicant.authorizationOrDecisionHolderIdentification) {
    // 3/2 authorizationOrDecisionHolder
    applicantGroupedValues.push(createApplicantGroupedValue('authorizationOrDecisionHolder', [
      {
        code: 'authorizationOrDecisionHolderIdentification',
        value: get(applicant, 'authorizationOrDecisionHolderIdentification'),
        applicantType: get(applicant, 'applicantType'),
      },
    ]))
  }

  if (representative.representativeIdentification) {
    // 3/3 representativeDetails
    applicantGroupedValues.push(createApplicantGroupedValue('representativeDetails', [
      {
        code: 'representativeIdentification',
        value: get(representative, 'representativeIdentification'),
        applicantType: get(representative, 'applicantType'),
      },
    ]))
  }


  return {
    applicationTypeCode,
    language: locale.toUpperCase(),
    groupValues: [
      {
        groupCode: 'applicant',
        groupedValues: applicantGroupedValues,
      },
    ],
  }
}


export function createDraftObj(applicationTypeCode, applicant, representative, customer, locale) {
  if (isApplicantPerson()) {
    return createPersonDraftObj(applicationTypeCode, applicant, representative, locale)
  }

  return createBusinessDraftObj(applicationTypeCode, applicant, representative, customer, locale)
}

export function isCodeset(fieldType) {
  return includes(PERMIT_FIELD_TYPES_AS_CODESETS, fieldType)
}


function formHasRepresentativeIdentification(form) {
  const values = form.values
  return values?.representative?.representativeIdentification
}

function formHasRepresentativeDetailsIdentification(form) {
  const values = form.values

  // We need this fallback for AL-798
  return values?.representativeDetails?.representativeIdentification
}

export function filterRepresentativeIfNoValue(infoElement, form) {
  const representativeInfoElement = getFromInfoElementByCode(infoElement, 'representative')
  const representativeDetailsInfoElement = getFromInfoElementByCode(infoElement, 'representativeDetails')


  if (representativeInfoElement) {
    return formHasRepresentativeIdentification(form)
  }

  if (representativeDetailsInfoElement) {
    return !!formHasRepresentativeDetailsIdentification(form)
  }


  return true
}

export function getApplicant() {
  const applicantAuthentication = getSelectedAuthorization()

  if (isApplicantPerson()) {
    return getPersonalApplicantData(getAuth(), applicantAuthentication)
  }

  return getBusinessApplicantData(applicantAuthentication)
}

export function getRepresentative() {
  if (isApplicantPerson()) {
    const auth = getAuth()
    return getPersonalRepresentativeData(auth)
  }

  const representativeAuthentication = getSelectedDelegateCompanyFromAuthentication()
  return getBusinessRepresentativeData(representativeAuthentication)
}

export function getBootstrapConfig() {
  const state = getStore().getState()
  return get(state, 'config.bootstrapConfig')
}
