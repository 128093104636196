import { CUSTOMER_INFORMATION_ROUTE_PATH } from './constants'

export default () => ({
  path: CUSTOMER_INFORMATION_ROUTE_PATH,
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: "customerInformation" */ './container'),
    ]).then(([containerModule]) => {
      next(null, containerModule.default)
    })
  },
})
