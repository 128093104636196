import React, { useEffect, useState } from 'react'

import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'

import { Col, Row, FormControl } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import moment from 'moment'
import { range } from 'src/utils'
import componentMessages from 'src/components/messages'

import messages from '../PersonInCharge/messages'
import { validateDob } from '../../../../../utils/validation'

const DateOfBirth = (props) => {
  moment.locale(locale)

  const [selectedDay, setSelectedDay] = useState('')
  const [selectedMonth, setSelectedMonth] = useState('')
  const [selectedYear, setSelectedYear] = useState('')

  const [dayIsTouched, setDayIsTouched] = useState(false)
  const [monthIsTouched, setMonthIsTouched] = useState(false)
  const [yearIsTouched, setYearIsTouched] = useState(false)


  useEffect(() => {
    if (props.initialValue) {
      const initialDob = moment(props.initialValue, 'YYYYMMDD')

      setSelectedDay(initialDob.date())
      setSelectedMonth(initialDob.month() + 1)
      setSelectedYear(initialDob.year())
    }
  }, [])

  useEffect(() => {
    if (dayIsTouched && monthIsTouched && yearIsTouched) {
      props.setTouched()
    }
  }, [dayIsTouched, monthIsTouched, yearIsTouched])

  useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      const { valid, dob } = validateDob(true, selectedYear, selectedMonth, selectedDay)

      if (valid) {
        props.setDobValue(dob.format('YYYYMMDD'))
      }
    }
  }, [selectedDay, selectedMonth, selectedYear])

  const { intl: { locale, formatMessage }, field } = props
  const [dateOfBirthInvalid] = useState(false)


  const className = dateOfBirthInvalid ? 'customLabel text-danger' : 'customLabel'

  return (
    <div role="group" aria-labelledby="dob-group" className={`clearfix formElementGutter form-group ${props.error ? 'has-error' : ''}`}>
      <span id="dob-group" className={`${className} ${props.error ? 'text-danger has-error' : ''}`}>
        {field.name[locale]} {!field.mandatory && <FormattedMessage {...componentMessages.formFieldOptional}>{message => `(${message})`}</FormattedMessage>}
      </span>

      {props.static && <FormControl.Static id-qa-test={`label-date-value-${props.name}`}>
        {(props.value && moment(props.value, 'YYYYMMDD').format('DD.MM.YYYY')) || '--'}
      </FormControl.Static>}

      {!props.static && <Row className="">
        <Col sm={4} className={styles.dobGutter}>
          <DobSelect
            placeholder={formatMessage(messages.dateOfBirthDayPlaceholder)}
            name="day"
            arialabel="ariaLabel"
            ariaLabelledBy="ariaLabelledBy"
            ariaDescribedBy="ariaDescribedBy"
            value={selectedDay}
            setValue={setSelectedDay}
            setTouched={() => setDayIsTouched(true)}
            options={getDayOptions(formatMessage, messages.dateOfBirthDayPlaceholder)}
          />
        </Col>
        <Col sm={4} className={styles.dobGutter}>
          <DobSelect
            placeholder={formatMessage(messages.dateOfBirthMonthPlaceholder)}
            name="month"
            arialabel="ariaLabel"
            ariaLabelledBy="ariaLabelledBy"
            ariaDescribedBy="ariaDescribedBy"
            value={selectedMonth}
            setValue={setSelectedMonth}
            setTouched={() => setMonthIsTouched(true)}
            options={getMonthOptions(formatMessage, messages.dateOfBirthMonthPlaceholder, locale)}
          />
        </Col>
        <Col sm={4}>
          <DobSelect
            placeholder={formatMessage(messages.dateOfBirthYearPlaceholder)}
            name="year"
            arialabel="ariaLabel"
            ariaLabelledBy="ariaLabelledBy"
            ariaDescribedBy="ariaDescribedBy"
            value={selectedYear}
            setValue={setSelectedYear}
            setTouched={() => setYearIsTouched(true)}
            options={getYearOptions(formatMessage, messages.dateOfBirthYearPlaceholder)}
          />
        </Col>
      </Row>}
      {props.error &&
      <div id="dob-error-message" className="text-danger help-block">
        <FormattedMessage {...messages.dateNotValid} />
      </div>
      }
    </div>
  )
}

const DobSelect = ({ placeholder, name, ariaLabel, ariaLabelledBy, ariaDescribedBy, value, setValue, setTouched, options }) => (
  <div style={{ position: 'relative' }}>
    <FormControl
      placeholder={placeholder}
      id-qa-test={`select-dob-${name}`}
      componentClass="select"
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      aria-required
      value={value}
      onChange={(event) => {
        event.preventDefault()
        setValue(event.target.value)
      }}
      onBlur={() => {
        setTouched()
      }}
    >
      {options}

    </FormControl>
    <span
      className={styles.inputActionIcon}
      style={{ pointerEvents: 'none', cursor: 'pointer' }}
    >
      <Icon name="chevron-tight-down" inline />
    </span>
  </div>
)

const getDayOptions = (formatMessage, placeholder) => {
  const days = Array.from(range(1, 32)).map(day => ({ id: day, value: `${day}`, title: day }))

  const options = [
    <option
      key="placeholder"
      disabled
      hidden={false}
      value=""
      id-qa-test={'option-placeholder'}
    >
      {formatMessage(placeholder)}
    </option>]

  days.forEach(option =>
    options.push(<option key={option.id} value={option.value} id-qa-test={`option-${option.value}`}>
      {option.message ? formatMessage(option.message) : option.title}
    </option>)
  )

  return options
}

const getMonthOptions = (formatMessage, placeholder, locale) => {
  moment.locale(locale)

  const months = moment.months().map((month, index) => ({ id: index + 1, value: `${index + 1}`, title: month }))

  const options = [
    <option
      key="placeholder"
      disabled
      hidden={false}
      value=""
      id-qa-test={'option-placeholder'}
    >
      {formatMessage(placeholder)}
    </option>]

  months.forEach(option =>
    options.push(<option key={option.id} value={option.value} id-qa-test={`option-${option.value}`}>
      {option.message ? formatMessage(option.message) : option.title}
    </option>)
  )

  return options
}

const getYearOptions = (formatMessage, placeholder) => {
  const years =  Array.from(range(moment().year() - 100, moment().year() - 15))
    .reverse()
    .map(year => ({ id: year, value: `${year}`, title: year }))

  const options = [
    <option
      key="placeholder"
      disabled
      hidden={false}
      value=""
      id-qa-test={'option-placeholder'}
    >
      {formatMessage(placeholder)}
    </option>]

  years.forEach(option =>
    options.push(<option key={option.id} value={option.value} id-qa-test={`option-${option.value}`}>
      {option.message ? formatMessage(option.message) : option.title}
    </option>)
  )

  return options
}

export default injectIntl(DateOfBirth)
