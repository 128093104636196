import { injectReducer } from 'src/reducers/root'
import { MESSAGES_ROUTE_PATH } from './constants'

export default store => ({
  path: `${MESSAGES_ROUTE_PATH}(/:threadId)`,
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: "messages" */ './container'),
      import(/* webpackChunkName: "messages" */ './reducer'),
    ]).then(([containerModule, reducerModule]) => {
      injectReducer(store, { key: 'messages', reducer: reducerModule.default })
      next(null, containerModule.default)
    })
  },
})
