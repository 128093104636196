import md5 from 'md5'
import { intersection, get, isNil, union, isEmpty, size } from 'lodash'
import { push as pushRoute } from 'react-router-redux'
import {
  AUTH_TYPES,
  AUTH_PLURALITY_TYPES,
  DEFAULT_AUTHORIZATION_TYPES_BY_APPLICATION,
  DEFAULT_AUTHORIZATION_TYPES, AUTH_TYPE_MAP,
  SERVICE_PATHS,
  TUTTU_PATH,
} from 'src/constants'
import { getApplicationNameFromStorage } from 'src/utils'

import { getStore } from '../store'
import { APPLICANT_TYPE } from '../routes/permits/permit/constants'

export const PERMIT_USER_TYPES = {
  admin: 'TULLITullinlupapalvelunPaakayttaja',
  handler: 'TULLITullinlupapalvelunKasittelija',
  reader: 'TULLITullinlupapalvelunLukuoikeudet',
}
export const CUSTOMS_CLEARANCE_ROLE = [
  'TULLITulliselvitys',
  'customs_clearance',
]
export const CUSTOMER_INFORMATION_VIEWING_ROLES = [
  'TULLITullitoiminnanasiakastietojenkatselu',
  'customer_information_viewing',
]
export const INTRASTAT_ROLES = [
  'TULLIIntrastat',
  'intrastat',
]

export const PERMIT_APPLYING_ROLE = 'permit_applying'

export const PERMIT_ROLES = [
  PERMIT_USER_TYPES.admin,
  PERMIT_USER_TYPES.handler,
  PERMIT_USER_TYPES.reader,
  PERMIT_APPLYING_ROLE,
  'permit_drafting',
  'permit_viewing',
]

export const IAT_ROLES = ['companyUser', 'TULLIPalveluntarjoaja']
// TODO Check specific Katso role
// export const rolesAllowingEoriRegistration = ['']

export function getSelectedCompanyAndDelegateCompanyIdentifiers() {
  const state = getStore().getState()

  return state?.auth?.selectedAuthorization
}

export function getSelectedAuthorization() {
  const state = getStore().getState()
  const authorizations = get(state, 'auth.authorizations')
  const selectedAuthorizationId = get(state, 'auth.selectedAuthorization.identifier')

  if (authorizations && selectedAuthorizationId) {
    // For private users we use id, beause they do not have identifier in their authorization object
    return authorizations.find(auth => auth.identifier === selectedAuthorizationId || auth.id === selectedAuthorizationId)
  }

  return null
}

export function getSelectedCompanyAuthorization(authPayload) {
  const authorizations = get(authPayload, 'authorizations')

  if (authorizations) {
    const selectedAuthorizationId = get(authPayload, 'selectedAuthorization.identifier')
    const foundAuth = authorizations.find(auth => auth.identifier === selectedAuthorizationId && auth.type === AUTH_TYPES.business)

    return foundAuth
  }

  return null
}

export function getSelectedDelegateCompanyFromAuthentication() {
  const selectedAuthorizationObject = getSelectedAuthorization()
  const selectedCompanyAndDelegateCompanyIdentifiers = getSelectedCompanyAndDelegateCompanyIdentifiers()

  return getSelectedDelegateCompany(selectedAuthorizationObject, selectedCompanyAndDelegateCompanyIdentifiers)
}

export function getSelectedDelegateCompany(selectedAuthorizationObject, selectedAuthorizationAndDelegateCompany) {
  const delegateCompanies = get(selectedAuthorizationObject, 'delegateCompanies')

  if (delegateCompanies) {
    const delegateId = get(selectedAuthorizationAndDelegateCompany, 'delegateCompany')
    return delegateCompanies.find(auth => auth.identifier === delegateId)
  }

  return null
}

export function getSelectedDelegateCompanyFromPayload(authPayload) {
  const selectedCompanyAndDelegateCompanyIdentifiers = authPayload.selectedAuthorization

  return getSelectedDelegateCompany(authPayload, selectedCompanyAndDelegateCompanyIdentifiers)
}

export function getBusinessApplicantData(applicant) {
  return {
    id: get(applicant, 'id', null),
    authorizationOrDecisionHolderIdentification: get(applicant, 'eori', null),
    type: get(applicant, 'type', null),
    applicantType: APPLICANT_TYPE.COMPANY,
  }
}

export function getBusinessRepresentativeData(representative) {
  return {
    id: get(representative, 'id', null),
    representativeIdentification: get(representative, 'eori', null),
    type: get(representative, 'type', null),
    applicantType: APPLICANT_TYPE.COMPANY,
  }
}

export function getAuth() {
  const state = getStore().getState()
  return get(state, 'auth')
}

export function isPersonPrivate(auth) {
  return auth.selectedAuthorizationType === AUTH_TYPES.private
}

export function getSelectedAuthorizationId(auth) {
  const authorization = getSelectedAuthorization(auth)

  return get(authorization, 'identifier')
}


export function getUid(auth) {
  return atob(auth.uid)
}

export function getPersonalApplicantData(auth, applicant) {
  const id = isPersonPrivate(auth) ? get(auth, 'userId') : getSelectedAuthorizationId(auth)
  const authorizationOrDecisionHolderIdentification = isPersonPrivate(auth) ? getUid(auth) : getSelectedAuthorizationId(auth)
  const hasPersonalDataAccessRestriction = isPersonPrivate(auth) && get(auth, 'hasPersonalDataAccessRestriction')
  const address = isPersonPrivate(auth) && !hasPersonalDataAccessRestriction ? get(auth, 'address', {}) : {}

  return {
    id,
    authorizationOrDecisionHolderIdentification,
    type: get(applicant, 'type', null),
    name: get(auth, 'name'),
    hasPersonalDataAccessRestriction,
    applicantType: APPLICANT_TYPE.PERSON,
    ...address,
  }
}

export function getPersonalRepresentativeData(auth) {
  const principal = getPrincipal()

  if (!principal) {
    return {
      id: null,
    }
  }

  const representativeIdentification = getUid(auth)
  const id = get(principal, 'id')
  const hasPersonalDataAccessRestriction = get(auth, 'hasPersonalDataAccessRestriction')
  const address = hasPersonalDataAccessRestriction ? {} : get(auth, 'address', {})
  const name = get(principal, 'name')
  const type = get(principal, 'type')

  return {
    id,
    representativeIdentification,
    name,
    type,
    hasPersonalDataAccessRestriction,
    applicantType: APPLICANT_TYPE.PERSON,
    ...address,
  }
}

export function getRepresentativeIdAndRepresentativeIdentifier() {
  const delegateCompany = getSelectedDelegateCompanyFromAuthentication()

  return getBusinessRepresentativeData(delegateCompany)
}

export function getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, selectedAuthorizationAndDelegateCompany) {
  const delegateCompany = getSelectedDelegateCompany(selectedAuthorizationObject, selectedAuthorizationAndDelegateCompany)

  if (!isEmpty(delegateCompany?.roles)) {
    return delegateCompany.roles
  }

  return selectedAuthorizationObject?.roles || []
}

export function containsAnyRole(userRoles, requiredRoles = []) {
  if (!requiredRoles.length) {
    return true
  }
  return filterUserRoles(userRoles, requiredRoles).length > 0
}

export function getAllRoles(authorizations) {
  if (!authorizations || !authorizations.length) {
    return false
  }
  return union(...(authorizations.map(auth => auth.roles)))
}

export function hasAuthorization(allUserRoles, allowedRoles) {
  if (!allowedRoles || !allowedRoles.length) {
    return true
  }
  if (!allUserRoles || !allUserRoles.length) {
    return false
  }
  return containsAnyRole(allUserRoles, allowedRoles)
}

export function checkPrivateAuthorization() {
  const state = getStore().getState()
  const authType = get(state, 'auth.selectedAuthorizationType')

  const selectedAuthorizationAndDelegateCompany = get(state, 'auth.selectedAuthorization')
  const roles = getSelectedAuthorizationOrDelegateRoles(getSelectedAuthorization(), selectedAuthorizationAndDelegateCompany)

  if (authType === AUTH_TYPES.principal) {
    return roles.includes(PERMIT_APPLYING_ROLE)
  }

  if (authType === AUTH_TYPES.private) {
    return true
  }

  return false
}

export function selectedAuthorizationHasSomeRole(someRoles) {
  const state = getStore().getState()

  // get authorized company id and delegate company id
  const selectedAuthorizationAndDelegateCompany = get(state, 'auth.selectedAuthorization')
  const roles = getSelectedAuthorizationOrDelegateRoles(getSelectedAuthorization(), selectedAuthorizationAndDelegateCompany)

  return roles.some(roleName => someRoles.includes(roleName))
}

export function selectedAuthorizationHasRole(role) {
  const state = getStore().getState()

  const selectedAuthorizationAndDelegateCompany = get(state, 'auth.selectedAuthorization')

  const roles = getSelectedAuthorizationOrDelegateRoles(getSelectedAuthorization(), selectedAuthorizationAndDelegateCompany)

  if (roles.includes(role)) {
    return true
  }

  const authType = get(state, 'auth.selectedAuthorizationType')

  if (authType === AUTH_TYPES.principal) {
    return roles.includes(PERMIT_APPLYING_ROLE)
  }

  if (authType === AUTH_TYPES.private) {
    return true
  }

  return false
}

export function isTermsAcceptedInBrowserStorage(userId, termsOfServiceVersion) {
  const allAcceptedTosJson = localStorage.getItem('acceptedTerms')

  if (!allAcceptedTosJson) {
    return false
  }

  let allAcceptedTos

  try {
    allAcceptedTos = JSON.parse(allAcceptedTosJson)
  } catch (err) {
    return false
  }

  if (!allAcceptedTos) {
    return false
  }
  const acceptedTermsFromLocalStorage = get(allAcceptedTos, userId)
  if (!acceptedTermsFromLocalStorage) {
    return false
  }
  if (md5(termsOfServiceVersion) === acceptedTermsFromLocalStorage) {
    return true
  }
  return false
}

export function getDelegateCompany() {
  const state = getStore().getState()
  return state?.auth?.selectedAuthorization?.delegateCompany
}

export function checkIntrastatAuthorization(selectedAuthorizationObject, selectedAuthorization) {
  if (!selectedAuthorizationObject || selectedAuthorizationObject.type === AUTH_TYPES.private) {
    return false
  }

  const roles = getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, selectedAuthorization)
  return hasAuthorization(roles, INTRASTAT_ROLES)
}

export function checkPermitsAuthorization(selectedAuthorizationObject, selectedAuthorization) {
  // Authorizate HPA/IPA users
  if (selectedAuthorizationObject?.type === AUTH_TYPES.private) {
    return true
  }

  if (!selectedAuthorizationObject) {
    return false
  }

  const roles = getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, selectedAuthorization)
  return hasAuthorization(roles, PERMIT_ROLES)
}

export function checkFormsAuthorization() {
  return true
}

export function checkEoriFormAuthorization(selectedAuthorizationObject) {
  if (!selectedAuthorizationObject || selectedAuthorizationObject.type === AUTH_TYPES.private) {
    return false
  }

  // TODO Check specific Katso role
  // return hasAuthorization(getAllRoles(authorizations), rolesAllowingEoriRegistration)
  return true
}

export function checkLocationInformationFormAuthorization(selectedAuthorizationObject, selectedAuthorization) {
  if (!selectedAuthorizationObject) {
    return false
  }

  if (selectedAuthorizationObject.type === AUTH_TYPES.private) {
    return false
  }

  const roles = getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, selectedAuthorization)
  return hasAuthorization(roles, CUSTOMER_INFORMATION_VIEWING_ROLES)
}

export function checkUtuAuthorization(selectedAuthorizationObject, selectedAuthorization) {
  if (!selectedAuthorizationObject) {
    return false
  }

  if (selectedAuthorizationObject.type === AUTH_TYPES.private) {
    return true
  }

  const roles = getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, selectedAuthorization)
  return hasAuthorization(roles, CUSTOMS_CLEARANCE_ROLE)
}

export function checkMessagesAuthorization(selectedAuthorizationObject, selectedAuthorization) {
  // For now, messages is only allowed for intrastat service
  return checkIntrastatAuthorization(selectedAuthorizationObject, selectedAuthorization)
}

export function isApplicantPerson() {
  const auth = getSelectedAuthorization()

  if (!auth) {
    return null
  }

  return auth.type === AUTH_TYPES.private || auth.type === AUTH_TYPES.principal
}

export function checkCustomerInformationAuthorization(selectedAuthorizationObject, selectedAuthorization) {
  if (!selectedAuthorizationObject) {
    return false
  }

  if (selectedAuthorizationObject.type === AUTH_TYPES.private) {
    return false
  }

  const roles = getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, selectedAuthorization)
  return hasAuthorization(roles, CUSTOMER_INFORMATION_VIEWING_ROLES)
}

export function checkIndividualGuaranteeAuthorization(selectedAuthorizationObject, selectedAuthorization) {
  if (!selectedAuthorizationObject) {
    return false
  }

  if (selectedAuthorizationObject.type === AUTH_TYPES.business) {
    const roles = getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, selectedAuthorization)
    return hasAuthorization(roles, CUSTOMS_CLEARANCE_ROLE)
  }

  return true
}

export function checkIATAuthorization(selectedAuthorizationObject, selectedAuthorization) {
  if (!selectedAuthorizationObject) {
    return false
  }

  if (selectedAuthorizationObject.type === AUTH_TYPES.principal || selectedAuthorizationObject.type === AUTH_TYPES.private) {
    return false
  }

  const roles = getSelectedAuthorizationOrDelegateRoles(selectedAuthorizationObject, selectedAuthorization)
  return hasAuthorization(roles, IAT_ROLES)
}

export function redirectToLoginService(locale, redirectAfterLoginLocation) {
  if (redirectAfterLoginLocation) {
    // We use local storage for all browsers
    localStorage.setItem('redirectAfterLoginLocation', redirectAfterLoginLocation)
  }

  const { config: { bootstrapConfig } } = getStore().getState()

  window.location.href = `${bootstrapConfig.tm_ext_auth_login_url}?locale=${locale}`
}

export function redirectToRoleSelector(returnUrl, history, hideConfirmationModal) {
  history.push(returnUrl)
  hideConfirmationModal()
}

export function findServicePath(uri) {
  const frontpage = '/'
  if (uri && uri.length) {
    const path = SERVICE_PATHS.find((element) => {
      if (uri.includes(element)) {
        return true
      }

      return false
    })
    return path || frontpage
  }

  return frontpage
}

export function handleAfterLoginRedirects(store) {
  const location = localStorage.getItem('redirectAfterLoginLocation')
  if (!location) {
    return
  }

  // We do not want to do a local routing to /import
  // Or a 404 page will flash after selecting a role
  // redirectAfterLoginLocation will be removed when landing in TUTTU
  const isTuttu = location.includes(TUTTU_PATH)
  if (!isTuttu) {
    localStorage.removeItem('redirectAfterLoginLocation')
  }

  if (/https?:/.test(location)) {
    // Location is external url - wait for authentication success, including terms of service and role selection
    store.subscribe(() => {
      const { auth } = store.getState()
      if (auth && auth.isAuthenticated && auth.termsAccepted && !isNil(auth.selectedAuthorization)) {
        window.location.href = location
      }
    })
  } else if (!isTuttu) {
    // Location is a local route path - change route immediately
    store.dispatch(pushRoute(location))
  }
}

export function isForbiddenForForeigners(serviceConfig, path) {
  const servicePath = parsePath(path)
  if (!servicePath) {
    return true
  }
  for (const value of Object.values(serviceConfig)) {
    if (value.paths.includes(servicePath)) {
      return false
    }
  }
  return true
}

export function parsePath(url) {
  const matches = url.match(/^\/([^/?]+)/i)
  return matches && matches[1]
}

function filterUserRoles(userRoles, allowedRoles) {
  return intersection(userRoles, allowedRoles)
}

export function getAuthorizationTypes(bootstrapConfig) {
  const authorizationConfig = bootstrapConfig.authorizationTypes
    ? bootstrapConfig.authorizationTypes : DEFAULT_AUTHORIZATION_TYPES_BY_APPLICATION
  return JSON.parse(authorizationConfig)
}

export function getApplicationAuthorizationConfig(bootstrapConfig) {
  const application = getApplicationNameFromStorage()
  const authorizationConfig = getAuthorizationTypes(bootstrapConfig)
  let applicationAuthorizationConfig = authorizationConfig.find(el => el.applicationName === application)
  // If configuration or it's types are not found, use default authorization types with application
  if (!applicationAuthorizationConfig || !applicationAuthorizationConfig.types) {
    applicationAuthorizationConfig = { types: DEFAULT_AUTHORIZATION_TYPES }
  }
  return applicationAuthorizationConfig
}

function mapAuthorizationTypes(types) {
  let mappedTypes

  if (types) {
    mappedTypes = types.map(item => AUTH_TYPE_MAP[item])
  }

  return mappedTypes
}

export function formatAuthorizationTypes(authorizationTypes) {
  let filtered

  if (authorizationTypes) {
    authorizationTypes.forEach((item) => {
      filtered = {
        ...filtered,
        [item.applicationName]: mapAuthorizationTypes(item.types),
      }
    })
  }

  return filtered
}

export function getCorrectAuthPath(mapPathsToAuthTypes, returnUriAfterRoleSwitch, pathname) {
  return Object.keys(mapPathsToAuthTypes)
    .find(configPath => (returnUriAfterRoleSwitch ? returnUriAfterRoleSwitch.indexOf(configPath) >= 0 : pathname === configPath))
}

export function getPrincipal() {
  const state = getStore().getState()
  const authorizations = get(state, 'auth.authorizations')
  const userId = get(state, 'auth.userId')
  const authTypeIsPrincipal = get(state, 'auth.selectedAuthorizationType') === AUTH_TYPES.principal

  if (authorizations && userId && authTypeIsPrincipal) {
    return authorizations.find(auth => auth.id === userId)
  }

  return null
}


/**
 * Gets plurality of authorizations
 *  @param {array} authorizations Array of authorization objects
 *  @returns {string} PRIVATE, ONLY_ONE_DELEGATE, ONLY_ONE_DIRECT, MULTIPLE
 */
export function getAuthPlurality(authorizations) {
  if (!authorizations || isEmpty(authorizations)) {
    return AUTH_PLURALITY_TYPES.NO_AUTH
  }


  const authorizationsSize = size(authorizations)
  const oneValidAuth = authorizationsSize === 1 && authorizations[0]

  if (oneValidAuth) {
    const privateUser = oneValidAuth.type === AUTH_TYPES.private

    /*
      Private user
    */
    if (privateUser) {
      return AUTH_PLURALITY_TYPES.PRIVATE
    }

    const emptyRoles = isEmpty(oneValidAuth.roles)
    const onlyOneDelegateCompany = size(oneValidAuth.delegateCompanies) === 1

    /*
      Only one delegate company
    */
    if (emptyRoles && onlyOneDelegateCompany) {
      return AUTH_PLURALITY_TYPES.ONLY_ONE_DELEGATE
    }

    const emptyDelegateCompanies = isEmpty(oneValidAuth.delegateCompanies)

    /*
      Only one direct authorization
    */
    if (!emptyRoles && emptyDelegateCompanies) {
      return AUTH_PLURALITY_TYPES.ONLY_ONE_DIRECT
    }
  }

  /*
    Multiple authorizations
  */
  return AUTH_PLURALITY_TYPES.MULTIPLE
}
