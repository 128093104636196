import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Row, Col, Button } from 'react-bootstrap'
import { find, filter, includes, get, isEmpty } from 'lodash'
import download from 'downloadjs'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import showNotification from 'src/utils/notifications'
import Loader from 'src/components/Loader'
import attachmentStyles from 'src/styles/_forms.scss'
import { updateStatusMessage } from 'src/utils'
import messages from '../messages'
import api from '../../../../api'
import { bytesToSize } from '../utils'
import {
  MAX_FILE_SIZE,
} from '../constants'
import styles from './AttachmentList.scss'

class AttachmentsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAttachment: null,
      isLoading: false,
      showDeleteModal: false,
    }
    this.deleteAttachment = this.deleteAttachment.bind(this)
    this.getAttachmentType = this.getAttachmentType.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { intl: { formatMessage } } = this.props
    const statusMessages = []

    filter(this.props.attachments, (attachment) => {
      const prevAttachment = find(prevProps.attachments, pa => pa.id === attachment.id)
      if (prevAttachment && prevAttachment.status !== attachment.status) {
        if (attachment.status === 'DONE') {
          const message = formatMessage(messages.attachmentUploaded, { filename: attachment.filename })
          statusMessages.push(message)
        } else if (attachment.status === 'FAILED') {
          const message = formatMessage(messages.attachmentUploadFailed, { filename: attachment.filename })
          statusMessages.push(message)
        }
      }
    })

    /* When getting multiple status messages in a same update, we need to batch them
     * so that the screen readers reads them all.
     */
    if (!isEmpty(statusMessages)) {
      batchStatusMessages(statusMessages)
    }
  }

  getAttachmentType(code) {
    const { attachedDocumentTypeCodeset, locale } = this.props
    const dateKey = Object.keys(attachedDocumentTypeCodeset)[0]
    const types = get(attachedDocumentTypeCodeset, dateKey, [])
    const type = find(types, { code })
    return get(type, `name.${locale}`)
  }

  deleteAttachment(attachmentId) {
    const { onDeleteAttachment, referenceNumber } = this.props
    this.setState({ isLoading: true })
    api.deleteAttachment(referenceNumber, attachmentId)
      .then(() => {
        onDeleteAttachment(attachmentId)
        this.setState({
          selectedAttachment: null,
          showDeleteModal: false,
          isLoading: false,
        })
      })
      .catch(() => {
        showNotification({
          level: 'error',
          message: messages.deleteAttachmentErrors,
          modal: true,
          title: messages.deleteAttachmentModalTitle,
        })
        this.setState({
          selectedAttachment: null,
          showDeleteModal: false,
          isLoading: false,
        })
      })
  }

  showDeleteModal(attachment) {
    this.setState({
      selectedAttachment: attachment,
      showDeleteModal: true,
    })
  }

  downloadAttachment(attachment) {
    const { referenceNumber } = this.props
    this.setState({ isLoading: true })
    return api.fetchAttachment(referenceNumber, attachment.id, 'pdf', 0)
      .then((response) => {
        const filename = `${attachment.filename}.pdf`
        download(`data:application/pdf;base64,${encodeURI(response.content)}`, filename, 'application/pdf')
        this.setState({ isLoading: false })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
        if (get(err, 'value.code') === 'notFound') {
          showNotification({
            level: 'error',
            message: messages.attachmentNotFound,
            modal: true,
            title: messages.errorModalTitle,
          })
        }
      })
  }

  renderAttachmentRow(attachment) {
    const { typeFieldCode, readOnly } = this.props

    return (
      <li key={attachment.id} id-qa-test={`label-attachment-${attachment.id}`} className={`row ${styles.uploadRow}`}>
        <Col md={5} xs={12} className={styles.padded}>
          {attachment.status !== 'DONE' && <span><Icon name="pdf" />{attachment.filename}</span>}
          {attachment.status === 'DONE' && <a href={attachment.content} rel="noopener noreferrer" target="_blank">
            <Button
              bsStyle="link"
              onClick={() => this.downloadAttachment(attachment)}
              id-qa-test={`button-open-attachment-${attachment.id}`}
              title={attachment.filename}
              className={attachmentStyles.summaryAttachmentButton}
            >
              <Icon name="pdf" /> {attachment.filename}
            </Button>
          </a>}
        </Col>
        <Col md={4} xs={12} key={`type-${attachment.id}`} className={styles.padded}>
          <span id-qa-test={`label-attachment-${attachment.type}-${attachment.id}`}>
            {this.getAttachmentType(attachment[typeFieldCode])}
          </span>
        </Col>
        {attachment.status === 'DONE' && !readOnly &&
          <Col md={3} xs={12} className={styles.fileAction}>
            <Button
              bsStyle="link"
              onClick={() => this.showDeleteModal(attachment)}
              id-qa-test={`button-remove-attachment-${attachment.id}`}
            >
              <Icon name="delete" />
              <FormattedMessage {...messages.attachmentRemove} />
            </Button>
          </Col>
        }
        {attachment.status !== 'DONE' &&
          <Col md={3} xs={12} key={`status-${attachment.id}`} className={styles.fileAction}>
            <i>
              {attachment.status === 'UPLOADING' && <span>
                <FormattedMessage {...messages.attachmentStatusUploading} />
              </span>}
              {attachment.status === 'IN_PROGRESS' && <span>
                <FormattedMessage {...messages.attachmentStatusInProgress} />
              </span>}
            </i>
          </Col>
        }
      </li>
    )
  }

  render() {
    const { attachments, typeFieldCode, rejectedFiles, isFetching, informationModalVisible } = this.props
    const { selectedAttachment, isLoading } = this.state

    const padding = {
      padding: '7px',
    }

    return (
      <Row style={{ position: 'relative' }}>
        {(isFetching || isLoading) && <Loader blocking />}
        {this.state.showDeleteModal &&
          <Modal
            show={this.state.showDeleteModal}
            showCancel
            cancelMessage={messages.deleteAttachmentModalCancel}
            showContinue
            titleMessage={messages.deleteAttachmentModalTitle}
            continueMessage={messages.deleteAttachmentModalDelete}
            cancelDisabled={isLoading}
            continueDisabled={isLoading}
            onClickContinue={() => this.deleteAttachment(selectedAttachment.id)}
            onClickCancel={() => this.setState({ showDeleteModal: false })}
            loading={isLoading}
            focusButton
          >
            <p className="lead">
              <FormattedMessage
                {...messages.deleteAttachmentModalMessage}
                values={{
                  filename: selectedAttachment.filename,
                }}
              />
            </p>
          </Modal>
        }
        <Row>
          <Col xs={12}>
            <p className={styles.uploadCount}>
              <FormattedMessage
                {...messages.attachmentUploadedCount}
                values={{
                  attachmentCount: filter(attachments, attachment => attachment.status === 'DONE').length,
                }}
              />
            </p>
            <ul className={styles.attachmentList}>
              {filter(attachments, attachment => !includes(['FAILED'], attachment.status))
                .map(attachment =>
                  this.renderAttachmentRow(attachment)
                )}
            </ul>
          </Col>
        </Row>
        {rejectedFiles.length > 0 && !informationModalVisible &&
          <div className={'alert alert-danger'}>
            <h4><FormattedMessage {...messages.attachmentGenericUploadErrorTitle} /></h4>
            <FormattedMessage
              {...messages.attachmentGenericUploadError}
              values={{
                maxFileSize: bytesToSize(MAX_FILE_SIZE),
              }}
            />
            <br /><br />
            {rejectedFiles.map(attachment =>
              <Row key={attachment.id} id-qa-test={`label-${attachment.id}`}>
                <Col xs={6} style={padding}>
                  <Icon name="pdf" /> {attachment.filename || attachment.name}
                </Col>
                <Col xs={6} key={`type-${attachment.id}`} style={padding}>
                  {this.getAttachmentType(attachment[typeFieldCode])}
                </Col>
              </Row>
            )}
          </div>
        }
      </Row>
    )
  }
}

export function batchStatusMessages(statusMessages = []) {
  updateStatusMessage(statusMessages.join(', '))
}

export default injectIntl(AttachmentsList)
