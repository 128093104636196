import { injectReducer } from 'src/reducers/root'
import { PERMITS_ROUTE_PATH } from '../constants'
import StepContainer from './containers/StepContainer.js'

export default store => ({
  path: '(:permitPath)(/:permitId)',
  indexRoute: {
    onEnter: (nextState, replace) => {
      const { permitId, permitPath } = nextState.params
      replace(`${PERMITS_ROUTE_PATH}/${permitPath}/${permitId}/init`)
    },
  },
  childRoutes: [
    { path: ':pathId(/:viewMode)', component: StepContainer },
  ],
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: 'permits' */ './containers/PermitPageContainer'),
      import(/* webpackChunkName: 'permits' */ './reducer'),
    ]).then((modules) => {
      injectReducer(store, { key: 'permits.permit', reducer: modules[1].default })
      next(null, modules[0].default)
    })
  },
})
