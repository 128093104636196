import React from 'react'
import { injectIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { get, some, isEqual, filter } from 'lodash'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import { focusElementById } from 'src/utils'
import ItemsList from '../RepetitiveComponent/components/ItemsList'
import messages from '../RepetitiveComponent/messages'
import { FOCUS_TARGET_ID_PREFIX } from '../../constants'
import InfoElementValidationMessages from '../InfoElementValidationMessages'
import GuaranteePerCustomsProcedureForm from './GuaranteePerCustomsProcedureForm'
import { getCodeset } from '../PermitPage/customParams'

class GuaranteePerCustomsProcedure extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: null,
      showValidation: false,
    }
    this.addNew = this.addNew.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
  }

  componentDidUpdate(prevProps) {
    const submits = get(this.props, 'formApi.submits', 0)
    const prevSubmits = get(prevProps, 'formApi.submits', 0)
    if (submits > prevSubmits) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ showValidation: true })
    }
  }

  updateReferenceAmount(newItems) {
    const { formApi } = this.props

    const infoElements = get(this.props.structure, 'infoElements')
    const hasReferenceAmount = infoElements.some(element => element.code === 'referenceAmount')

    if (!hasReferenceAmount) {
      return
    }

    const referenceAmount = newItems.reduce((total, item) => {
      let sum = total

      if (item.guaranteeExistingDebt) {
        sum += parseFloat(item.guaranteeExistingDebt.replace(',', '.'))
      }

      if (item.guaranteePotentialDebt) {
        sum += parseFloat(item.guaranteePotentialDebt.replace(',', '.'))
      }

      return sum
    }, 0)

    formApi.setValue('referenceAmount.referenceAmount', String(referenceAmount).replace('.', ','))
  }

  addNew(data) {
    const { formApi, infoElement } = this.props
    const newData = {
      guaranteeCustomsProcedure: data.guaranteeCustomsProcedure,
    }

    const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', { codesets: this.props.codesets })

    const selectedCustomsProcedureGuaranteeCodeset = Object.values(
      customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
      customsProcedureGuarantee.code === data.guaranteeCustomsProcedure
    )

    const isExisting = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.velka')
    const isPotential = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.vastuu')

    if (isExisting) {
      newData.guaranteeExistingDebt = data?.guaranteeExistingDebt
      newData.guaranteeExistingDebtCurrency = 'EUR'
      newData.guaranteeExistingDebtDescription = data?.guaranteeExistingDebtDescription
    }

    if (isPotential) {
      newData.guaranteePotentialDebt = data?.guaranteePotentialDebt
      newData.guaranteePotentialDebtCurrency = 'EUR'
      newData.guaranteePotentialDebtDescription = data?.guaranteePotentialDebtDescription
    }

    const newItems = formApi.values[infoElement.code] || []


    newItems.push(newData)
    formApi.setValue(infoElement.code, newItems)
    this.updateReferenceAmount(newItems)
  }

  cancelEditing() {
    this.setState({ selectedItem: null })
  }

  updateItem(oldData, newData) {
    const { formApi, infoElement } = this.props
    const newItems = filter(formApi.values[infoElement.code], item => !isEqual(item, oldData))

    const updatedItem = {
      guaranteeCustomsProcedure: newData.guaranteeCustomsProcedure,
    }

    const customsProcedureGuaranteeCodeset = getCodeset('CustomsProcedureGuarantee', { codesets: this.props.codesets })

    const selectedCustomsProcedureGuaranteeCodeset = Object.values(
      customsProcedureGuaranteeCodeset).find(customsProcedureGuarantee =>
      customsProcedureGuarantee.code === newData.guaranteeCustomsProcedure
    )

    const isExisting = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.velka')
    const isPotential = get(selectedCustomsProcedureGuaranteeCodeset, 'extensionValues.vastuu')

    updatedItem.guaranteeExistingDebt = isExisting ? newData?.guaranteeExistingDebt : null
    updatedItem.guaranteeExistingDebtCurrency = isExisting ? 'EUR' : null
    updatedItem.guaranteeExistingDebtDescription = isExisting ? newData?.guaranteeExistingDebtDescription : null

    updatedItem.guaranteePotentialDebt = isPotential ? newData?.guaranteePotentialDebt : null
    updatedItem.guaranteePotentialDebtCurrency = isPotential ? 'EUR' : null
    updatedItem.guaranteePotentialDebtDescription = isPotential ? newData?.guaranteePotentialDebtDescription : null

    newItems.push(updatedItem)
    formApi.setValue(infoElement.code, newItems)

    this.updateReferenceAmount(newItems)
  }

  deleteItem(index) {
    const { formApi, infoElement } = this.props
    const newItems = formApi.values[infoElement.code].slice(0)
    newItems.splice(index, 1)
    formApi.setValue(infoElement.code, newItems)

    this.updateReferenceAmount(newItems)
  }

  focus = (listId) => {
    const destId = `${FOCUS_TARGET_ID_PREFIX}${listId}`
    focusElementById(destId, 1000)
  }

  render() {
    const {
      infoElement,
      locale,
      formApi,
      renderField,
      intl,
      codesets,
    } = this.props

    const { showValidation } = this.state

    const fields = get(this.props.infoElement, 'fields')
    const absolutelyRequired = get(this.props.infoElement, 'mandatory') && some(fields, 'mandatory')
    const conditionallyRequired = infoElement.requiredWhen && infoElement.requiredWhen(formApi.values)
    const mandatory = absolutelyRequired || conditionallyRequired
    const newRowDescriptionId = `new-row-description-${this.props.infoElement.code}`
    const items = formApi.values[infoElement.code] || []

    return (
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <InfoElementHeaderArea
              infoElement={infoElement}
              locale={locale}
            />
          </Col>
        </Row>
        <Row className="form-group">
          <Col xs={12}>
            <GuaranteePerCustomsProcedureForm
              buttonDescriptionId={newRowDescriptionId}
              selectedItem={this.state.selectedItem}
              cancelEditing={this.cancelEditing}
              updateItem={this.updateItem}
              locale={locale}
              infoElement={infoElement}
              renderField={renderField}
              addNew={this.addNew}
              structure={this.props?.structure}
            />
            <ItemsList
              items={items}
              fields={infoElement.fields}
              locale={locale}
              infoElement={infoElement}
              selectItem={item => this.setState({ selectedItem: item })}
              deleteItem={this.deleteItem}
              codesets={codesets}
              emptyValue={''}
              guaranteeCustomsProcedure
            />
            {mandatory && items.length === 0 &&
              <div className={showValidation ? 'has-error' : ''}>
                <p
                  id={newRowDescriptionId}
                  className="help-block"
                >
                  {intl.formatMessage(messages.mandatoryRowDescription)}
                </p>
              </div>
            }
          </Col>
          {items.length > 0 && <InfoElementValidationMessages infoElement={infoElement} formApi={this.props.formApi} />}
        </Row>
      </Col>
    )
  }
}

export default injectIntl(GuaranteePerCustomsProcedure)
