import React from 'react'
import ReactMarkdown from 'react-markdown'
import { withRouter } from 'react-router'
import { Row, Col, Grid } from 'react-bootstrap'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { get } from 'lodash'

import styles from './Footer.scss'
import { setShowCookieNotice } from '../actions'
import { deleteCookie } from '../utils/utils'
import FooterFeedbackButton from './FooterFeedbackButton'

function ButtonRenderer({ href, children }) {
  const target = href.startsWith('mailto:') ? '_top' : '_blank'
  return (
    <a
      href={href}
      target={target}
      rel="noopener noreferrer"
      className={classNames('btn btn-default', styles.defaultButton)}
    >
      {children}
    </a>
  )
}

function LinkRenderer({ href, children }) {
  const target = href.startsWith('mailto:') ? '_top' : '_blank'
  return (
    <a
      href={href}
      target={target}
      rel="noopener noreferrer"
      className={classNames('btn btn-link', styles.defaultLink)}
    >
      {children}
    </a>
  )
}

function Footer({
  intl: { formatMessage },
  showCookieDialog,
  config,
}) {
  function removeCookies() {
    sessionStorage.removeItem('__snbref')
    localStorage.removeItem('acceptedCookieNotice')
    localStorage.removeItem('cookieMode')
    showCookieDialog()
    document.cookie.split(';').forEach((cookie) => {
      if (JSON.parse(get(config.bootstrapConfig, 'cookie_blacklist', [])).some(unwantedCookie => cookie.trim().startsWith(unwantedCookie))) {
        deleteCookie(cookie.split('=')[0])
      }
    })
    setTimeout(() => document.getElementById('cookieNotice-accesibility').focus(), 100)
  }

  const footerLeftContent = [
    { key: '/pageFooter/accessibilityfeedback', content: formatMessage({ id: '/pageFooter/accessibilityfeedback' }), isButton: true },
    { key: '/pageFooter/contact', content: formatMessage({ id: '/pageFooter/contact' }), isButton: true },
    { key: '/pageFooter/removeCookies', content: formatMessage({ id: '/pageFooter/removeCookies' }), isButton: true, func: removeCookies },
  ]

  const footerRightContent = [
    { key: '/pageFooter/privacyStatement', content: formatMessage({ id: '/pageFooter/privacyStatement' }), isButton: true },
    { key: '/pageFooter/serviceUsage', content: formatMessage({ id: '/pageFooter/serviceUsage' }), isButton: true },
    { key: '/pageFooter/accessibility', content: formatMessage({ id: '/pageFooter/accessibility' }), isButton: true },
    { key: '/pageFooter/copyright', content: formatMessage({ id: '/pageFooter/copyright' }), isButton: true },
  ]

  const reactMarkdownText = (entry, renderer) => {
    const label = entry.content !== entry.key ? entry.content : ''

    if (entry.func) {
      return <ReactMarkdown
        source={label}
        renderers={{
          text: () => (
            <button
              className={classNames('btn btn-default', styles.defaultButton)}
              onClick={entry.func}
            >
              {label}
            </button>
          ),
        }}
      />
    }

    return <ReactMarkdown
      source={label}
      renderers={{
        link: renderer,
      }}
      linkTarget="_blank"
    />
  }

  return (
    <footer className={styles.footer}>
      <Grid>
        <Row>
          <Col sm={12}>
            <FooterFeedbackButton />
          </Col>
        </Row>
        <hr className={styles.borderLine} />
        <Row>
          <Col md={6}>
            { footerLeftContent &&
              footerLeftContent.map(entry =>
                <div className={styles.inlineParagraph} key={`${entry.key}`}>
                  {reactMarkdownText(entry, ButtonRenderer)}
                </div>
              )
            }
          </Col>
          <Col md={6} className={styles.containerRight}>
            { footerRightContent &&
              footerRightContent.map(entry =>
                <div className={styles.inlineParagraph} key={`${entry.key}`}>
                  {reactMarkdownText(entry, LinkRenderer)}
                </div>
              )
            }
          </Col>
        </Row>
      </Grid>
    </footer>
  )
}

const mapStateToProps = ({ config }) => ({
  config,
})

const mapActionCreators = {
  showCookieDialog: () => setShowCookieNotice(true),
}

export default withRouter(injectIntl(connect(mapStateToProps, mapActionCreators)(Footer)))
