/**
 * @file Intrastat app route definition with Webpack code splitting
 * and asynchronous reducer injection to Redux store
 *
 * @author Sami Tiilikainen
 */

import { endsWith } from 'lodash'
import { injectReducer } from 'src/reducers/root'
import { NEW_DECLARATION_PATH_ID, SUMMARY_ROUTE_PATH, HEADERS_ROUTE_PATH } from './constants'
import { DECLARATION_ROUTE_PATH } from '../constants'

export default store => ({
  path: `${DECLARATION_ROUTE_PATH}(/:declarationId)`,
  indexRoute: {
    onEnter: (nextState, replace) => {
      let basePath = nextState.location.pathname
      if (!endsWith(basePath, '/')) {
        basePath += '/'
      }
      if (!nextState.params.declarationId) {
        basePath += `${NEW_DECLARATION_PATH_ID}/`
      }
      replace(`${basePath}${HEADERS_ROUTE_PATH}`)
    },
  },
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: 'intrastatDeclaration' */ './containers/ReadOnlyDeclarationContainer'),
      import(/* webpackChunkName: 'intrastatDeclaration' */ './containers/EditDeclarationContainer'),
      import(/* webpackChunkName: 'intrastatDeclaration' */ './reducer'),
    ]).then((modules) => {
      let ContainerComponent
      if (nextState.routes.find(it => it.path.startsWith(SUMMARY_ROUTE_PATH))) {
        ContainerComponent = modules[0].default
      } else {
        ContainerComponent = modules[1].default
      }

      injectReducer(store, { key: 'intrastat.declaration', reducer: modules[2].default })
      next(null, ContainerComponent)
    })
  },
  getChildRoutes(location, next) {
    Promise.all([
      import(/* webpackChunkName: 'intrastatDeclaration' */ './routes/headers'),
      import(/* webpackChunkName: 'intrastatDeclaration' */ './routes/rows'),
      import(/* webpackChunkName: 'intrastatDeclaration' */ './routes/confirmation'),
      import(/* webpackChunkName: 'intrastatDeclaration' */ './routes/summary'),
    ]).then((modules) => {
      next(null, [
        modules[0].default,
        modules[1].default,
        modules[2].default,
        modules[3].default,
      ])
    })
  },
})
