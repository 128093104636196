/* global snoobi */
import 'whatwg-fetch'
import URLSearchParamsPolyfill from 'url-search-params'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import createBrowserHistory from 'history/lib/createBrowserHistory'
import { useRouterHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import fi from 'react-intl/locale-data/fi'
import sv from 'react-intl/locale-data/sv'
import { get, includes } from 'lodash'
import 'react-dates/initialize'

// Import common utils here to make sure webpack includes them in app.js
import validationUtils from 'src/utils/validation' // eslint-disable-line no-unused-vars
import ValidationMessage from 'src/components/validation/ValidationMessage' // eslint-disable-line no-unused-vars

import { createStore } from './store'
import Root from './containers/Root'
import RootError from './containers/RootError'
import {
  getUserData,
  storeBootstrapConfig,
  storeCmsContent,
  switchCmsLocale,
  showGlobalNotification,
  suomiFiAuthorizationTypeRequest,
  sendLogoutRequestAndExit,
  changeReturnUriAfterRoleSwitch,
} from './actions'
import {
  getParams,
  getValidLocale,
  persistChangeToStorage,
  setPageTitle,
} from './utils/index'
import {
  isTermsAcceptedInBrowserStorage,
  handleAfterLoginRedirects,
} from './utils/auth'
import createRoutes from './routes/createRoutes'
import { checkHttpStatus, parseJSON } from './utils/http'
import apiMessages from './utils/apiMessages'

import { PATH_TO_TITLE_MAP, RETURN_URI_QUERY_PARAM, SERVICE_PATHS } from './constants'

const MOUNT_ELEMENT = document.getElementById('root')

const helpServiceUrl = `${window ? window.location.origin : 'https://localhost:3000'}/asiointipalvelu/palvelut/tm_help`

if (window) {
  window.helpServiceUrl = helpServiceUrl
}


// TODO: Dynamically import and add locales for languages specified in constants?
//       ES6 imports are static, try System.import?
// TODO: Move languages from constants to t2_config and fetch them?
addLocaleData(en)
addLocaleData(fi)
addLocaleData(sv)

// Configure history for react-router
const browserHistory = useRouterHistory(createBrowserHistory)({
  basename: BASENAME,
})

if (!global.URLSearchParams) {
  global.URLSearchParams = URLSearchParamsPolyfill
}
const searchParams = new URLSearchParams(window.location.search)
const queryLocale = searchParams.get('lang') || null

/**
 * NOTE:
 * Navigator.language is the browser/system language, not necessarily editable by the user.
 * Navigator.languages instead contains all languages user specified in browser settings.
 */
// Get initial language from user's browser
const initialStoredLocale = localStorage.getItem('locale')
const browserLocale = navigator.language && navigator.language.split('-')[0]
const initialLocale = getValidLocale(queryLocale, initialStoredLocale, browserLocale)
const urlSearchParams = getParams(window.location)
const loginError = urlSearchParams.get('errorCode') || null
const suomiFiAuthorizationCallback = urlSearchParams.get('suomiFiAuthorizationCallback') || null
const suomiFiAuthorization = urlSearchParams.get('suomiFiAuthorization') || null
const suomiFiAuthorizationError = urlSearchParams.get('suomiFiAuthorizationError') || null
const rejectedAuthorizationsError = urlSearchParams.get('rejectedAuthorizations') || null
const returnUriAfterRoleSwitchParam = urlSearchParams.get(RETURN_URI_QUERY_PARAM) || null
const redirectAfterLoginLocation = localStorage.getItem('redirectAfterLoginLocation')
// We allow the authorization type selector only when logging in,
// redirectAfterLoginLocation value is removed when authorization type is selected
const authorizationTypeSelectorAllowed = suomiFiAuthorization && redirectAfterLoginLocation


if (initialLocale) {
  moment.locale(initialLocale)
}
moment.updateLocale('sv-FI', {
  longDateFormat: {
    L: 'DD.MM.YYYY',
  },
})

// Initial state for redux store
const initialState = {
  locale: initialLocale,
  auth: {},
  ...window.__INITIAL_STATE__, // eslint-disable-line no-underscore-dangle
}

// Create redux store and sync with react-router-redux
const store = createStore(initialState, browserHistory)
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: state => state.router,
})

// Further token/locale/tos changes in redux store are persisted to browser storage
let persistedLocale = initialStoredLocale
let persistedPath = '/'
store.subscribe(() => {
  const state = store.getState()
  const oldLocale = persistedLocale
  const oldPath = persistedPath
  persistedLocale = persistChangeToStorage(
    localStorage,
    'locale',
    get(state, 'locale'),
    persistedLocale,
  )
  if (persistedLocale && oldLocale !== persistedLocale) {
    store.dispatch(switchCmsLocale({ locale: persistedLocale }))
    moment.locale(persistedLocale)
  }
  if (persistedPath !== state.router.locationBeforeTransitions.pathname) {
    const newPathName = state.router.locationBeforeTransitions.pathname
    if (includes(SERVICE_PATHS, newPathName)) {
      localStorage.setItem('previousServicePath', newPathName)
    }
    persistedPath = newPathName
  }
  if (persistedPath !== oldPath || persistedLocale !== oldLocale) {
    setPageTitle(PATH_TO_TITLE_MAP, persistedPath, persistedLocale)
  }
})

const checkReturnUriAfterRoleSwitch = (returnUriAfterRoleSwitch) => {
  if (returnUriAfterRoleSwitch) {
    store.dispatch(changeReturnUriAfterRoleSwitch(returnUriAfterRoleSwitch))
  }
}

browserHistory.listen((location) => {
  if (typeof snoobi !== 'undefined') {
    snoobi.trackPageView(`/asiointipalvelu${location.pathname}`)
  }
  const returnUriAfterRoleSwitch = get(location, `query.${RETURN_URI_QUERY_PARAM}`, null)
  checkReturnUriAfterRoleSwitch(returnUriAfterRoleSwitch)
})

let render = (key = null) => {
  if (!authorizationTypeSelectorAllowed && !suomiFiAuthorizationError) {
    handleAfterLoginRedirects(store)
  } else if (suomiFiAuthorizationCallback) {
    handleAfterLoginRedirects(store)
  }

  Promise.all([
    fetch(`${helpServiceUrl}/config/bootstrapConfig`)
      .then(checkHttpStatus)
      .then(parseJSON),
    fetch(`${helpServiceUrl}/config/services`)
      .then(checkHttpStatus)
      .then(parseJSON),
    fetch(`${helpServiceUrl}/component/common`)
      .then(checkHttpStatus)
      .then(parseJSON),
  ])
    .catch((e) => {
      ReactDOM.render(
        <RootError locale={persistedLocale} />,
        MOUNT_ELEMENT,
      )
      throw e
    })
    .then(([bootstrapConfig, services, cmsContentData]) => {
      store.dispatch(storeBootstrapConfig({ bootstrapConfig, ...services }))
      store.dispatch(storeCmsContent({ locale: persistedLocale, data: cmsContentData }))
      const features = bootstrapConfig.features
      const routes = createRoutes(store, features)

      if (loginError || suomiFiAuthorizationError) {
        const errorMsg = loginError || suomiFiAuthorizationError
        store.dispatch(showGlobalNotification({
          modal: true,
          level: 'error',
          message: apiMessages[errorMsg] || apiMessages.suomiFiAuthorizationFailure,
        }))
      }

      if (suomiFiAuthorizationError) {
        store.dispatch(sendLogoutRequestAndExit())
      }

      if (!authorizationTypeSelectorAllowed && !suomiFiAuthorizationError) {
        // Dispatch getUserData thunk to get all user data from server
        store.dispatch(getUserData(cmsContentData, isTermsAcceptedInBrowserStorage))
      }

      if (authorizationTypeSelectorAllowed) {
        store.dispatch(suomiFiAuthorizationTypeRequest(true))
      }
      checkReturnUriAfterRoleSwitch(returnUriAfterRoleSwitchParam)

      if (rejectedAuthorizationsError) {
        store.dispatch(showGlobalNotification({
          level: 'error',
          message: apiMessages.rejectedAuthorizationsError,
          title: apiMessages.authorizationNoteHeader,
          modal: true,
        }))
      }

      ReactDOM.render(
        <Root
          store={store}
          history={history}
          routes={routes}
          routerKey={key}
        />,
        MOUNT_ELEMENT,
      )
    })
}

// Enable HMR and catch runtime errors in RedBox
// This code is excluded from production bundle
if (DEV && module.hot) {
  const RedBox = require('redbox-react').default // eslint-disable-line global-require

  const renderApp = render
  const renderError = (error) => {
    ReactDOM.render(<RedBox error={error} />, MOUNT_ELEMENT)
  }
  render = () => {
    try {
      renderApp(Math.random())
    } catch (error) {
      renderError(error)
    }
  }
  module.hot.accept(['./routes/createRoutes'], () => render())
}

// All modern browsers, except `Safari`, have implemented
// the `ECMAScript Internationalization API`.
// For that we need to patch in on runtime.
if (!global.Intl) {
  import(/* webpackChunkName: "i18n" */ 'intl').then((module) => {
    module.default // eslint-disable-line
    render()
  })
} else {
  render()
}
