/**
 * React root component wrapping store, intl, router etc
 * @module containers/Root
 */

import {
  findServicePath,
  getApplicationAuthorizationConfig,
  getAuthorizationTypes,
  redirectToRoleSelector,
} from 'src/utils/auth'
import { LANGUAGE_NATION, RETURN_URI_QUERY_PARAM } from 'src/constants'
import React from 'react'
import { Router } from 'react-router'
import { Provider, connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { get } from 'lodash'
import * as i18n from '../i18n'
import AuthorizationWrapper from '../components/AuthorizationWrapper'
import Snoobi from '../components/Snoobi'
import CookieNotice from '../components/CookieNotice'
import Modal from '../components/Modal'
import {
  sendSelectAuthorization,
  sendAcceptTerms,
  switchLocale,
  sendLogoutRequestAndExit,
  sendSelectAuthorizationType,
  changeShowSwitchRoleConfirmationModal,
  changeShowLogoutConfirmationModal,
} from '../actions'
import { collectCmsMessages } from '../utils/index'

function Root({
  store,
  routes,
  routerKey,
  locale,
  onSwitchLocale,
  history,
  auth,
  onSelectAuthorization,
  onSelectAuthorizationType,
  onTermsAccepted,
  onLogout,
  snoobiAccount,
  bootstrapConfig,
  cmsMessages,
  cmsContentLoaded,
  hideRoleSelectorConfirmationModal,
  showSwitchRoleConfirmationModal,
  authenticationContent,
  showLogoutConfirmationModal,
  hideLogoutConfirmationModal,
}) {
  document.documentElement.lang = `${locale}-${LANGUAGE_NATION[locale]}`

  const allMessages = Object.assign({}, i18n[locale], cmsMessages)

  if (!cmsContentLoaded) {
    return null
  }

  const focusOnContent = () => {
    document.getElementById('accessibility').focus()
  }

  const continueToLogout = () => {
    hideLogoutConfirmationModal()
    history.push('logout')
  }

  const authorizationTypes = getAuthorizationTypes(bootstrapConfig)
  const uri = findServicePath(window.location.pathname)
  const returnUrl = `?${RETURN_URI_QUERY_PARAM}=${uri}`

  const {
    roleSelectorConfirmationModalContinueButtonText,
    roleSelectorConfirmationModalTitle,
    roleSelectorConfirmationModalBodyText,
    logoutConfirmationModalContinueButtonText,
    logoutConfirmationModalTitle,
    logoutConfirmationModalBodyText,
  } = authenticationContent

  return ([
    <Snoobi key="snoobi" account={snoobiAccount} />,
    <Provider key="main" store={store}>
      <IntlProvider locale={locale} messages={allMessages}>
        <AuthorizationWrapper
          auth={auth}
          onSelectAuthorization={onSelectAuthorization}
          onSelectAuthorizationType={onSelectAuthorizationType}
          onTermsAccepted={onTermsAccepted}
          onLogout={onLogout}
          onSwitchLocale={onSwitchLocale}
          locale={locale}
          authorizationTypes={authorizationTypes}
          suomiFiAuthorization={
            auth.suomiFiAuthorizationRequest === true
              ? getApplicationAuthorizationConfig(bootstrapConfig)
              : null
          }
          history={history}
        >
          <CookieNotice onAccept={focusOnContent} />
          <Router history={history} children={routes} key={routerKey} />

          <Modal
            show={showSwitchRoleConfirmationModal}
            continueMessage={roleSelectorConfirmationModalContinueButtonText}
            onClickCancel={hideRoleSelectorConfirmationModal}
            onClickContinue={() => redirectToRoleSelector(returnUrl, history, hideRoleSelectorConfirmationModal)}
            locale={locale}
            focusDisableButton
            showContinue
            titleMessage={roleSelectorConfirmationModalTitle}
          >
            <div id="dialog-message">
              {roleSelectorConfirmationModalBodyText}
            </div>
          </Modal>
          <Modal
            show={showLogoutConfirmationModal}
            continueMessage={logoutConfirmationModalContinueButtonText}
            onClickCancel={hideLogoutConfirmationModal}
            onClickContinue={() => continueToLogout()}
            locale={locale}
            focusDisableButton
            showContinue
            titleMessage={logoutConfirmationModalTitle}
          >
            <div id="dialog-message">
              {logoutConfirmationModalBodyText}
            </div>
          </Modal>
        </AuthorizationWrapper>
      </IntlProvider>
    </Provider>,
  ])
}

const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages')

  return {
    locale: state.locale,
    auth: state.auth,
    cmsContentLoaded: get(state, 'content.cmsContentLoaded'),
    cmsMessages,
    authenticationContent: collectCmsMessages('/authentication', cmsMessages),
    snoobiAccount: get(state.config, 'bootstrapConfig.nakki_snoobi_account'),
    bootstrapConfig: state.config.bootstrapConfig,
    showSwitchRoleConfirmationModal: get(state, 'auth.showSwitchRoleConfirmationModal', false),
    showLogoutConfirmationModal: get(state, 'auth.showLogoutConfirmationModal', false),
  }
}

const mapActionCreators = {
  onSelectAuthorization: sendSelectAuthorization,
  onSelectAuthorizationType: sendSelectAuthorizationType,
  onTermsAccepted: sendAcceptTerms,
  onLogout: sendLogoutRequestAndExit,
  onSwitchLocale: switchLocale,
  hideRoleSelectorConfirmationModal: () => changeShowSwitchRoleConfirmationModal(false),
  hideLogoutConfirmationModal: () => changeShowLogoutConfirmationModal(false),
}

export default connect(mapStateToProps, mapActionCreators)(Root)
