import { injectReducer } from 'src/reducers/root'
import {
  CUSTOMS_INFORMATION_FORM_ROUTE_PATH,
  SUMMARY_ROUTE_PATH,
} from './constants'

export default store => ({
  path: CUSTOMS_INFORMATION_FORM_ROUTE_PATH,
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: 'forms' */ './container'),
      import(/* webpackChunkName: 'forms' */ './reducer'),
      import(/* webpackChunkName: 'forms' */ './routes/summary/container'),
    ]).then((modules) => {
      injectReducer(store, { key: 'forms', reducer: modules[1].default })
      let ContainerComponent
      if (nextState.routes.find(it => it.path.startsWith(SUMMARY_ROUTE_PATH))) {
        ContainerComponent = modules[2].default
      } else {
        ContainerComponent = modules[0].default
      }
      next(null, ContainerComponent)
    })
  },
  getChildRoutes(location, next) {
    Promise.all([
      import(/* webpackChunkName: 'forms' */ './routes/summary'),
    ]).then((modules) => {
      next(null, [
        modules[0].default,
      ])
    })
  },
})
