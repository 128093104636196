import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { injectIntl } from 'react-intl'
import { get } from 'lodash'
import Step from '../components/Step'
import { getApplicant } from '../permitHelper'
import { isApplicantPerson } from '../../../../utils/auth'

const mapStateToProps = state => ({
  applicant: getApplicant(),
  auth: state.auth, // Re-render Step Component when auth state changes
  isPerson: isApplicantPerson(),
  locale: state.locale,
  codesets: get(state, 'codeset.cachedCodesets', []),
  isUploadingAttachment: get(state, 'permits.permit.isUploadingAttachment'),
  isAttachmentInProgress: get(state, 'permits.permit.isAttachmentInProgress'),
  showApplicationTabActionButtons: get(state, 'config.features.SHOW_APPLICATION_TAB_ACTION_BUTTONS', false),
})

export default withRouter(
  connect(mapStateToProps)(
    injectIntl(
      Step
    )))
