
import React from 'react'
import ReactDOM from 'react-dom'
import { Manager, Target, Popper as Pop, Arrow } from 'react-popper'
import { RootCloseWrapper } from 'react-overlays'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import messages from './messages'

export default class Popper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popperOpen: false,
    }
    this.togglePopper = this.togglePopper.bind(this)
  }

  togglePopper(open) {
    const newStateOpen = typeof open === 'boolean' ? open : !this.state.popperOpen
    if (newStateOpen && !this.state.popperOpen && typeof this.props.onShow === 'function') {
      this.props.onShow()
    }
    this.setState({ popperOpen: newStateOpen })
  }

  render() {
    const { id, type, key, content, placement, closeIcon, className, large, children, usePortal } = this.props
    let { trigger, rootClose } = this.props

    if (type !== 'tooltip' && type !== 'popover') {
      throw new Error('Missing required type prop on Popper')
    }
    if (trigger === undefined) {
      trigger = type === 'tooltip' ? 'hover' : 'click'
    }
    if (rootClose === undefined) {
      rootClose = type === 'popover' || false
    }

    const cloneProps = {
      'aria-expanded': this.state.popperOpen,
    }
    if (id && this.state.popperOpen) {
      cloneProps['aria-describedby'] = id
    }
    if (trigger === 'click') {
      cloneProps.onClick = this.togglePopper
    } else if (trigger === 'hover') {
      cloneProps.onMouseOver = cloneProps.onFocus = () => this.togglePopper(true)
      cloneProps.onMouseOut = cloneProps.onBlur = () => this.togglePopper(false)
    }
    const child = React.Children.only(children)
    const targetChild = React.cloneElement(child, cloneProps)

    let popper
    if (this.state.popperOpen) {
      popper = (
        <Pop id={id} key={key} placement={placement} className={classNames(type, large && 'large')}>
          <div className={`${type}-content`}>
            {closeIcon &&
              <InteractiveElement
                aria-hidden="true"
                onClick={() => this.togglePopper(false)}
                className="close"
                tabIndex="-1"
              >
                <Icon name="close" />
                <span className="sr-only"><FormattedMessage {...messages.close} /></span>
              </InteractiveElement>
            }
            {content}
          </div>
          <Arrow className="arrow" />
        </Pop>
      )
      // Root close makes sure popper is closed on outside click, esc-button press etc.
      if (rootClose) {
        popper = (
          <RootCloseWrapper onRootClose={() => this.togglePopper(false)}>
            {popper}
          </RootCloseWrapper>
        )
      }
      // Portal is used to move dom element to document body for better rendering in overflow: hidden containers.
      if (usePortal) {
        popper = ReactDOM.createPortal(popper, document.getElementsByTagName('body')[0])
      }
    }
    return (
      <Manager>
        <Target
          className={className}
          component="span"
        >
          {targetChild}
        </Target>
        {popper}
      </Manager>
    )
  }
}
