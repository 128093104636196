import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import { Jumbotron, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Icon from 'src/components/Icon'
import Heading from 'src/layout/Heading'

const messages = defineMessages({
  pageNotFoundTitle: {
    id: 'pageNotFound.title',
    description: 'Title shown when a page for requested url is not found in UI',
    defaultMessage: 'Page not found',
  },
  pageNotFoundDescription: {
    id: 'pageNotFound.description',
    description: 'Description shown when a page for requested url is not found in UI',
    defaultMessage: 'The page you are trying to reach does not exist.',
  },
  pageNotFoundHomeLink: {
    id: 'pageNotFound.homeLink',
    description: 'Link text for home button in page not found page',
    defaultMessage: 'Go to homepage',
  },
})

export default function () {
  return (
    <div>
      <Heading message={messages.pageNotFoundTitle} compact />
      <main className="container" role="main" id="main">
        <Jumbotron>
          <div className="text-center lead">
            <Icon name="info" huge /><br /><br />
            <FormattedMessage {...messages.pageNotFoundDescription} />
          </div>
          <div className="text-center">
            <LinkContainer to="/">
              <Button bsStyle="primary">
                <FormattedMessage {...messages.pageNotFoundHomeLink} />
              </Button>
            </LinkContainer>
          </div>
        </Jumbotron>
      </main>
    </div>
  )
}
