import { FORMS_ROUTE_PATH } from './constants'
import customsInformationFormRoute from './customsInformation'
import eoriFormRoute from './eori'
import locationInformationFormRoute from './locationInformation'


export default (store, features) => ({
  path: FORMS_ROUTE_PATH,
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: "forms" */ './container'),
    ]).then(([containerModule]) => {
      next(null, containerModule.default)
    })
  },
  childRoutes: [
    ...(features.FORM_CUSTOMS_INFORMATION ? [customsInformationFormRoute(store)] : []),
    ...(features.FORM_EORI ? [eoriFormRoute(store)] : []),
    ...(features.FORM_LOCATION_INFORMATION ? [locationInformationFormRoute(store)] : []),
  ],
})
