import React from 'react'
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { isObject, includes, isNil, find, sortBy, indexOf, get } from 'lodash'
import Autosuggest from 'react-autosuggest'
import InteractiveElement from 'src/components/InteractiveElement'
import Icon from 'src/components/Icon'
import styles from 'src/styles/_forms.scss'
import { fixAutocompleteAccessibility } from 'src/utils'
import CommonFormGroup from './CommonFormGroup'
import { messages as inputMessages } from './InputArea'
import '../form/autocomplete.scss'

const messages = defineMessages({
  errorNoOptions: {
    id: 'form.error.noOptionsPlaceholder',
    description: 'Error placeholder for input without necessary data',
    defaultMessage: 'Error in loading options',
  },
  selectNonGroupedTitle: {
    id: 'form.selectNonGroupedTitle',
    description: 'Title for option group',
    defaultMessage: 'Other',
  },
})

class Autocomplete extends React.Component {
  static renderSuggestion(option, { query }) {
    const suggestion = option.title || option.value
    const filterMatchAt = suggestion.toLowerCase().indexOf(query.toLowerCase())
    if (!query || !query.length || filterMatchAt < 0) {
      return suggestion
    }
    const beginning = suggestion.slice(0, filterMatchAt)
    const match = suggestion.slice(filterMatchAt, filterMatchAt + query.length)
    const end = suggestion.slice(filterMatchAt + query.length)
    return (
      <span>{beginning}<strong>{match}</strong>{end}</span>
    )
  }

  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onClearSelection = this.onClearSelection.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.filterSuggestions = this.filterSuggestions.bind(this)
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this)

    let selectedOption
    if (props.options && props.input.value) {
      selectedOption = props.options.find(it => it.value === props.input.value)
    }
    this.state = {
      value: selectedOption ? selectedOption.title : '',
      suggestions: props.options || [],
      multiSection: !isNil(props.optionGroups),
    }
  }

  componentDidMount() {
    const inputName = get(this.props, 'input.name')
    fixAutocompleteAccessibility(inputName)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.input.value && this.props.input.value) {
      this.setState({ value: '' })
      return
    }

    if (!nextProps.input.value && nextProps.initialized) {
      this.setState({ value: '' })
      return
    }
    if (!nextProps.input.value) {
      return
    }
    const selectedOption = nextProps.options && nextProps.options.find(it => it.value === nextProps.input.value)
    this.setState({
      value: selectedOption ? selectedOption.title : '',
    })
  }

  componentDidUpdate() {
    const inputName = get(this.props, 'input.name')
    fixAutocompleteAccessibility(inputName)
  }

  onChange(event, { newValue }) {
    this.setState({
      value: newValue,
    })
  }

  onBlur() {
    const value = this.state.value
    const filteredSuggestions = value && this.filterSuggestions(value)
    const suggestionList = this.state.multiSection ? get(filteredSuggestions[0], 'values', []) : filteredSuggestions
    if (suggestionList.length === 1) {
      const matchingOption = filteredSuggestions[0]
      let optionValueObj
      if (this.state.multiSection) {
        optionValueObj = get(matchingOption, 'values[0]', undefined)
      } else {
        optionValueObj = matchingOption
      }
      if (optionValueObj) {
        this.props.input.onBlur(optionValueObj.value)
        this.onSuggestionSelected(undefined, { suggestionValue: optionValueObj.title })
      } else {
        this.setState({ value: '' })
        this.props.input.onBlur(null)
      }
    }
  }

  onClearSelection() {
    const { input, formApi } = this.props

    this.setState({ value: '' })
    input.onChange(null)

    if (formApi) {
      formApi.setError(input.name, null)
    }

    setTimeout(() => this.autosuggestRef.input.focus(), 0)
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.filterSuggestions(value) || [],
    })
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    })
  }

  onSuggestionSelected(event, { suggestionValue }) {
    const matchingOption = this.props.options.find(it => it.title === suggestionValue)
    if (matchingOption) {
      this.props.input.onChange(matchingOption.value)
    }
  }

  shouldRenderSuggestions(value) {
    if (!value) {
      return true
    }
    const suggestions = this.filterSuggestions(value)
    if (suggestions.length === 0) {
      return false
    } else if (suggestions.length === 1) {
      if (!this.state.multiSection && suggestions[0].value.title === value) {
        return false
      }
      if (this.state.multiSection && suggestions[0].title === value) {
        return false
      }
    }
    return true
  }

  groupOptions(options) {
    const {
      optionGroups,
      intl: { formatMessage },
    } = this.props

    const groups = {}
    const nonGrouped = []
    const combinedOptions = []

    options.forEach((option) => {
      let optGroup = null
      optionGroups.forEach((optionGroup) => {
        if (includes(optionGroup.values, option.value)) {
          optGroup = optionGroup
        }
      })
      if (optGroup) {
        if (!groups[optGroup.title.id]) {
          groups[optGroup.title.id] = []
        }
        groups[optGroup.title.id].push({
          ...option,
          sort: optGroup.sort,
        })
      } else {
        nonGrouped.push({
          ...option,
          sort: 10,
        })
      }
    })

    Object
      .keys(groups)
      .sort((a, b) => groups[a][0].sort - groups[b][0].sort)
      .forEach((key) => {
        const optGroup = find(optionGroups, group => group.title.id === key)
        const sortedOptions = sortBy(groups[key], option => indexOf(optGroup.values, option.id))
        combinedOptions.push({
          title: formatMessage(optGroup.title),
          values: sortedOptions,
        })
      })

    combinedOptions.push({
      title: formatMessage(messages.selectNonGroupedTitle),
      values: nonGrouped,
    })

    return combinedOptions
  }

  filterSuggestions(value) {
    const { multiSection } = this.state
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (!this.props.options || !this.props.options.length) {
      return []
    }

    const sorted = sortBy(this.props.options, ['title'])

    if (inputLength === 0) {
      return multiSection ? this.groupOptions(sorted) : sorted
    }

    const options = this.props.options
      .map((option) => {
        let relevance = 0

        const valueMatchOffset = option.value.toLowerCase().indexOf(inputValue)

        if (valueMatchOffset === 0) {
          relevance += 100
        } else if (valueMatchOffset > 0) {
          relevance += 30
        }

        const titleMatchOffset = option.title.toLowerCase().indexOf(inputValue)

        if (titleMatchOffset === 0) {
          relevance += 50
        } else if (titleMatchOffset > 0) {
          relevance += 1
        }

        return Object.assign({}, option, { relevance })
      })

    const sortedOptions = options
      .filter(option => option.relevance > 0)
      .sort((optionA, optionB) => {
        if (optionA.relevance === optionB.relevance) {
          return optionA.title.localeCompare(optionB.title)
        }

        return optionB.relevance - optionA.relevance
      })

    return multiSection ? this.groupOptions(sortedOptions) : sortedOptions
  }

  render() {
    const {
      value,
      title,
      suggestions,
      multiSection,
    } = this.state

    const {
      input,
      disabled,
      options,
      mandatory,
      fetchError,
      className,
      intl: { formatMessage },
    } = this.props

    let { placeholderMessage } = this.props

    if (fetchError && !(options && options.length)) {
      placeholderMessage = messages.errorNoOptions
    }

    let multiSectionProps = {}
    if (multiSection) {
      multiSectionProps = {
        multiSection,
        renderSectionTitle: section => (
          <strong>{section.title}</strong>
        ),
        getSectionSuggestions: section => section.values || [],
      }
    }

    return (
      <div>
        <Autosuggest
          id={`${input.name}-options`}
          ref={(el) => { this.autosuggestRef = el }}
          suggestions={suggestions}
          getSuggestionValue={option => option.title}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderInputComponent={this.renderInputComponent}
          renderSuggestion={Autocomplete.renderSuggestion}
          {...multiSectionProps}
          inputProps={{
            id: input.name,
            className: classNames('form-control autocomplete', className),
            onChange: this.onChange,
            onBlur: this.onBlur,
            value,
            title,
            disabled,
            'aria-required': mandatory,
            'aria-describedby': `${input.name}-error`,
            placeholder: isObject(placeholderMessage) ? formatMessage(placeholderMessage) : placeholderMessage,
            'id-qa-test': `select-${input.name}`,
            onKeyDown: (event) => {
              switch (event.key) {
              case 'Enter': {
                event.preventDefault()
                this.onBlur()
                break
              }
              default:
                break
              }
            },
          }}
        />
        {!this.state.value &&
          <span
            className={styles.inputActionIcon}
            style={{ pointerEvents: 'none', cursor: 'pointer' }}
          >
            <Icon name="chevron-tight-down" inline />
          </span>
        }
        {this.state.value &&
          <InteractiveElement
            className={styles.inputActionIcon}
            onClick={this.onClearSelection}
          >
            <Icon name="close" inline />
            <span className="sr-only"><FormattedMessage {...inputMessages.clearInputValue} /></span>
          </InteractiveElement>
        }
      </div>
    )
  }
}

export default injectIntl(Autocomplete)

export function AutocompleteFieldGroup(props) {
  return (
    <CommonFormGroup {...props}>
      <Autocomplete {...props} />
    </CommonFormGroup>
  )
}
