import React from 'react'
import { FormattedMessage } from 'react-intl'
import { find, get } from 'lodash'
import { Row, Col } from 'react-bootstrap'
import Icon from 'src/components/Icon'
import Loader from 'src/components/Loader'
import messages from '../messages'
import styles from './customerInformation.scss'
import { AUTH_TYPES } from '../../../constants'
import ApplicantAddressForm from './ApplicantAddressForm'

export function getApplicantName(selectedAuthorizationObject) {
  if (!selectedAuthorizationObject) {
    return null
  }

  const name = selectedAuthorizationObject?.name
  const identifier = selectedAuthorizationObject?.identifier
  const type = selectedAuthorizationObject.type

  if (!name && identifier) {
    return identifier
  }

  if (name && identifier && type === AUTH_TYPES.business) {
    return `${name} (${identifier})`
  }

  return name
}

export function getApplicantContactInfo(values) {
  const address = get(values, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderAddress')
  const zipCode = get(values, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderPostalCode')
  const city = get(values, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderCity')
  const countryCode = get(values, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderCountryCode')

  return { address, zipCode, city, countryCode }
}

/**
 * Component needs to be React.Component instead of pure function when rendering it via dynamically assigned name
 */
// eslint-disable-next-line react/prefer-stateless-function
export default class CustomerInformation extends React.Component {
  render() {
    const {
      infoElement,
      isPerson,
      applicant,
      fetchingCustomer,
      customerInformation,
      selectedAuthorizationObject,
      values,
      staticValue,
    } = this.props

    const businessAuthorizationId = get(selectedAuthorizationObject, 'id')
    const customer = get(customerInformation, businessAuthorizationId) || {}
    const contactInfo = isPerson ? getApplicantContactInfo(values) : find(get(customer, 'addresses'), { addressType: 'POST_ADDRESS' })
    const applicantName = getApplicantName(selectedAuthorizationObject)
    const hasPersonalDataAccessRestriction = get(applicant, 'hasPersonalDataAccessRestriction')
    const isPrincipal = get(selectedAuthorizationObject, 'type') === AUTH_TYPES.principal

    return (
      <div>
        {fetchingCustomer &&
          <Loader
            blocking
            message={{ ...messages.loadingCustomerInformation }}
          />
        }
        <Row className={styles.verticalCol}>
          <Col xs={12}>
            <label>
              <FormattedMessage {...messages.applicant} />
            </label>
          </Col>
          <Col xs={12}>
            <span id-qa-test={'label-applicant-name'}>
              {applicantName}
            </span>
          </Col>
        </Row>

        <Row className={styles.verticalCol}>
          <Col xs={12}>
            <label>
              <FormattedMessage {...messages.customerInformationTitle} />
            </label>
          </Col>
          <Col sm={6}>
            <FormattedMessage {...messages.identifier} />
          </Col>
          <Col sm={6} id-qa-test={'label-EORI'}>
            {applicant.authorizationOrDecisionHolderIdentification && applicant.authorizationOrDecisionHolderIdentification}
            {!isPerson && !customer.hasEori &&
              <span>
                <span style={{ color: 'red' }}><FormattedMessage {...messages.missing} /></span>
                <a
                  href="http://tulli.fi/sahkoiset-palvelut/palvelut/haku-ja-kyselypalvelut/eori"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '10px' }}
                >
                  <FormattedMessage {...messages.applyForEori} />
                  <Icon name="external" />
                </a>
              </span>
            }
          </Col>
          {!isPerson && <Col sm={6}>
            <FormattedMessage {...messages.AEOAuthorisationTitle} />
          </Col>}

          {!isPerson && <Col sm={6} id-qa-test={'label-AEOAuthorisation'}>
            {customer.AEOCertificateType || <FormattedMessage {...messages.AEONoCertificate} />}
          </Col>}

        </Row>

        {!isPrincipal && !hasPersonalDataAccessRestriction &&
          <Row className={styles.verticalCol}>
            <Col sm={6}>
              <FormattedMessage {...messages.contactInformation} />
            </Col>
            <Col sm={6} id-qa-test={'label-contactInformation'}>
              <div>{get(contactInfo, 'address')}</div>
              <div>{get(contactInfo, 'zipCode')} {get(contactInfo, 'city')} {get(contactInfo, 'countryCode')}</div>
            </Col>
          </Row>
        }
        {isPrincipal && <ApplicantAddressForm infoElement={infoElement} values={values} staticValue={staticValue} />}
      </div>
    )
  }
}
