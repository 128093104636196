import React from 'react'
import { renderToString } from 'react-dom/server'
import { get, isEmpty, find, startsWith } from 'lodash'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { SERVICE_PATHS } from 'src/constants'
import DisruptionNotice from 'src/routes/frontpage/components/DisruptionNotice'
import Heading from 'src/layout/Heading'
import { Jumbotron, Button, Grid, Row, Col } from 'react-bootstrap'
import Icon from 'src/components/Icon'
import classnames from 'classnames'
import ReactMarkdown from 'react-markdown'

import { formatCmsMessage, formatOptionalCmsMessage } from 'src/utils'
import { appTemplate } from '../templates/appTemplate'
import styles from './LoginHelp.scss'
import Accordion from './Accordion'

function IconRenderer({ src, alt: type }) {
  if (type === 'ICON') {
    const iconName = src
    return <Icon name={iconName} xlg />
  }
  return null
}

class LoginHelp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: props.accordionIsOpen === 'true',
    }
  }

  getLoginInfoSection = (section) => {
    const { intl: { formatMessage } } = this.props
    const content = formatCmsMessage(formatMessage, `/onboarding/authenticationHelp/${section}_content`)
    return isEmpty(content) ? null : content
  }

  toggleAccordion = () => {
    const newOpenState = !this.state.open
    localStorage.setItem('accordionIsOpen', newOpenState)
    this.setState({ open: newOpenState })
    const accordionButton = document.getElementById('loginHelpContentButton')
    if (accordionButton) {
      accordionButton.focus()
    }
  }

  openLoginInfo = () => {
    const { intl: { formatMessage } } = this.props
    const sections = []

    sections.push(this.getLoginInfoSection('section1'))
    sections.push(this.getLoginInfoSection('section2'))
    sections.push(this.getLoginInfoSection('section3'))
    sections.push(this.getLoginInfoSection('section4'))
    sections.push(this.getLoginInfoSection('section5'))
    sections.push(this.getLoginInfoSection('section6'))
    sections.push(this.getLoginInfoSection('section7'))
    sections.push(this.getLoginInfoSection('section8'))
    sections.push(this.getLoginInfoSection('section9'))
    sections.push(this.getLoginInfoSection('section10'))

    const templateProps = {
      pageTitle: formatMessage({ id: '/onboarding/authenticationHelp/pageTitle' }),
      title: formatMessage({ id: '/onboarding/authenticationHelp/title' }),
      children: renderToString(
        <div>
          {sections && sections.map((section, index) => (
            section !== null && <ReactMarkdown key={index} source={section} />
          ))}
        </div>
      ),
    }

    const loginInstructionsWindow = window.open()
    loginInstructionsWindow.opener = null
    loginInstructionsWindow.document.write(appTemplate(templateProps))
  }

  render() {
    const {
      titleMessage,
      locale,
      onClickButton,
      path,
      intl,
      intl: { formatMessage },
    } = this.props

    const servicePath = find(SERVICE_PATHS, servicePathInArray => startsWith(path, servicePathInArray))

    const onboardingTitle = formatOptionalCmsMessage(
      intl,
      `${servicePath}/onboarding/header`,
      '/onboarding/title'
    )
    const onboardingCloseButtonText = formatMessage({ id: '/onboarding/closeButtonText' })
    const onboardingAdditionalInfoText = formatMessage({ id: '/onboarding/additionalInfoText' })
    const dynamicOnboardingTitle = formatCmsMessage(formatMessage, `${servicePath}/onboarding/title`)
    const dynamicOnboardingSection1Icon = formatCmsMessage(formatMessage, `${servicePath}/onboarding/section1_icon`)
    const dynamicOnboardingSection1Content =
      formatCmsMessage(formatMessage, `${servicePath}/onboarding/section1_content`)
    const dynamicOnboardingSection2Icon = formatOptionalCmsMessage(intl, `${servicePath}/onboarding/section2_icon`)
    const dynamicOnboardingSection2Content =
      formatOptionalCmsMessage(intl, `${servicePath}/onboarding/section2_content`)
    const dynamicOnboardingSection3Icon = formatOptionalCmsMessage(intl, `${servicePath}/onboarding/section3_icon`)
    const dynamicOnboardingSection3Content =
      formatOptionalCmsMessage(intl, `${servicePath}/onboarding/section3_content`)
    const dynamicOnboardingSection4Icon = formatOptionalCmsMessage(intl, `${servicePath}/onboarding/section4_icon`)
    const dynamicOnboardingSection4Content =
      formatOptionalCmsMessage(intl, `${servicePath}/onboarding/section4_content`)
    const dynamicOnboardingSection5Icon = formatOptionalCmsMessage(intl, `${servicePath}/onboarding/section5_icon`)
    const dynamicOnboardingSection5Content =
      formatOptionalCmsMessage(intl, `${servicePath}/onboarding/section5_content`)

    const loginButtonText = formatMessage({ id: '/authentication/loginButtonText' })
    const loginInfoText = formatOptionalCmsMessage(
      intl,
      `${servicePath}/onboarding/loginInfoText`,
      '/authentication/loginInfoText'
    )
    const loginAdditionalInfoText = formatOptionalCmsMessage(
      intl,
      `${servicePath}/onboarding/loginAdditionalInfoText`
    )

    const infoText = formatMessage({ id: '/onboarding/authenticationHelp/infoText' })
    const infoButtonText = formatMessage({ id: '/onboarding/authenticationHelp/buttonText' })
    const opensInNewWindow = formatMessage({ id: 'link.opensInNewWindow' })

    return (
      <div>
        {titleMessage && <Heading message={titleMessage} compact />}
        <main className="container" role="main" id="main">
          {this.props.disruptionNoticeUrls &&
            <DisruptionNotice
              locale={locale}
              className="container"
              disruptionNoticeUrls={this.props.disruptionNoticeUrls}
              service={servicePath}
            />
          }
          <Jumbotron
            className={classnames(styles.jumbotron,
              this.state.open ? styles.accordionOpen : styles.accordionClosed)}
          >
            <div className={styles.top}>
              <Accordion
                title={onboardingTitle}
                open={this.state.open}
                clickHandler={this.toggleAccordion}
                className={styles.accordionRoot}
                accordionId="loginHelpContent"
              >
                <Grid className={styles.contentArea}>
                  <Row className={styles.contentRow}>
                    <Col sm={12} md={7} lg={8} className={styles.onboardingColumn}>
                      {dynamicOnboardingTitle &&
                        <div>
                          <h3>
                            {dynamicOnboardingTitle}
                          </h3>
                          {dynamicOnboardingSection1Content &&
                            <div className={styles.section}>
                              <ReactMarkdown
                                source={dynamicOnboardingSection1Icon}
                                renderers={{ Image: IconRenderer }}
                                className={styles.sectionIcon}
                              />
                              <ReactMarkdown
                                source={dynamicOnboardingSection1Content}
                                className={styles.sectionContent}
                              />
                            </div>
                          }

                          {dynamicOnboardingSection2Content &&
                            <div className={styles.section}>
                              <ReactMarkdown
                                source={dynamicOnboardingSection2Icon}
                                renderers={{ Image: IconRenderer }}
                                className={styles.sectionIcon}
                              />
                              <ReactMarkdown
                                source={dynamicOnboardingSection2Content}
                                className={styles.sectionContent}
                              />
                            </div>
                          }

                          {dynamicOnboardingSection3Content &&
                            <div className={styles.section}>
                              <ReactMarkdown
                                source={dynamicOnboardingSection3Icon}
                                renderers={{ Image: IconRenderer }}
                                className={styles.sectionIcon}
                              />
                              <ReactMarkdown
                                source={dynamicOnboardingSection3Content}
                                className={styles.sectionContent}
                              />
                            </div>
                          }

                          {dynamicOnboardingSection4Content &&
                            <div className={styles.section}>
                              <ReactMarkdown
                                source={dynamicOnboardingSection4Icon}
                                renderers={{ Image: IconRenderer }}
                                className={styles.sectionIcon}
                              />
                              <ReactMarkdown
                                source={dynamicOnboardingSection4Content}
                                className={styles.sectionContent}
                              />
                            </div>
                          }

                          {dynamicOnboardingSection5Content &&
                            <div className={styles.section}>
                              <ReactMarkdown
                                source={dynamicOnboardingSection5Icon}
                                renderers={{ Image: IconRenderer }}
                                className={styles.sectionIcon}
                              />
                              <ReactMarkdown
                                source={dynamicOnboardingSection5Content}
                                className={styles.sectionContent}
                              />
                            </div>
                          }
                        </div>
                      }
                    </Col>

                    <Col sm={12} md={5} lg={4} className={styles.loginInfoColumn}>
                      <Row className={styles.loginInfoBox}>
                        <Col sm={2} xsHidden className={styles.loginInfoBoxIcon}>
                          <Icon name={'book-bookmark'} xlg />
                        </Col>
                        <Col xs={12} sm={10} className={styles.loginInfoBoxContent}>
                          <div>
                            {infoText &&
                              <ReactMarkdown
                                source={infoText}
                              />
                            }
                            {infoButtonText &&
                              <Button className={styles.openLoginInfoButton} onClick={this.openLoginInfo}>
                                <span>{infoButtonText}</span>
                                <span id="openLoginInfoDesc" className="sr-only">{opensInNewWindow}</span>
                                <Icon name={'external'} className={styles.openLoginInfoButtonIcon} />
                              </Button>
                            }
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {onboardingCloseButtonText && onboardingAdditionalInfoText &&
                    <div className={styles.closeHelpRow}>
                      <div>
                        <Button onClick={this.toggleAccordion} aria-describedby="close-button-help">
                          <span>{onboardingCloseButtonText}</span>
                          <Icon name={'close'} className={styles.closeButtonIcon} />
                        </Button>
                      </div>
                      <div id="close-button-help" className={styles.additionalInfoText}>
                        <span>
                          {onboardingAdditionalInfoText}
                        </span>
                      </div>
                    </div>
                  }
                </Grid>
              </Accordion>
            </div>
            <div className={styles.bottom}>
              <div
                className={classnames([styles.bottomLeft, !this.state.open ? styles.alignCenter : ''])}
              >
                <div className={styles.bottomIconContainer}>
                  <Icon name={'info'} {...(this.state.open) ? { xlg: true } : { huge: true }} />
                </div>
                <div>
                  <p>{loginInfoText}</p>
                  {loginAdditionalInfoText &&
                    <ReactMarkdown
                      source={loginAdditionalInfoText}
                      className={styles.bottomAdditionalInfo}
                    />
                  }
                </div>
              </div>
              <div className={styles.bottomRight}>
                <Button
                  id="jumbotronButton"
                  bsStyle="primary"
                  onClick={onClickButton}
                  ref={(ref) => { this.buttonRef = ref }}
                  aria-label={loginButtonText}
                  className={styles.loginButton}
                >
                  <span className={styles.loginButtonText}>{loginButtonText}</span>
                  <Icon name={'arrow-right'} className={styles.loginButtonIcon} lg />
                </Button>
              </div>
            </div>
          </Jumbotron>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const accordIsOpen = localStorage.getItem('accordionIsOpen')
  return {
    locale: state.locale,
    disruptionNoticeUrls: get(state, 'config.bootstrapConfig.nakki_disruption_notices'),
    path: state.router.locationBeforeTransitions.pathname,
    accordionIsOpen: accordIsOpen,
  }
}

export default connect(mapStateToProps)(injectIntl(LoginHelp))
