import { UTU_LANDING_PATH } from './constants'
import UtuContainer from './container'

export default {
  path: `${UTU_LANDING_PATH}/:serviceId`,
  getComponents(nextState, next) {
    import(/* webpackChunkName: 'ext-auth' */ './container')
      .then((containerModule) => {
        next(null, containerModule.default)
      })
  },
  childRoutes: [
    { path: ':utuPath*', component: UtuContainer },
  ],
}
