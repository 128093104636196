import React, { useContext, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { find, get } from 'lodash'
import { injectIntl } from 'react-intl'
import InfoElementHeaderArea from 'src/components/form_v2/InfoElementHeaderArea'
import PermitPageContext from './PermitPage/context'
import { APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME } from '../constants'
import CommonFormGroup from '../../../../components/form_v2/CommonFormGroup'
import messages from '../messages'

function ApplicantCustomsResponsible(props) {
  const {
    structure,
    infoElement,
    locale,
    formApi,
    renderField,
  } = props

  const { customerHasAEO } = useContext(PermitPageContext)

  const applicantCustomsResponsibleRadio = get(formApi.values, APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME)
  const applicationContactPersonName = get(formApi.values, 'applicationContactPerson.applicationContactPersonName', '')
  const applicationContactPersonPhone = get(formApi.values, 'applicationContactPerson.applicationContactPersonPhone', '')
  const applicationContactPersonEmail = get(formApi.values, 'applicationContactPerson.applicationContactPersonEmail', '')


  useEffect(() => {
    formApi.setError('applicantCustomsResponsible.applicantCustomsResponsibleDateEori', null)
    formApi.setError('applicantCustomsResponsible.applicantCustomsResponsibleDateOfBirth', null)
    formApi.setError('applicantCustomsResponsible.applicantCustomsResponsibleNationalIdentificationNumber', null)

    if (applicantCustomsResponsibleRadio === 'sameAsUser') {
      if (applicationContactPersonName) {
        formApi.setValue('applicantCustomsResponsible.applicantCustomsResponsibleName', applicationContactPersonName)
      }

      if (applicationContactPersonPhone) {
        formApi.setValue('applicantCustomsResponsible.applicantCustomsResponsiblePhone', applicationContactPersonPhone)
      }

      if (applicationContactPersonEmail) {
        formApi.setValue('applicantCustomsResponsible.applicantCustomResponsibleEmail', applicationContactPersonEmail)
      }
    }
  }, [
    applicantCustomsResponsibleRadio,
    applicationContactPersonName,
    applicationContactPersonPhone,
    applicationContactPersonEmail,
  ])

  const applicationContactPersonInfoElement = find(structure.infoElements, { code: 'applicationContactPerson' })

  const options = [
    {
      value: 'sameAsUser',
      title: messages.sameAsApplicationContactPersonText,
    },
    {
      value: 'otherPerson',
      title: messages.otherPerson,
    },
    {
      value: 'otherCompany',
      title: messages.otherCompany,
    },
  ]

  if (customerHasAEO) {
    options.push({
      value: 'noInput',
      title: messages.noInput,
    })
  }

  const radioInput = {
    type: 'radioListWithField',
    multilineLabel: true,
    name: APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME,
    static: false,
    multiple: false,
    validate: true,
    visible: true,
    validation: {
      mandatory: infoElement.mandatory,
    },
    options,
  }

  return (
    <Col sm={6} xs={12}>
      <Row>
        <Col xs={12}>
          <InfoElementHeaderArea
            infoElement={infoElement}
            locale={locale}
            small
          />
          {applicationContactPersonInfoElement && <CommonFormGroup
            errors={formApi.errors}
            label={infoElement.name[locale]}
            id={APPLICANT_CUSTOMS_RESPONSIBLE_RADIO_NAME}
            formApi={formApi}
            ariaDescribedBy={`${infoElement.code}`}
            input={radioInput}
          />}
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {infoElement.fields.map(field => renderField({
            field: {
              ...field,
            },
          }))}
        </Col>
      </Row>
    </Col>
  )
}

export default injectIntl(ApplicantCustomsResponsible)
