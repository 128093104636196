import { applyMiddleware, compose, createStore as reduxCreateStore } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import { reducers } from './reducers/root'

let store

export function createStore(initialState = {}, history) {
  let middleware = applyMiddleware(thunk, routerMiddleware(history))

  // DevTools chrome extension in development
  if (DEV) {
    // eslint-disable-next-line no-underscore-dangle
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
    if (typeof devToolsExtension === 'function') {
      middleware = compose(middleware, devToolsExtension())
    }
  }

  // Create store with empty structure for asynchronously injected reducers
  store = reduxCreateStore(reducers(), initialState, middleware)
  store.asyncReducers = {}

  // Webpack hot module replacement (live reload)
  if (module.hot) {
    module.hot.accept('./reducers/root', () => {
      // eslint-disable-next-line global-require
      const newReducers = require('./reducers/root').default

      store.replaceReducer(newReducers)
    })
  }

  return store
}

export function getStore() {
  return store
}
