import React from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { injectIntl } from 'react-intl'
import { some, isArray, isEmpty, isObject, get } from 'lodash'
import ErrorMessage from 'src/components/form_v2/ErrorMessage'
import messages from 'src/components/messages'
import { getCellValueFormatter } from '../../../formatValue'

const renderRow = (item, itemIndex, fields, errors, locale, formatCellValue) =>
  <tr key={itemIndex}>
    {fields.map((field) => {
      const fieldError = get(errors, `${field.code}-${itemIndex}`, '')
      return (
        <td key={field.code}>
          {formatCellValue(item, field)}
          {fieldError &&
            <div className={'has-error'}>
              <p className="help-block">
                <ErrorMessage error={fieldError} fieldName={field.name[locale]} />
              </p>
            </div>
          }
        </td>
      )
    })}
  </tr>

function ItemsTableReadOnly(props) {
  const {
    locale,
    fields,
    infoElement,
    codesets,
    values,
    intl: { formatMessage },
  } = props

  const isMandatory = infoElement.mandatory && some(fields, { mandatory: true })
  const isSingleInfoElement = infoElement.repeat === 1
  const formatCellValue = getCellValueFormatter({ codesets, locale })

  let hasValues = isArray(values) && !isEmpty(values)
  if (isSingleInfoElement) {
    hasValues = isObject(values) && !isEmpty(values)
  }

  const errors = get(props.formApi.getFormState(), `errors.${infoElement.code}`)

  return (
    <div>
      <Row>
        <Col xs={12}>
          {!hasValues && !isMandatory &&
            <div>--</div>
          }
          {!hasValues && isMandatory &&
            <div className="has-error">
              <div className="help-block">
                --
              </div>
            </div>
          }
          {hasValues &&
            <div tabIndex="0" role="region" aria-label={formatMessage(messages.table)} className="table-responsive">
              <Table striped bordered>
                <thead>
                  <tr>
                    {fields.map(field => <th><b>{field.name[locale]}</b></th>)}
                  </tr>
                </thead>
                <tbody>
                  {hasValues && !isSingleInfoElement
                    && values.map((item, rowIndex) =>
                      renderRow(item, rowIndex, fields, errors, locale, formatCellValue)
                    )}
                  {hasValues && isSingleInfoElement &&
                    renderRow(values, 0, fields, errors, locale, formatCellValue)
                  }
                </tbody>
              </Table>
            </div>
          }
        </Col>
      </Row>
    </div>
  )
}

export default injectIntl(ItemsTableReadOnly)
