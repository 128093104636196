import React, { useState, useContext } from 'react'
import { withRouter } from 'react-router'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import PermitPageContext from './context'
import { PERMITS_ROUTE_PATH } from '../../../constants'
import messages from '../../messages'

function ExitPermitButton({ title, router, className, onClick }) {
  return (
    <Button
      className={className}
      onClick={onClick || (() => exitPermit(router))}
      id-qa-test="button-exit"
    >
      <Icon name="close" /><FormattedMessage {...title} />
    </Button>
  )
}

export function exitPermit(router) {
  router.push(`/${PERMITS_ROUTE_PATH}`)
}

export function ExitPrintPermitButton() {
  return (
    <div className="btn-toolbar pull-right">
      <Button onClick={() => window.close()} id-qa-test="btn-close">
        <Icon name="close" /><FormattedMessage {...messages.close} />
      </Button>
    </div>
  )
}

function ExitWithoutSavingButtonInner({ router, className }) {
  const [showModal, setShowModal] = useState(false)
  const { formModified } = useContext(PermitPageContext)

  return <React.Fragment>
    <Modal
      show={showModal}
      showCancel
      cancelMessage={messages.closeWithoutSavingModalCancel}
      showContinue
      titleMessage={messages.closeWithoutSavingModalTitle}
      continueMessage={messages.closeWithoutSavingModalProceed}
      onClickContinue={() => exitPermit(router)}
      onClickCancel={() => setShowModal(false)}
      focusDisableButton
    >
      <p id="dialog-message"><FormattedMessage {...messages.closeWithoutSavingModalMessage} /></p>
    </Modal>
    <ExitPermitButton
      title={messages.closeWithoutSaving}
      className={className}
      onClick={() => {
        if (formModified) {
          setShowModal(true)
        } else {
          exitPermit(router)
        }
      }}
    />
  </React.Fragment>
}
export const ExitWithoutSavingButton = withRouter(
  ExitWithoutSavingButtonInner
)

export default withRouter(ExitPermitButton)
