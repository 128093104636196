// We only need to import the modules necessary for initial render
import CoreLayout from 'src/layout/CoreLayout'
import PageNotFound from 'src/components/PageNotFound'
import frontpageRoute from './frontpage/'
import logoutRoute from './logout'
import feedbackRoute from './feedback'
import intrastatRoute from './intrastat'
import iatRoute from './iat'
import permitsRoute from './permits'
import formsRoute from './forms'
import mrnRoute from './mrn'
import utuRoute from './ext-auth'
import messagesRoute from './messages'
import customerInformationRoute from './customer-information'
import individualGuarantee from './individual-guarantee'

export default function createRoutes(store, features) {
  /*
    Note: Instead of using JSX, we are using react-router PlainRoute,
    a simple javascript object to provide route definitions.
    When creating a new async route, pass the instantiated store!
  */

  const routes = {
    path: '/',
    component: CoreLayout,
    indexRoute: frontpageRoute,
    childRoutes: [
      logoutRoute,
      feedbackRoute(store),
      iatRoute(store),
      ...(features.PERMITS ? [permitsRoute(store)] : []),
      ...(features.FORMS ? [formsRoute(store, features)] : []),
      ...(features.MRN ? [mrnRoute(store)] : []),
      intrastatRoute(store),
      utuRoute,
      ...(features.CUSTOMER_INFORMATION ? [customerInformationRoute(store)] : []),
      ...(features.MESSAGES ? [messagesRoute(store)] : []),
      ...(features.INDIVIDUAL_GUARANTEE ? [individualGuarantee(store)] : []),
      {
        path: '*',
        component: PageNotFound,
      },
    ],
  }

  return routes
}
