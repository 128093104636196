import cn from 'classnames/bind'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isObject } from 'lodash'
import styles from './radio.scss'

const boundCn = cn.bind(styles)

/* eslint-disable max-len */
const svgPaths = {
  default: 'M10 19.1C4.97 19.1 0.9 15.03 0.9 10 0.9 4.97 4.97 0.9 10 0.9 15.03 0.9 19.1 4.97 19.1 10 19.1 15.03 15.03 19.1 10 19.1ZM10 17.1C13.92 17.1 17.1 13.92 17.1 10 17.1 6.08 13.92 2.9 10 2.9 6.08 2.9 2.9 6.08 2.9 10 2.9 13.92 6.08 17.1 10 17.1Z',
  selected: 'M14.6 9.9A4.5 4.5 0 0 1 10.1 14.4 4.5 4.5 0 0 1 5.6 9.9 4.5 4.5 0 0 1 10.1 5.4 4.5 4.5 0 0 1 14.6 9.9ZM10.1 19C5.07 19 1 14.93 1 9.9 1 4.87 5.07 0.8 10.1 0.8c5.03 0 9.1 4.07 9.1 9.1 0 5.03-4.07 9.1-9.1 9.1zm0-2c3.92 0 7.1-3.18 7.1-7.1C17.2 5.98 14.02 2.8 10.1 2.8 6.18 2.8 3 5.98 3 9.9 3 13.82 6.18 17 10.1 17Z',
  focus: 'M10 20C4.48 20 0 15.52 0 10 0 4.48 4.48 0 10 0c5.52 0 10 4.48 10 10 0 5.52-4.48 10-10 10zm0.1-2.8c3.92 0 7.1-3.18 7.1-7.1C17.2 6.18 14.02 3 10.1 3 6.18 3 3 6.18 3 10.1c0 3.92 3.18 7.1 7.1 7.1z',
  focusSelected: 'm14.6 10.1a4.5 4.5 0 0 1-4.5 4.5 4.5 4.5 0 0 1-4.5-4.5 4.5 4.5 0 0 1 4.5-4.5 4.5 4.5 0 0 1 4.5 4.5zM10 20C4.48 20 0 15.52 0 10 0 4.48 4.48 0 10 0c5.52 0 10 4.48 10 10 0 5.52-4.48 10-10 10zm0.1-2.8c3.92 0 7.1-3.18 7.1-7.1C17.2 6.18 14.02 3 10.1 3 6.18 3 3 6.18 3 10.1c0 3.92 3.18 7.1 7.1 7.1z',
}
/* eslint-enable max-len */

class Radio extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasFocus: false }
    this.setFocused = this.setFocused.bind(this)
  }

  setFocused(hasFocus) {
    this.setState({ hasFocus })
  }

  render() {
    const {
      inline = false,
      disabled = false,
      option,
      validationState,
      inputRef,
      input,
      className,
      style,
      checked,
      labelId,
      externalBlurControl,
    } = this.props

    const {
      hasFocus,
    } = this.state

    const hiddenElementAriaProps = {
      'aria-hidden': true,
      role: 'presentation',
    }

    const classes = {
      disabled,
      radio: !inline,
      'radio-inline': inline,
      customRadio: true,
      checked,
      focused: this.state.hasFocus,
    }
    if (validationState) {
      classes[`has-${validationState}`] = true
    }

    const radioClasses = {
      [styles.customRadioButton]: true,
      [styles.customRadioButtonSelected]: checked,
      [styles.customRadioFocus]: hasFocus,
    }

    const radioDefault = (
      <svg
        {...hiddenElementAriaProps}
        viewBox="0 0 20 20"
        className={boundCn(radioClasses)}
      >
        <path d={svgPaths[hasFocus ? 'focus' : 'default']} />
      </svg>
    )
    const radioSelected = (
      <svg
        {...hiddenElementAriaProps}
        viewBox="0 0 20 20"
        className={boundCn(radioClasses)}
      >
        <path d={svgPaths[hasFocus ? 'focusSelected' : 'selected']} />
      </svg>
    )

    return (
      <div className={boundCn(className, classes)} style={style}>
        <label style={{ marginRight: '15px' }}>
          <input
            ref={inputRef}
            type="radio"
            disabled={disabled}
            value={option.value}
            name={input.name}
            id-qa-test={`input-${input.name}-${option.value}`}
            onChange={event => input.onChange(event.target.value)}
            onFocus={() => this.setFocused(option.value)}
            onBlur={() => {
              this.setFocused(null)

              if (!externalBlurControl) {
                input.onBlur()
              }
            }}
            aria-hidden="false"
            aria-labelledby={labelId}
            aria-describedby={`${input.name}-error`}
            checked={checked}
          />
          {checked ? radioSelected : radioDefault}
          {isObject(option.title) ? <FormattedMessage {...option.title} /> : option.title}
        </label>
      </div>
    )
  }
}

export default Radio
