import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { some } from 'lodash'
import { FormattedMessage } from 'react-intl'
import messages from './messages'

export default function (props) {
  const {
    infoElement,
    values,
    locale,
    renderErrors,
  } = props

  const isMandatory = infoElement.mandatory && some(infoElement.fields, { mandatory: true })

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h4>{infoElement.name[locale]}</h4>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {values.personInCharge.length === 0 && isMandatory &&
            <div className="has-error">
              <div className="help-block">
                <FormattedMessage {...messages.missingRequiredRows} />
              </div>
            </div>
          }
          {values.personInCharge.length > 0 && values.personInCharge.map((pic, i) =>
            <div>
              <span id-qa-test={`label-person-in-charge-name-${i}`}>
                {pic.personInChargeName}
              </span>
            </div>)
          }
          {renderErrors()}
        </Col>
      </Row>
    </div>
  )
}
