import { has, get } from 'lodash'
import { getFieldErrors, isInfoElementMandatory } from '../../permitManager'
import { getCompanyNameFieldCode, COMPANY_NAME_NOT_FOUND } from '../../components/BusinessIdInput'

// eslint-disable-next-line import/prefer-default-export
export const checkFormValues = (formApi, infoElement) => {
  const formData = formApi && formApi.getFormState().values
  let isValid = true

  for (const field of infoElement.fields) {
    const errors = getFieldErrors(field, formData)

    // Make sure the user does not try to add an empty GRN row.
    // An empty GRN row is not allowed even if the information is not mandatory.
    if (field.code === 'guaranteeGRN') {
      if (!formData[field.code] || !/^(?!\s*$).+/.test(formData[field.code])) {
        errors.push({ id: 'permits.checkGRNNumber' })
      }
    }

    // company name must be fetched when new item is added to repetitive info element
    if (field.code === 'messageDeclarantProviderIdentification') {
      const companyName = get(formData, getCompanyNameFieldCode(field))
      if (!companyName || companyName === COMPANY_NAME_NOT_FOUND) {
        errors.push({ id: 'permits.companyNotFound' })
      }
    }

    if (errors.length) {
      formApi.setError(field.code, errors[0])
      isValid = false
    } else {
      formApi.setError(field.code, null)
    }
  }

  const firstFieldWithValue = infoElement.fields
    .find(field => has(formData, field.code) && formData[field.code].trim() !== '')
  // check if the form is empty
  if (isInfoElementMandatory(infoElement, formData, {}) && !firstFieldWithValue) {
    isValid = false
  }

  return isValid
}
