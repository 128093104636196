import React from 'react'
import { connect } from 'react-redux'
import { get, isNil, size } from 'lodash'
import { AUTH_PLURALITY_TYPES, AUTH_TYPES } from 'src/constants'
import withSessionTimer from 'src/components/SessionTimer'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import Modal from 'src/components/Modal'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import apiMessages from 'src/utils/apiMessages'
import RoleSelector from '../components/RoleSelector'
import SuomiFiAuthorizationTypeSelector from '../components/SuomiFiAuthorizationTypeSelection'
import TermsOfService from './TermsOfService'
import { containsAnyRole, formatAuthorizationTypes, CUSTOMS_CLEARANCE_ROLE, getCorrectAuthPath, PERMIT_ROLES, getAuthPlurality } from '../utils/auth'
import styles from './loader.scss'
import { PERMITS_ROUTE_PATH } from '../routes/permits/constants'

const messages = defineMessages({
  error: {
    id: 'invalidAuth.message',
    description: 'Message shown in error modal when the user has no valid authorizations',
    defaultMessage: 'You do not have valid authorizations',
  },
  logout: {
    id: 'invalidAuth.logout',
    description: 'Logout button text for invalid authorizations modal',
    defaultMessage: 'Logout',
  },
  // TODO: Check if this is still needed.
  interruptOnBehalf: {
    id: 'interruptOnBehalf.message',
    description: ' Message shown when selection a person for on-behalf authorization is interrupted.',
    defaultMessage: 'You have interrupted on-behalf authorization.',
  },
  interruptOnBehalfOfMinor: {
    id: 'interruptOnBehalfOfMinor.message',
    description: ' Message shown when trying to transact on behalf of a minor.',
    defaultMessage: 'If you wish to transact on behalf of an underage child in the Import Declaration Service for private persons, select first the option of transacting on your own behalf..',
  },
})

function renderRoleSelector(props) {
  const {
    validAuthorizations,
    onLogout,
    onSelectAuthorization,
    onSwitchLocale,
    locale,
    returnUriAfterRoleSwitch,
    history,
  } = props
  return (
    <RoleSelector
      authorizations={validAuthorizations}
      onRejected={() => onLogout()}
      onSelect={onSelectAuthorization}
      onSwitchLocale={onSwitchLocale}
      locale={locale}
      returnUriAfterRoleSwitch={returnUriAfterRoleSwitch}
      history={history}
    />
  )
}

class AuthorizationWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.isAuthorizationAllowed = this.isAuthorizationAllowed.bind(this)
    this.isSuomiFiSingleAuthorizationType = this.isSuomiFiSingleAuthorizationType.bind(this)
    const { authorizationTypes } = props

    this.mapPathsToAuthTypes = formatAuthorizationTypes(authorizationTypes)

    this.state = {
      isLoading: this.isSuomiFiSingleAuthorizationType(),
    }
  }

  componentDidMount() {
    if (this.isSuomiFiSingleAuthorizationType()) {
      this.props.onSelectAuthorizationType(this.props.suomiFiAuthorization.types[0])
    }
  }

  isSuomiFiSingleAuthorizationType() {
    return this.props.suomiFiAuthorization && this.props.suomiFiAuthorization.types.length === 1
  }

  isAuthorizationAllowed(authorization, selectedAuthorizationType) {
    const { returnUriAfterRoleSwitch, pathname } = this.props
    if (selectedAuthorizationType !== authorization.type) {
      return false
    }
    if (selectedAuthorizationType === AUTH_TYPES.principal && !containsAnyRole(authorization.roles, CUSTOMS_CLEARANCE_ROLE)) {
      if (containsAnyRole(authorization.roles, PERMIT_ROLES) && pathname === PERMITS_ROUTE_PATH) {
        return true
      }

      if (containsAnyRole(authorization.roles, CUSTOMS_CLEARANCE_ROLE)) {
        return true
      }

      return false
    }

    const correctPath = getCorrectAuthPath(this.mapPathsToAuthTypes, returnUriAfterRoleSwitch, pathname)

    if (!correctPath) {
      return true
    }

    const validAuthTypes = this.mapPathsToAuthTypes[correctPath]
    return validAuthTypes.includes(authorization.type)
  }

  render() {
    const {
      auth,
      children,
      onSelectAuthorization,
      onSelectAuthorizationType,
      onLogout,
      onTermsAccepted,
      onSwitchLocale,
      locale,
      suomiFiAuthorization,
      returnUriAfterRoleSwitch,
      intl: { formatMessage },
      history,
    } = this.props
    if (suomiFiAuthorization) {
      if (this.state.isLoading) {
        // Redirected to VRK WebApi, show some amusement for user meanwhile...
        return (
          <div className={styles.centered}>
            <Loader />
          </div>
        )
      }
      return (
        <SuomiFiAuthorizationTypeSelector
          onRejected={() => onLogout()}
          onSelect={onSelectAuthorizationType}
          onSwitchLocale={onSwitchLocale}
          locale={locale}
          authorizationTypes={suomiFiAuthorization.types}
        />
      )
    }

    if (auth.isAuthenticated) {
      if (!auth.termsAccepted) {
        return (
          <TermsOfService
            show
            onAccepted={() =>
              onTermsAccepted(auth.userId, formatMessage({ id: '/authentication/termsOfServiceVersion' }))}
            onRejected={() => onLogout()}
            submitFailure={auth.acceptTermsFailure}
            onSwitchLocale={onSwitchLocale}
            locale={locale}
          />
        )
      }

      const validAuthorizations = auth.authorizations.filter(a => this.isAuthorizationAllowed(a, auth.selectedAuthorizationType))
      const validAuthorizationsSize = size(validAuthorizations)
      const authPlurality = getAuthPlurality(validAuthorizations)

      const showRoleSelector = authPlurality !== AUTH_PLURALITY_TYPES.NO_AUTH && returnUriAfterRoleSwitch

      if (showRoleSelector) {
        return renderRoleSelector({
          history,
          validAuthorizations,
          onLogout,
          onSelectAuthorization,
          onSwitchLocale,
          locale,
          returnUriAfterRoleSwitch,
        })
      }

      if (isNil(auth.selectedAuthorization)) {
        let modalMessage = messages.error
        let titleMessage = { ...apiMessages.authorizationErrorHeader }
        if (auth.selectedAuthorizationType === AUTH_TYPES.principal) {
          if (auth.actOnBehalfOfMinor === true) {
            modalMessage = messages.interruptOnBehalfOfMinor
          } else {
            modalMessage = messages.interruptOnBehalf
          }
          titleMessage = { ...apiMessages.onBehalfInterruptHeader }
        }

        if (authPlurality === AUTH_PLURALITY_TYPES.NO_AUTH) {
          // Display special error modal and force the user to log out if they don't have
          // any valid authorizations to prevent the UI from ending up in an invalid state.
          return (
            <Modal
              show
              showCancel={false}
              disableEscKey
              titleMessage={titleMessage}
              continueMessage={messages.logout}
              onClickContinue={() => onLogout()}
              locale={locale}
              bodyStyle={{ padding: 0 }}
              focusButton
            >
              <div id="dialog-message">
                <div
                  className={'panel-body alert alert-danger'}
                  style={{ borderBottom: '5px solid', backgroundColor: '#fce8eb', marginBottom: '0' }}
                >
                  <div className="row">
                    <div className="col-xs-2 col-sm-1" style={{ paddingTop: '20px', paddingBottom: '15px' }}>
                      <Icon name="attention" lg className={styles.iconWarning} />
                    </div>
                    <div className="col-xs-10 col-sm-11">
                      <span>
                        <p style={{ paddingLeft: '10px', paddingTop: '20px', paddingBottom: '15px', color: '#c71530' }}>
                          <FormattedMessage {...modalMessage} values={{ br: <br /> }} />
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )
        }


        /*
          We check there is only one authorization, so we can automatically select it
          Handle private authorization by itself
        */
        const oneValidAuth = validAuthorizationsSize === 1 && validAuthorizations[0]

        switch (getAuthPlurality(validAuthorizations)) {
        case AUTH_PLURALITY_TYPES.PRIVATE:
          onSelectAuthorization({
            identifier: oneValidAuth.id,
          })
          break

        // eslint-disable-next-line no-case-declarations
        case AUTH_PLURALITY_TYPES.ONLY_ONE_DELEGATE:
          const delegateCompanies = get(oneValidAuth, 'delegateCompanies', [])
          const onlyValidDelegateCompany = delegateCompanies[0]

          onSelectAuthorization({
            identifier: oneValidAuth.identifier,
            delegateCompany: onlyValidDelegateCompany.identifier,
          })
          break

        case AUTH_PLURALITY_TYPES.ONLY_ONE_DIRECT:
          onSelectAuthorization({
            identifier: oneValidAuth.identifier,
          })
          break

        default:
          return renderRoleSelector({
            history,
            validAuthorizations,
            onLogout,
            onSelectAuthorization,
            onSwitchLocale,
            locale,
          })
        }
      }
    }

    return children
  }
}

const mapStateToProps = state => ({
  pathname: state.router.locationBeforeTransitions.pathname.slice(1),
  bootstrapConfig: state.config.bootstrapConfig,
  returnUriAfterRoleSwitch: state.auth.returnUriAfterRoleSwitch,
})

export default connect(mapStateToProps)(injectIntl(withSessionTimer(AuthorizationWrapper)))
