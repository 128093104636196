import { defineMessages } from 'react-intl'
import { get } from 'lodash'
import Notifications from 'react-notification-system-redux'
import md5 from 'md5'
import { createDefaultAction } from 'src/utils/redux'
import logger from 'src/utils/logger'
import { createHttpRequestHeaders, checkHttpStatus, apiCall } from 'src/utils/http'
import { getApplicationNameFromStorage } from 'src/utils'
import apiMessages from 'src/utils/apiMessages'
import {
  LOADING_ACTION,
  LOGOUT_REQUEST,
  LOGOUT,
  LOGIN_DATA_REQUEST,
  LOGIN_DATA,
  SWITCH_LOCALE,
  SELECT_AUTHORIZATION,
  ACCEPT_TERMS,
  CMS_CONTENT,
  CMS_SWITCH_LOCALE,
  BOOTSTRAP_CONFIG,
  SUOMI_FI_AUTHORIZATION_TYPE_REQUEST,
  IPA,
  CHANGE_SELECTED_NAME,
  AUTH_TYPES,
  RETURN_URI_AFTER_ROLE_SWITCH,
  SHOW_SWITCH_ROLE_CONFIRMATION_MODAL,
  SHOW_LOGOUT_CONFIRMATION_MODAL,
  TUTTU_PATH,
  SET_SHOW_COOKIE_NOTICE,
  SESSION_TOKEN_INVALID,
  SESSION_TOKEN_MISSING,
} from './constants'

/**
 * Message definitions for all possible api message codes
 */
const messages = defineMessages({
  loginSuccess: {
    id: 'auth.loginSuccess',
    description: 'User logged in successfully',
    defaultMessage: 'You are logged in',
  },
  logoutSuccess: {
    id: 'auth.logoutSuccess',
    description: 'User logged out successfully',
    defaultMessage: 'You are logged out',
  },
  setAuthorizationSuccess: {
    id: 'auth.setAuthorizationSuccess',
    description: 'User selected authorization successfully',
    defaultMessage: 'Selected authorization {authorization}',
  },
  logoutRequestFailure: {
    id: 'auth.logoutRequestFailure',
    description: 'Notification for unexpected error during logout',
    defaultMessage: 'Could not log out. Please try again.',
  },
  loginDataFailure: {
    id: 'auth.loginDataFailure',
    description: 'Notification for unexpected error during fetching user data after login',
    defaultMessage: 'Error in login',
  },
  authorizationTypeSelectionFailure: {
    id: 'auth.authorizationTypeSelectionFailure',
    description: 'Notification for unexpected error after authorization type is selected',
    defaultMessage: 'Error in authorization type selection.',
  },
  suomiFiAuthorizationFailure: {
    id: 'api.suomiFiAuthorizationFailure',
    description: 'Error message shown if Suomi.fi authorization failed',
    defaultMessage: 'Unexpected error when fetching authorization information.',
  },
  contentFetchFailure: {
    id: 'help.contentFetchFailure',
    description: 'Notification for error during fetching help and instruction content',
    defaultMessage: 'Could not fetch instructions, help texts may not be available.',
  },
})

export const loadingAction = createDefaultAction(LOADING_ACTION)

export const showGlobalNotification = notification =>
  Notifications.show(notification, notification.level)

export const switchLocale = createDefaultAction(SWITCH_LOCALE)

export const logoutRequest = createDefaultAction(LOGOUT_REQUEST)

export const logout = createDefaultAction(LOGOUT)

export function showLogoutNotification() {
  return (dispatch) => {
    dispatch(showGlobalNotification({
      level: 'success',
      autoDismiss: true,
      message: {
        ...messages.logoutSuccess,
      },
    }))
  }
}

export function showLoginNotification() {
  return (dispatch) => {
    dispatch(showGlobalNotification({
      level: 'success',
      autoDismiss: true,
      message: {
        ...messages.loginSuccess,
      },
    }))
  }
}

export function showSetAuthorizationNotificationAction(authorization) {
  return (dispatch) => {
    dispatch(showGlobalNotification({
      level: 'success',
      autoDismiss: true,
      message: {
        ...messages.setAuthorizationSuccess,
        values: { authorization },
      },
    }))
  }
}

/**
 * Thunk to start logout process, returns redirect url.
 * User should finally end up in separate `logout.html` app.
 *
 * @see `/src/static/logout.html`
 */
export function sendLogoutRequest() {
  return (dispatch, getState) => {
    dispatch(logoutRequest())

    const { config: { bootstrapConfig } } = getState()

    return apiCall(
      bootstrapConfig.tm_ext_auth_logout_url,
      { method: 'GET' },
      {},
      dispatch,
      false,
    )
      .then((response) => {
        if (response && response.redirect) {
          // Logout response should contain redirect url to external logout service
          if (!/^https?:\/\//.test(response.redirect)) {
            throw new Error('Invalid logout redirect')
          }
        } else {
          // Successful response without a redirect url means user is already logged out.
          // Dispatch logout to clear all login state from redux store (and browser storage).
          dispatch(logout())
          if (bootstrapConfig.nakki_logout_redirect_url) {
            return { redirect: bootstrapConfig.nakki_logout_redirect_url }
          }
        }
        return response
      })
      .catch((error) => {
        logger.error('Error in logout', error)
        dispatch(logout(error))
        throw error
      })
  }
}

export function sendLogoutRequestAndExit() {
  return (dispatch, getState) => {
    sendLogoutRequest()(dispatch, getState)
      .then((response) => {
        if (response && response.redirect) {
          window.location.href = response.redirect
        }
      })
      .catch((error) => {
        logger.error('Error in sending logout request', error)
        dispatch(showGlobalNotification({
          level: 'error',
          message: messages.logoutRequestFailure,
        }))
      })
  }
}

export const loginDataRequest = createDefaultAction(LOGIN_DATA_REQUEST)

export const loginData = createDefaultAction(LOGIN_DATA)

export function getUserData(content, isTermsAcceptedInBrowserStorage) {
  return (dispatch, getState) => {
    dispatch(loginDataRequest())

    const { config: { bootstrapConfig }, locale } = getState()

    return apiCall(bootstrapConfig.tm_ext_auth_user_api_url, { method: 'GET' }, {}, dispatch, false)
      .then((response) => {
        const termsOfServiceVersion = get(content, '/authentication/termsOfServiceVersion')
        const userData = {
          ...response,
          termsAccepted: isTermsAcceptedInBrowserStorage(response.userId, termsOfServiceVersion[locale]),
        }
        dispatch(loginData(userData))
        return userData
      })
      .catch((error) => {
        dispatch(loginData(error))

        const tokenMissing = get(error, 'value.code') === SESSION_TOKEN_MISSING
        const tokenExpired = get(error, 'value.code') === SESSION_TOKEN_INVALID

        // Dont show any errors if the token cookie is not present
        if (tokenMissing) {
          return
        }

        logger.error('Error in login', error)
        let errorNotification
        if (tokenExpired) {
          errorNotification = {
            level: 'error',
            autoDismiss: true,
            modal: false,
            message: apiMessages.invalidToken,
          }
        } else {
          errorNotification = {
            level: 'error',
            modal: true,
            message: messages.loginDataFailure,
          }
        }
        dispatch(showGlobalNotification(errorNotification))
      })
  }
}

export const selectAuthorization = createDefaultAction(SELECT_AUTHORIZATION)
export const changeSelectedName = createDefaultAction(CHANGE_SELECTED_NAME)
export const changeReturnUriAfterRoleSwitch = createDefaultAction(RETURN_URI_AFTER_ROLE_SWITCH)
export const changeShowSwitchRoleConfirmationModal = createDefaultAction(SHOW_SWITCH_ROLE_CONFIRMATION_MODAL)
export const changeShowLogoutConfirmationModal = createDefaultAction(SHOW_LOGOUT_CONFIRMATION_MODAL)

export function sendSelectAuthorization(selectedAuth, returnUriAfterRoleSwitch, history) {
  return (dispatch, getState) => {
    const {
      auth: {
        authorizations,
      },
      config: {
        bootstrapConfig,
      } } = getState()

    const index = authorizations.findIndex(authorization => authorization.identifier === selectedAuth.identifier)

    return apiCall(
      `${bootstrapConfig.tm_ext_auth_user_api_url}setAuthorization`,
      {
        method: 'POST',
        body: JSON.stringify(selectedAuth),
      },
      {},
      dispatch,
      true,
    )
      .then(() => {
        const selectedAuthorizationObject = authorizations[index] ? authorizations[index] : null
        if (selectedAuthorizationObject !== null &&
          (selectedAuthorizationObject.type === AUTH_TYPES.private ||
            selectedAuthorizationObject.type === AUTH_TYPES.principal)) {
          dispatch(changeSelectedName(selectedAuthorizationObject.name))
        }

        const application = getApplicationNameFromStorage()

        if (returnUriAfterRoleSwitch) {
          if (returnUriAfterRoleSwitch.includes(TUTTU_PATH)) {
            window.location.assign(`${window.location.origin}${window.location.pathname}${returnUriAfterRoleSwitch}`)
          } else {
            history.push(returnUriAfterRoleSwitch)
            dispatch(changeReturnUriAfterRoleSwitch(null))
          }
        } else if (application && application.includes(TUTTU_PATH)) {
          window.location.assign(`${window.location.origin}${window.location.pathname}${application}`)
        }

        dispatch(selectAuthorization(selectedAuth))

        return true
      })
      .catch((error) => {
        logger.error(`Could not select authorization ${index}`, error)
        selectAuthorization(error)
        return false
      })
  }
}

export const suomiFiAuthorizationTypeRequest = createDefaultAction(SUOMI_FI_AUTHORIZATION_TYPE_REQUEST)

export function sendSelectAuthorizationType(type) {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  return (dispatch, getState) => {
    const { locale, config: { bootstrapConfig } } = getState()

    // The authorization type selection dialog won't work properly after redirecting so we clear it from history.
    window.history.replaceState({}, '', bootstrapConfig.nakki_front_page_url)

    if (type === IPA) {
      const application = getApplicationNameFromStorage()
      window.location.href = `${bootstrapConfig.nakki_front_page_url}/${application}`

      return null
    }

    return apiCall(
      `${bootstrapConfig.tm_ext_auth_user_api_url}setAuthorizationType?authorizationType=${type}&locale=${locale}`,
      { method: 'POST' },
      {},
      dispatch,
      true,
    )
      .then(async (response) => {
        if (response.error) {
          dispatch(suomiFiAuthorizationTypeRequest(response.error))
          logger.error('Error in authorization type selection:', response.error)
          const errorNotification = {
            level: 'error',
            modal: true,
            message: messages.suomiFiAuthorizationFailure,
          }
          dispatch(showGlobalNotification(errorNotification))
        } else {
          window.location.href = response.url
          // It takes some time to load VRK web page, so we have to wait couple of second before dismissing the modal
          // to prevent screen flashing effect
          await sleep(2000)
          dispatch(suomiFiAuthorizationTypeRequest(false))
        }
      })
      .catch((error) => {
        logger.error('Error in authorization type selection', error)
        dispatch(suomiFiAuthorizationTypeRequest(error))
      })
  }
}

export const acceptTerms = createDefaultAction(ACCEPT_TERMS)

export function sendAcceptTerms(userId, termsOfServiceVersion) {
  function persistAcceptedTosVersionToLocalStorage() {
    const allAcceptedTosJson = localStorage.getItem('acceptedTerms') || '{}'
    let allAcceptedTos
    try {
      allAcceptedTos = JSON.parse(allAcceptedTosJson)
    } catch (err) {
      localStorage.removeItem('acceptedTerms')
      allAcceptedTos = {}
    }
    allAcceptedTos[userId] = md5(termsOfServiceVersion)
    localStorage.setItem('acceptedTerms', JSON.stringify(allAcceptedTos))
  }

  return (dispatch, getState) => {
    const { config: { bootstrapConfig } } = getState()

    return fetch(`${bootstrapConfig.tm_ext_auth_ext_url}/tos/`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: createHttpRequestHeaders(),
    })
      .then(checkHttpStatus)
      .then(() => persistAcceptedTosVersionToLocalStorage())
      .then(() => dispatch(acceptTerms(userId)))
      .catch((error) => {
        logger.error('Error in accepting terms', error)
        dispatch(acceptTerms(error))
      })
  }
}

export const storeCmsContent = createDefaultAction(CMS_CONTENT)
export const switchCmsLocale = createDefaultAction(CMS_SWITCH_LOCALE)
export const storeBootstrapConfig = createDefaultAction(BOOTSTRAP_CONFIG)

export const setShowCookieNotice = createDefaultAction(SET_SHOW_COOKIE_NOTICE)
