import React from 'react'
import { get, isEmpty } from 'lodash'
import { PATH_TO_TITLE_MAP, RETURN_URI_QUERY_PARAM } from 'src/constants'
import { defineMessages } from 'react-intl'
import { connect } from 'react-redux'
import { collectCmsMessages, focusFirstElementByIdOrName } from 'src/utils'
import { Form } from 'react-form'
import { browserHistory } from 'react-router'
import CommonFormGroup from 'src/components/form_v2/CommonFormGroup'
import Modal from './Modal'
import { formatSelectedAuthorizationName, getAllDelegateIds, setPageTitle } from '../utils/index'

// Header-related intl message definitions
const messages = defineMessages({
  title: {
    id: 'roleSelector.title',
    description: 'Title for role selection modal',
    defaultMessage: 'Role selection',
  },
  continue: {
    id: 'roleSelector.continue',
    description: 'Button value for role selection operation "continue"',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'roleSelector.cancel',
    description: 'Button value for role selector cancel operation (resulting in logout)',
    defaultMessage: 'Logout',
  },
})

class RoleSelector extends React.Component {
  constructor(props) {
    super(props)
    this.formApi = null

    // This is to fix issue of the modal staying visible when pressing back button
    // https://jira.tulli.csc.fi/browse/NAKKI-2831
    this.backListener = browserHistory.listen((location) => {
      if (location.action === 'POP') {
        window.location.reload()
      }
    })
  }

  componentDidMount() {
    const { locale } = this.props
    setPageTitle(PATH_TO_TITLE_MAP, RETURN_URI_QUERY_PARAM, locale)
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props
    if (locale !== prevProps.locale) {
      setPageTitle(PATH_TO_TITLE_MAP, RETURN_URI_QUERY_PARAM, locale)
    }
  }

  componentWillUnmount() {
    const { locale, router, returnUriAfterRoleSwitch } = this.props
    const pathname = get(router, 'locationBeforeTransitions.pathname', null)
    // This needs to be done when returning from Suomi.fi after login
    if (!returnUriAfterRoleSwitch && pathname) {
      setPageTitle(PATH_TO_TITLE_MAP, pathname, locale)
    }
  }

  onClick = () => {
    this.formApi.submitForm().then(() => {
      const errors = get(this.formApi.getFormState(), 'errors', {})

      if (errors) {
        focusFirstElementByIdOrName(Object.keys(errors))
      }
    })
  }

  connectFormApi = (formApi) => {
    this.formApi = formApi
  }

  render() {
    const {
      authorizations,
      onRejected,
      locale,
      onSwitchLocale,
      children,
      content,
      onSelect,
      returnUriAfterRoleSwitch,
      history,
      dropdownMinItemCount,
    } = this.props
    const delegateCompanyLabel = get(content, 'delegateCompanyLabel')
    const showDropdown = authorizations.length >= dropdownMinItemCount

    return (
      <div>
        <Modal
          show
          titleMessage={messages.title}
          cancelMessage={messages.cancel}
          showCancel
          onClickCancel={onRejected}
          continueMessage={messages.continue}
          onClickContinue={this.onClick}
          showContinue
          locale={locale}
          onSwitchLocale={onSwitchLocale}
          bodyStyle={{ paddingTop: '25px' }}
          disableEscKey
          focusLocaleSwitch
        >
          <Form
            getApi={this.connectFormApi}
            onSubmit={() => {
              const formState = this.formApi.getFormState()
              const authorization = get(formState, 'values.authorization')
              const parsedAuthorization = JSON.parse(authorization)
              const { id, delegateCompany } = parsedAuthorization

              const selectedAuthorization = authorizations.find(auth => auth.id === id)
              const delegateCompanies = get(selectedAuthorization, 'delegateCompanies', [])
              const selectedDelegateCompany = delegateCompanies.find(auth => auth.id === delegateCompany)
              onSelect({
                identifier: selectedAuthorization.identifier,
                delegateCompany: selectedDelegateCompany ? selectedDelegateCompany.identifier : undefined,
              }, returnUriAfterRoleSwitch, history)
            }}
            render={formApi => (
              <form>
                <CommonFormGroup
                  errors={formApi.errors}
                  formApi={formApi}
                  formGroupClassname="formElementGutter"
                  formName="RoleSelector"
                  input={{
                    name: 'authorization',
                    static: false,
                    type: showDropdown ? 'select' : 'radioListWithField',
                    placeholder: showDropdown ? get(content, 'roleSelectorDropdownPlaceholder') : undefined,
                    validate: true,
                    validation: {
                      mandatory: true,
                    },
                    visible: true,
                    multilineLabel: true,
                  }}
                  label={get(content, 'roleSelectorBodyText')}
                  options={authorizations
                    .flatMap((authorization) => {
                      const delegateCompanies = get(authorization, 'delegateCompanies')
                      if (!isEmpty(delegateCompanies)) {
                        return getAllDelegateIds(authorization, delegateCompanies, delegateCompanyLabel)
                      }
                      return {
                        title: formatSelectedAuthorizationName(authorization),
                        value: JSON.stringify({
                          id: authorization.id,
                        }),
                      }
                    })
                  }
                  showLabel
                  type="BOOLEAN"
                />
              </form>
            )}
          />
        </Modal>
        <div id="dialog-message">
          {children}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const cmsMessages = get(state, 'content.cmsMessages', {})
  const authenticationContent = collectCmsMessages('/authentication', cmsMessages)

  return {
    content: authenticationContent,
    locale: state.locale,
    router: state.router,
    dropdownMinItemCount: get(state.config, 'bootstrapConfig.role_selector_dropdown_min_item_count', 10),
  }
}

export default connect(mapStateToProps)(RoleSelector)
