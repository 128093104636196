import moment from 'moment'
import { find, uniq, findIndex, findLastIndex, get, isEmpty } from 'lodash'
import { SUPPORTED_LOCALES, DEFAULT_LOCALE, GLOBAL_STATUS_ID } from '../constants'
import { isFinnishVatNumber } from './validation'

export function getParams(location) {
  return new URLSearchParams(location.search)
}

/**
 * Get valid locale primarily from storage, secondarily from browser.
 *
 * @returns {string} valid locale or default locale if valid is not found
 */
export function getValidLocale(...locales) {
  if (locales && locales.length) {
    for (const locale of locales) {
      if (locale && SUPPORTED_LOCALES.indexOf(locale) >= 0) {
        return locale
      }
    }
  }
  return DEFAULT_LOCALE
}

/**
 * Persist to storage only if value has changed.
 *
 */
export function persistChangeToStorage(storage, storageKey, newValue, oldValue) {
  if (newValue !== oldValue) {
    if (newValue) {
      storage.setItem(storageKey, newValue)
    } else {
      storage.removeItem(storageKey)
    }
    return newValue
  }
  return oldValue
}

export const isBrowserInternetExplorer = () => {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ') // IE 10 or older
  const trident = ua.indexOf('Trident/') // IE 11
  return Boolean(msie > 0 || trident > 0)
}

export const getApplicationNameFromStorage = () => {
  const redirectAfterLoginLocation = localStorage.getItem('redirectAfterLoginLocation')
  return redirectAfterLoginLocation ? redirectAfterLoginLocation.substring(1) : ''
}

export const wrapPreventDefault = callback =>
  (event) => {
    if (event) {
      event.preventDefault()
    }
    callback(event)
    return false
  }

// Generator function returning an iterator to a specified range of numbers
export function* range(begin, end, step = 1) {
  for (let i = begin; i < end; i += step) {
    yield i
  }
}

export const uniqueId = () =>
  Math.random().toString(36).substr(2, 9)

export function formatBusinessId(businessId) {
  if (isFinnishVatNumber(businessId)) {
    const formattedId = businessId.replace('FI', '')
    const slicedId = formattedId.slice(0, formattedId.length - 1)
    return `${slicedId}-${formattedId[formattedId.length - 1]}`
  }
  return businessId
}

export function formatUTCdateStringIntoFinnishFormat(UTCdateFormatString) {
  return moment(UTCdateFormatString).format('DD.MM.YYYY')
}

export function formatCmsMessage(formatMessage, messageKey) {
  const messageObj = {
    id: messageKey,
  }
  const message = formatMessage(messageObj)
  if (message === messageKey) {
    return ''
  }
  return message
}

export function formatOptionalCmsMessage(intl, messageKey, defaultMessageKey) {
  const { formatMessage, messages } = intl
  if (messages[messageKey]) {
    return formatMessage({ id: messageKey })
  }
  if (defaultMessageKey) {
    return formatMessage({ id: defaultMessageKey, defaultMessage: '' })
  }
  return ''
}

export function collectCmsMessages(prefix, cmsMessages) {
  const messagePrefix = prefix.substring(prefix.length - 1, prefix.length) === '/' ? prefix : `${prefix}/`
  const prefixSubstringLenght = messagePrefix.length
  const messages = {}
  Object.keys(cmsMessages).forEach((key) => {
    if (key.indexOf(messagePrefix) === 0) {
      const messageKey = key.substring(prefixSubstringLenght)
      messages[messageKey] = cmsMessages[key]
    }
  })
  return messages
}

export function markdownLinkToText(content) {
  let formatted = content.replace(/\((.*?)\)/gi, '') // remove all parenthesis
  formatted = formatted.replace(/[[+\]]/gi, '') // remove all link sections by square brackets
  return formatted
}

export function focusFirstElementByIdOrName(idArray) {
  const selector = uniq(idArray)
    .map((id) => {
      const escapedId = id.replace(/\./g, '\\.')
      return `#${escapedId},input[name='${escapedId}']`
    })
    .join(',')

  if (selector) {
    let firstElement = document.querySelector(selector)
    if (firstElement) {
      if (firstElement.type === 'radio') {
        const checkedElement = find(document.getElementsByName(firstElement.name), e => e.checked)
        if (checkedElement) {
          firstElement = checkedElement
        }
      }
      setTimeout(() => firstElement.focus(), 1)
    }
  }
}

export function focusFirstInputWithErrorInModal() {
  const errorElement = document.querySelector('.modal-body .has-error input, .modal-body .has-error select')
  if (errorElement) {
    errorElement.focus()
  }
}

export function focusAndScrollFirstFocusableElementInPanel(id) {
  setTimeout(() => {
    const targetElement = document
      .querySelector(id)

    if (!targetElement) {
      return
    }

    const firstFocusable = targetElement.querySelector('[tabindex="-1"]')

    if (firstFocusable) {
      // scroll target and focus next focusable in it

      targetElement.scrollIntoView()
      firstFocusable.focus()
    }
  }, 1)
}

export function focusFirstInputElementInModal() {
  setTimeout(() => {
    const firstInputElement = document
      .querySelector('.modal-content input, .modal-content select, .modal-content button')
    if (firstInputElement) {
      firstInputElement.focus()
    }
  }, 1)
}

export function focusFirstInputElementWithError() {
  const errorElement = document.querySelector('.has-error input, .has-error select, .has-error textarea')
  if (errorElement) {
    errorElement.focus()
  }
}

export function focusElementById(id, delay = 0) {
  setTimeout(() => {
    const element = document.getElementById(id)
    if (element) {
      element.focus()
    }
  }, delay)
}

export function createAriaLiveRegion() {
  let globalStatus = document.getElementById(GLOBAL_STATUS_ID)
  if (!globalStatus) {
    globalStatus = document.createElement('div')
    globalStatus.setAttribute('id', GLOBAL_STATUS_ID)
    globalStatus.setAttribute('aria-live', 'assertive')
    globalStatus.setAttribute('aria-atomic', 'true')
    globalStatus.setAttribute('class', 'sr-only')
    document.body.insertBefore(globalStatus, document.getElementById('root'))
  }
  return globalStatus
}

export function updateStatusMessage(message) {
  const globalStatuselement = createAriaLiveRegion()
  globalStatuselement.innerHTML = message
  setTimeout(() => {
    if (document.getElementById(GLOBAL_STATUS_ID).innerHTML === message) {
      document.getElementById(GLOBAL_STATUS_ID).innerHTML = ''
    }
  }, 1000)
}

export function updateStatusMessageForInput(inputName) {
  const errorElement = document.getElementById(`${inputName}-error`)
  if (errorElement) {
    const errorMessage = errorElement.getAttribute('sr-message')
    if (errorMessage) {
      updateStatusMessage(errorMessage)
    }
  }
}

/*
  Modulo function for large numbers. Large numbers in JavaScript are unreliable for computing a modulo. This function
  computes the modulo in smaller chunks that are then added.

  https://stackoverflow.com/questions/929910/modulo-in-javascript-large-number
*/
export function modulo(divident, divisor) {
  const chunkLength = 10
  let accDivident = `${divident}`
  while (accDivident.length > chunkLength) {
    const part = accDivident.substring(0, chunkLength)
    accDivident = (part % divisor) + accDivident.substring(chunkLength)
  }
  return accDivident % divisor
}

export function getURLParam(param) {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(param) || null
}

/**
 * NAKKI-1824 react-autosuggest accessibility fixes
 * This function hacks react-autosuggest DOM elements to fix accessibility bugs in the library.
 */
export function fixAutocompleteAccessibility(inputId) {
  const inputElement = document.getElementById(inputId)
  if (inputElement) {
    const suggestionsDiv = inputElement.nextSibling
    if (suggestionsDiv) {
      const labelId = `${inputId}Label`
      if (suggestionsDiv.id.indexOf('react-autowhatever') === 0) {
        suggestionsDiv.removeAttribute('role')
      }
      suggestionsDiv.querySelectorAll('ul')
        .forEach((ul, index) => {
          let labelledby = labelId
          const groupLabelElement = ul.previousSibling
          if (groupLabelElement && groupLabelElement.className === 'react-autosuggest__section-title') {
            const groupLabelId = `${labelId}Group${index}`
            groupLabelElement.setAttribute('id', groupLabelId)
            labelledby = `${labelledby} ${groupLabelId}`
          }
          ul.setAttribute('aria-labelledby', labelledby)
        })
    }
  }
  const dropdownElement = document.getElementById(`react-autowhatever-${inputId}-options`)
  if (dropdownElement) {
    dropdownElement.setAttribute('tabIndex', -1)
  }
}

export function sortServices(list) {
  return list.sort((a, b) => {
    const aOrder = parseInt(a.extensionValues.find(extValue => extValue.extensionName === 'order').value, 10)
    const bOrder = parseInt(b.extensionValues.find(extValue => extValue.extensionName === 'order').value, 10)
    return (aOrder || 0) - (bOrder || 0)
  })
}

export function setPageTitle(pathMap, pathname, language) {
  let titleIndex = findIndex(pathMap, o => pathname === o.path)
  if (titleIndex === -1) {
    titleIndex = findLastIndex(pathMap, o => pathname.indexOf(o.path) === 0)
  }
  if (titleIndex === -1) {
    titleIndex = 0
  }
  const title = get(pathMap, `[${titleIndex}]title.${language}`)
  if (document.title !== title) {
    document.title = title
  }
}

export function formatRepresentativeName(representative, delegateCompanyLabel) {
  const representativeName = get(representative, 'name')

  if (!representativeName) {
    return ''
  }

  const formattedDelegateString = !isEmpty(delegateCompanyLabel) ? delegateCompanyLabel.replace('{delegateName}', representativeName) : representativeName
  return formattedDelegateString
}

export function formatSelectedAuthorizationName(auth, selectedDelegateId = '', delegateCompanyLabel = '') {
  const delegateCompanies = get(auth, 'delegateCompanies', [])
  const delegate = delegateCompanies ? delegateCompanies.find(comp => comp.identifier === selectedDelegateId) : delegateCompanies

  if (delegate) {
    const formattedDelegateString = !isEmpty(delegateCompanyLabel) ? delegateCompanyLabel.replace('{delegateName}', delegate.name) : delegate.name
    return `${auth.name} ${formattedDelegateString}`
  }
  return auth.name
}

export function getAllDelegateIds(auth, delegateCompanies, delegateCompanyLabel) {
  if (!delegateCompanies) {
    return null
  }

  const delegateIds = delegateCompanies.map(comp => ({
    title: formatSelectedAuthorizationName(auth, comp.identifier, delegateCompanyLabel),
    value: JSON.stringify({
      id: auth.id,
      delegateCompany: comp.id,
    }),
  }))

  if (delegateIds.length && auth.roles.length) {
    delegateIds.push({
      title: formatSelectedAuthorizationName(auth),
      value: JSON.stringify({
        id: auth.id,
      }),
    })
  }

  return delegateIds
}
