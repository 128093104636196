import React from 'react'
import { Col, Button, Row, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-form'
import { find, xor, chain, has, every } from 'lodash'
import moment from 'moment'
import Loader from 'src/components/Loader'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import showNotification from 'src/utils/notifications'
import { focusFirstInputWithErrorInModal } from 'src/utils'
import DateOfBirthFields from './DateOfBirthFields'
import messages from '../messages'
import { validateDob } from '../../../../../../utils/validation'

export default class PicForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNew: null,
      dateOfBirthInvalid: false,
    }
    this.formApi = null
    this.connectFormApi = this.connectFormApi.bind(this)
    this.savePic = this.savePic.bind(this)
    this.cancel = this.cancel.bind(this)
    this.onClickContinue = this.onClickContinue.bind(this)
  }

  onClickContinue(event) {
    const birthYear = this.formApi.getValue('year')
    const birthMonth = this.formApi.getValue('month')
    const birthDay = this.formApi.getValue('day')

    const { infoElement } = this.props
    const picDateOfBirth = find(infoElement.fields, { code: 'personInHargeDateOfBirth' }) // [sic]

    const { valid } = validateDob(picDateOfBirth, birthYear, birthMonth, birthDay)
    const dateOfBirthInvalid = !valid

    this.setState({ dateOfBirthInvalid })

    this.formApi.submitForm(event)
      .then(focusFirstInputWithErrorInModal)
  }

  cancel(e) {
    if (e) {
      e.preventDefault()
    }

    if (this.state.isNew) {
      this.setState({
        isNew: false,
        dateOfBirthInvalid: false,
      })
    } else {
      this.setState({
        dateOfBirthInvalid: false,
      })
      this.props.cancelPicEditing()
      this.props.cancelCallback()
    }
  }

  savePic(formData) {
    const { addNewPic, updatePic, selectedPicData, infoElement } = this.props
    const { isNew } = this.state
    const picDateOfBirth = find(infoElement.fields, { code: 'personInHargeDateOfBirth' }) // [sic]
    const { dob, valid } = validateDob(picDateOfBirth?.mandatory, formData.year, formData.month, formData.day)

    this.setState({ dateOfBirthInvalid: !valid })

    const newFormData = valid && dob ? {
      ...formData,
      personInHargeDateOfBirth: dob.format('YYYYMMDD'), // [sic]
    } : formData

    const fieldValues = chain(infoElement.fields)
      .filter(field => field.mandatory)
      .map(field =>
        has(newFormData, field.code) && formData[field.code] !== ''
      )
      .value()

    if (every(fieldValues, value => value)) {
      if (valid) {
        if (isNew) {
          addNewPic(newFormData)
          this.afterDoneSavingPic()
        } else if (selectedPicData) {
          updatePic(selectedPicData, newFormData)
          this.afterDoneSavingPic()
        }
      }
    }
  }

  afterDoneSavingPic() {
    this.cancel()
    showNotification({
      level: 'success',
      message: messages.doneSavingPic,
      autoDismiss: true,
    })
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  render() {
    const {
      selectedPicData,
      error,
      deletingPic,
      infoElement,
      renderField,
      locale,
    } = this.props

    const { isNew } = this.state
    const titleMessage = messages.newPicHeading

    const picNameField = find(infoElement.fields, { code: 'personInChargeName' })
    const picNationalIdentificationNumberField = find(infoElement.fields, {
      code: 'personInChargeNationalIdentificationNumber',
    })
    const picDateOfBirth = find(infoElement.fields, { code: 'personInHargeDateOfBirth' }) // [sic]
    const picAddressField = find(infoElement.fields, { code: 'personInChargeAddress' })
    const picPostalCodeField = find(infoElement.fields, { code: 'personInChargePostalCode' })
    const picCityField = find(infoElement.fields, { code: 'personInChargeCity' })
    const picCountryCodeField = find(infoElement.fields, { code: 'personInChargeCountryCode' })

    const otherFields = xor(infoElement.fields, [picNameField,
      picNationalIdentificationNumberField,
      picDateOfBirth,
      picAddressField,
      picPostalCodeField,
      picCityField,
      picCountryCodeField,
    ])

    const slidingContentStyle = {
      transition: 'transform 200ms ease-in-out',
    }

    return (
      <div>
        <ButtonToolbar>
          <Button
            id="new-row-personInCharge"
            aria-describedby={this.props.buttonDescriptionId}
            bsStyle="primary"
            onClick={() => this.setState({ isNew: true })}
            id-qa-test="button-add-person"
            ref={(ref) => { this.editNewPicButtonRef = ref }}
          >
            <Icon name="add" /><FormattedMessage {...messages.addPic} />
          </Button>
        </ButtonToolbar>
        <Modal
          show={Boolean(isNew || selectedPicData)}
          size="lg"
          showCancel
          cancelMessage={messages.cancelPic}
          onClickCancel={this.cancel}
          showContinue
          continueMessage={messages.savePic}
          onClickContinue={this.onClickContinue}
          titleMessage={titleMessage}
          animation={false}
          contentRef={(ref) => { this.modalContentRef = ref }}
        >
          <div
            ref={(ref) => { this.contentRef = ref }}
            style={slidingContentStyle}
            onTransitionEnd={this.onScrollToCN8TransitionEnd}
          >
            <Form
              onSubmit={this.savePic}
              getApi={this.connectFormApi}
              defaultValues={selectedPicData}
              render={(formApi) => {
                if (selectedPicData && !formApi.values.day) {
                  const dateOfBirth = moment(selectedPicData.personInHargeDateOfBirth, 'YYYYMMDD') // [sic]
                  formApi.setAllValues({
                    ...selectedPicData,
                    day: dateOfBirth.get('date'),
                    month: dateOfBirth.get('month') + 1,
                    year: dateOfBirth.get('year'),
                  })
                }
                return (
                  <form>
                    {deletingPic && <Loader blocking />}
                    <Row>
                      <Col xs={12}>
                        {picNameField && renderField({
                          field: picNameField,
                          formApi,
                          autoFocus: true,
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        {picDateOfBirth && <DateOfBirthFields
                          field={picDateOfBirth}
                          infoElement={infoElement}
                          renderField={renderField}
                          locale={locale}
                          formApi={formApi}
                          errors={formApi.errors}
                          dateOfBirthInvalid={this.state.dateOfBirthInvalid}
                        />}
                      </Col>
                      <Col sm={6}>
                        {picNationalIdentificationNumberField && renderField({
                          field: picNationalIdentificationNumberField,
                          formApi,
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        {picAddressField && renderField({
                          field: picAddressField,
                          formApi,
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        {picPostalCodeField && renderField({
                          field: picPostalCodeField,
                          formApi,
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        {picCityField && renderField({
                          field: picCityField,
                          formApi,
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        {picCountryCodeField && renderField({
                          field: picCountryCodeField,
                          formApi,
                        })}
                      </Col>
                    </Row>
                    {otherFields && otherFields.map(field =>
                      <Row>
                        <Col xs={12}>
                          {renderField({
                            field,
                          })}
                        </Col>
                      </Row>
                    )}
                  </form>
                )
              }}
            />
            <Row>
              <Col md={8} sm={12}>
                {error && error.id &&
                  <div className="text-danger text-right" role="alert">
                    <FormattedMessage {...error} />
                  </div>
                }
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    )
  }
}
