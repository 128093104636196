import React from 'react'
import { get } from 'lodash'
import ReactMarkdown from 'react-markdown'
import Modal from 'src/components/Modal'
import LinkRenderer from 'src/components/LinkRenderer'

export default function (props) {
  const { visible, helpTexts, setShowModal, router } = props
  const noCustomerInfoContent = get(helpTexts, '/authentication/noCustomerInfoModalContent', '')

  return (
    <Modal
      show={visible}
      showContinue
      disableEscKey
      showCancel={false}
      titleMessage={get(helpTexts, '/authentication/noCustomerInfoModalTitle')}
      continueMessage={get(helpTexts, '/authentication/noCustomerInfoModalContinueButtonText')}
      onClickContinue={() => {
        setShowModal(false)
        router.replace('/')
      }}
      focusButton
    >
      <p className="lead">
        <ReactMarkdown
          source={noCustomerInfoContent}
          renderers={{ Link: LinkRenderer }}
          target={'_blank'}
        />
      </p>
    </Modal>
  )
}
