import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { sendLogoutRequest } from 'src/actions'
import Logout from './Logout'

const mapStateToProps = (state) => {
  const {
    token,
    isAuthenticated,
    isAuthenticating,
    logoutRequested,
    logoutFailed,
  } = state.auth

  return {
    token,
    isAuthenticated,
    isAuthenticating,
    logoutRequested,
    logoutFailed,
  }
}

const mapActionCreators = {
  sendLogoutRequest,
}

export default withRouter(
  connect(mapStateToProps, mapActionCreators)(Logout)
)
