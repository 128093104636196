import React from 'react'
import styles from 'src/styles/_tables.scss'
import { FormattedMessage } from 'react-intl'

/**
 * Common renderer for React Virtualized lists without rows
 */
export default function noRowsRenderer(message, renderContainer = false) {
  const output = (
    <div tabIndex="0" className={styles.noRows}>
      <FormattedMessage {...message} />
    </div>
  )
  if (renderContainer) {
    return (
      <div className={styles.noRowsContainer}>
        {output}
      </div>
    )
  }
  return output
}
