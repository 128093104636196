import React from 'react'
import {
  filter,
  isBoolean,
  has,
  find,
  chain,
  includes,
  get,
  isEmpty,
} from 'lodash'
import { FormattedMessage } from 'react-intl'
import { Grid, Button } from 'react-bootstrap'
import ErrorBoundary from 'src/components/ErrorBoundary'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import { focusFirstElementByIdOrName, focusElementById } from 'src/utils'
import { PERMIT_USER_TYPES, selectedAuthorizationHasRole } from 'src/utils/auth'
import showNotification from 'src/utils/notifications'
import { exitPermit } from './PermitPage/ExitPermitButton'
import PermitButtonToolbar from './PermitButtonToolbar'
import DeleteApplicationModal from './DeleteApplicationModal'
import InfoElement from './InfoElement'
import { renderField } from './InfoElementField'
import {
  getStepComponent,
  isGuaranteeRequired,
} from '../permitManager'
import api from '../../api'
import {
  getAdjacentStepPath,
  getReferenceNumberAndVersionFromRoute,
} from '../utils'
import css from './Step.scss'
import messages from '../messages'
import {
  PERMITS_ROUTE_PATH,
  PERMIT_APPLICATION_ROUTE_PATH,
  PERMIT_APPLICATION_STATUS_DRAFT,
  PERMIT_APPLICATION_STATUS_REGISTERED,
  PERMIT_APPLICATION_STATUS_VERIFICATION,
  PERMIT_APPLICATION_STATUS_ACCEPTED,
  PERMIT_APPLICATION_STATUS_NOT_ACCEPTED,
  PERMIT_APPLICATION_STATUS_WITHDRAWN,
  PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
} from '../../constants'
import {
  APPLICATION_BASIS_TAP_INFO_ELEMENT_CODE,
  APPLICATION_BASIS_TAT_INFO_ELEMENT_CODE,
  APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE,
  APPLICATION_BASIS_TITLE_KEY,
  INIT_ROUTE_PATH,
} from '../constants'
import PermitPageContext from './PermitPage/context'
import { filterRepresentativeIfNoValue } from '../permitHelper'
import RepresentativeNotification, {
  getCurrentUserApplicationsRepresentativeStatus,
} from './RepresentativeNotification'
import { NOTIFICATION_TYPE } from './InlineNotification'

function userHasAdminAuthorization() {
  return selectedAuthorizationHasRole(PERMIT_USER_TYPES.admin)
}

function userHasHandlerAuthorization() {
  return selectedAuthorizationHasRole(PERMIT_USER_TYPES.handler)
}

function userIsAuthorizedToSendApplication() {
  return userHasAdminAuthorization()
}

function userIsAuthorizedToCopyApplication() {
  return userHasAdminAuthorization() || userHasHandlerAuthorization()
}

export default class Step extends React.Component {
  constructor(props) {
    super(props)
    this.leavingFromSubmit = false
    this.exitAction = this.exitAction.bind(this)
    this.renderSummaryRelatedButtons = this.renderSummaryRelatedButtons.bind(this)
    this.showInfoSendingNotAllowed = this.showInfoSendingNotAllowed.bind(this)
    this.deleteApplicationCallback = this.deleteApplicationCallback.bind(this)
    this.backAction = this.backAction.bind(this)
    this.routerWillLeave = this.routerWillLeave.bind(this)
    this.sendApplication = this.sendApplication.bind(this)
    this.state = {
      showDeleteModal: false,
      showCopyModal: false,
      showRepresentativeModal: false,
      showApplicationSentModal: false,
      sentApplicationReferenceNumber: null,
      copyApplicationValue: null,
    }
  }

  componentDidMount() {
    this.checkCreatorAuthorization()
    const { router, route } = this.props
    this.removeLeaveHook = router.setRouteLeaveHook(route, this.routerWillLeave)
  }

  componentWillUnmount() {
    if (this.removeLeaveHook) {
      this.removeLeaveHook()
    }
  }

  getPreviousStepPath() {
    return getAdjacentStepPath(this.context.structure, this.props.router.params, -1)
  }

  routerWillLeave() {
    const formState = this.context.formApi.getFormState()
    // Clear errors for this step so they don't prevent other steps from validating and the user navigating
    this.context.formApi.setFormState({
      ...formState,
      errors: {},
    })
  }

  checkCreatorAuthorization() {
    const { initialValues } = this.context
    const { applicant } = this.props
    const creatorAuthorization = get(initialValues, 'authorizationOrDecisionHolder.authorizationOrDecisionHolderIdentification')

    if (creatorAuthorization !== applicant.authorizationOrDecisionHolderIdentification) {
      this.exitAction()
    }
  }

  static contextType = PermitPageContext

  exitAction() {
    return exitPermit(this.props.router)
  }

  backAction() {
    const backPath = this.getPreviousStepPath()
    this.props.router.push(backPath)
  }

  handleSubmit(event, path) {
    if (event) {
      event.preventDefault()
    }


    const { formApi } = this.context
    formApi.submitForm(path)
      .then(() => {
        const errors = get(formApi.getFormState(), 'errors', {})
        const errorIds = []

        for (const errorBlock of Object.keys(errors)) {
          if (typeof errorBlock === 'string' && errorBlock === APPLICATION_BASIS_TITLE_KEY) {
            errorIds.push(`${errorBlock}`)
            break
          }

          if (typeof errorBlock === 'string' && errorBlock === APPLICATION_BASIS_TAT_INFO_ELEMENT_CODE) {
            errorIds.push(`${errorBlock}`)
            break
          }

          if (typeof errorBlock === 'string' && errorBlock === APPLICATION_BASIS_TAP_INFO_ELEMENT_CODE) {
            errorIds.push(`${errorBlock}`)
            break
          }

          if (typeof errorBlock === 'string' && errorBlock === APPLICATION_BASIS_ADDITIONAL_INFORMATION_TEMPORARY_CODE) {
            errorIds.push(`${errorBlock}`)
            break
          }

          for (const error of Object.keys(errors[errorBlock])) {
            errorIds.push(`${errorBlock}.${error}`)
          }
        }
        focusFirstElementByIdOrName(errorIds)
      })
  }

  deleteApplicationCallback(response) {
    const { router } = this.props
    if (!response.errors || response.errors.length === 0) {
      router.replace(`/${PERMITS_ROUTE_PATH}`)
    }
  }

  cancelApplication() {
    const { router } = this.props
    const { setPageLoader } = this.context
    const referenceNumber = getReferenceNumberAndVersionFromRoute(router).referenceNumber
    const version = getReferenceNumberAndVersionFromRoute(router).version
    setPageLoader(true)
    return api.cancelApplication(referenceNumber, version)
      .then(() => {
        showNotification({
          level: 'success',
          message: messages.applicationCancelled,
          autoDismiss: true,
        })
        router.replace(`/${PERMITS_ROUTE_PATH}`)
        setPageLoader(false)
        this.setState({
          copyApplicationValue: null,
        })
        return null
      })
      .catch((err) => {
        setPageLoader(false)
        throw err
      })
  }

  copyApplication() {
    const { setPageLoader, structure } = this.context
    const { router } = this.props
    const { version, referenceNumber } = getReferenceNumberAndVersionFromRoute(this.props.router)
    setPageLoader(true)
    return api.copyApplication(referenceNumber, version, structure.code)
      .then((response) => {
        if (!has(response, 'applicationTypeCode')) {
          throw new Error('no applicationTypeCode')
        }
        return api.saveDraft(response, response.referenceNumber, response.version)
      })
      .then((response) => {
        setPageLoader(false)
        // eslint-disable-next-line max-len
        router.replace(`/${PERMITS_ROUTE_PATH}?redirect=${PERMIT_APPLICATION_ROUTE_PATH}/${response.referenceNumber}:${response.version}/${INIT_ROUTE_PATH}`)
      })
      .catch(() => {
        showNotification({
          level: 'error',
          message: messages.copyErrors,
          modal: true,
          title: messages.errorModalTitle,

        })
        setPageLoader(false)
      })
  }

  showInfoSendingNotAllowed() {
    const { router } = this.props
    const { structure, applicationState } = this.context
    const group = structure.groups &&
      find(structure.groups, { code: router.params.pathId })

    return applicationState === PERMIT_APPLICATION_STATUS_DRAFT &&
      group && group.code === 'summary' &&
      !userIsAuthorizedToSendApplication()
  }

  sendApplication() {
    if (getCurrentUserApplicationsRepresentativeStatus(this.props.formApi)) {
      throw new Error('USER IS NOT PRIVILEDGED TO SEND APPLICATION')
    }


    this.context.sendApplication().then((response) => {
      if (response) {
        this.setState({
          showApplicationSentModal: true,
          sentApplicationReferenceNumber: response.referenceNumber,
        })
      }
    })
  }

  renderSummaryRelatedButtons(formErrors) {
    const { structure, activeGuarantee, guaranteeApplication, formApi } = this.context
    const { isAttachmentInProgress, isPerson } = this.props
    const summaryButtons = []
    const isMissingGuarantee = isGuaranteeRequired(structure) && !activeGuarantee && !guaranteeApplication
    const canSendApplication = userIsAuthorizedToSendApplication() && (this.context.customerHasEori || isPerson)

    summaryButtons.push(
      <Button
        key="btnDeleteApplication"
        id="deleteApplication"
        id-qa-test="button-delete-application"
        bsStyle="default"
        disabled={isAttachmentInProgress}
        onClick={() => this.setState({ showDeleteModal: true })}
      >
        <Icon name="delete" /><FormattedMessage {...messages.deleteApplicationDraft} />
      </Button>
    )

    if (canSendApplication) {
      const currentApplicantStatus = getCurrentUserApplicationsRepresentativeStatus(formApi)

      summaryButtons.push(
        <Button
          id-qa-test="button-send-application"
          key="btnSendApplication"
          id="sendApplication"
          bsStyle="primary"
          disabled={!isEmpty(formErrors) || isAttachmentInProgress || isMissingGuarantee}
          onClick={() => {
            if (currentApplicantStatus) {
              this.setState({
                showRepresentativeModal: true,
              })

              return
            }

            this.sendApplication()
          }}
        >
          <FormattedMessage {...messages.submitForm} />
        </Button>
      )
    }

    return summaryButtons
  }

  renderInfoElement(infoElement, formApi) {
    const {
      locale,
      codesets,
      helpTexts,
      isPerson,
    } = this.props

    return <InfoElement
      isPerson={isPerson}
      key={infoElement.code}
      locale={locale}
      codesets={codesets}
      helpTexts={helpTexts}
      infoElement={infoElement}
      formApi={formApi}
      renderField={renderField}
      saveDraft={this.props.saveDraft}
    />
  }

  render() {
    const {
      isPerson,
      codesets,
      locale,
      isUploadingAttachment,
      router,
      showApplicationTabActionButtons,
      applicant,
    } = this.props
    const {
      sentApplicationReferenceNumber,
      showApplicationSentModal,
    } = this.state
    const {
      initialValues,
      formApi,
      structure,
      customerHasAEO,
      applicationState,
      isPrintMode,
      pageLoaderVisible,
    } = this.context

    const infoElements = filter(structure.infoElements, { group: router.params.pathId })
    const group = structure.groups && find(structure.groups, { code: router.params.pathId })
    const StepComponent = getStepComponent(router.params.pathId)

    let issueNumber = ''
    if (initialValues.issueNumber !== undefined) {
      issueNumber = initialValues.issueNumber.issueNumber || ''
    }

    const { referenceNumber, version } = getReferenceNumberAndVersionFromRoute(router)

    return (
      <ErrorBoundary key="formArea">
        {showApplicationSentModal &&
          <Modal
            show
            showCancel={false}
            showContinue
            titleMessage={messages.sentApplicationTitle}
            continueMessage={messages.sentApplicationContinue}
            cancelDisabled={pageLoaderVisible}
            continueDisabled={pageLoaderVisible}
            onClickContinue={() => {
              // eslint-disable-next-line max-len
              router.replace(`/${PERMITS_ROUTE_PATH}?redirect=${PERMIT_APPLICATION_ROUTE_PATH}/${sentApplicationReferenceNumber}:0/init`)
              this.setState({ showApplicationSentModal: false })
            }}
            onEscKey={() => {
              this.setState({ showApplicationSentModal: false })
            }}
            loading={pageLoaderVisible}
            focusButton
          >
            <p className="lead"><FormattedMessage {...messages.applicationSent} /></p>
          </Modal>
        }
        {this.state.showDeleteModal &&
          <DeleteApplicationModal
            referenceNumber={referenceNumber}
            version={version}
            onClickContinue={this.deleteApplicationCallback}
            onClickCancel={() => {
              this.setState({ showDeleteModal: false })
              focusElementById('deleteApplication')
            }}
            loading={pageLoaderVisible}
          />
        }
        {this.state.showCancelModal &&
          <Modal
            show={this.state.showCancelModal}
            showCancel
            cancelMessage={messages.cancelModalCancel}
            showContinue
            titleMessage={{
              ...messages.cancelModalTitle,
              values: {
                issueNumber,
              },
            }}
            continueMessage={messages.cancelModalProceed}
            cancelDisabled={pageLoaderVisible}
            continueDisabled={pageLoaderVisible}
            onClickContinue={() => this.cancelApplication()}
            onClickCancel={() => {
              this.setState({ showCancelModal: false })
              focusElementById('cancelApplication')
            }}
            loading={pageLoaderVisible}
            focusDisableButton
          >
            <span
              style={{
                float: 'left',
                display: 'block',
                width: '70px',
                height: '70px',
                fontSize: '3rem',
              }}
            >
              <Icon name="delete" />
            </span>
            <p className="lead">
              <FormattedMessage
                {...messages.cancelModalMessage}
                values={{
                  issueNumber,
                }}
              />
            </p>
          </Modal>
        }
        <form
          onSubmit={(event) => {
            this.handleSubmit(event, '')
          }}
        >
          {StepComponent &&
            <StepComponent
              isPerson={isPerson}
              structure={structure}
              formApi={formApi}
              locale={locale}
              renderField={renderField}
              renderInfoElement={this.renderInfoElement}
              applicationState={applicationState}
              saveDraft={this.props.saveDraft}
              customerHasAEO={customerHasAEO}
              codesets={codesets}
              applicant={applicant}
            />
          }
          {!StepComponent && infoElements &&
            <Grid fluid>
              {chain(infoElements)
                .filter('visible')
                /*
                *  If permit data has been created with delegate user, the data has it and we want to show it
                *  otherwise, we want to hide it
                */
                .filter(infoElement => filterRepresentativeIfNoValue(infoElement, formApi))
                .map(infoElement => this.renderInfoElement(infoElement, formApi))
                .value()
              }
            </Grid>
          }
          {this.showInfoSendingNotAllowed() &&
            <div className={css.containerInfo}>
              <div className={css.containerInfoInner}>
                <Icon name="info" className={css.iconInfo} />
                <div className={css.containerInfoText}>
                  <FormattedMessage {...messages.sendingRequiresAuthorization} />
                </div>
              </div>
            </div>
          }
          {includes([PERMIT_APPLICATION_STATUS_DRAFT], applicationState) &&
            <PermitButtonToolbar
              backAction={this.backAction}
              showBack={this.getPreviousStepPath()}
              showNext={has(group, 'navigation.next') &&
              isBoolean(group.navigation.next) ? group.navigation.next : true}
              disabled={isUploadingAttachment}
              exitAction={this.exitAction}
              bordered
            >
              {group && group.code === 'summary' && (
                <React.Fragment>
                  <RepresentativeNotification
                    notificationType={NOTIFICATION_TYPE.MODAL}
                    showModal={this.state.showRepresentativeModal}
                    onClickCallback={() => this.sendApplication()}
                    onClickCancel={() => {
                      this.setState({ showRepresentativeModal: false })
                      focusElementById('sendApplication')
                    }}
                  />
                  {this.renderSummaryRelatedButtons(formApi.errors)}
                </React.Fragment>
              )}
            </PermitButtonToolbar>
          }
          {!isPrintMode && !showApplicationTabActionButtons && <PermitButtonToolbar
            pathToBack={null}
            showBack={false}
            showNext={false}
            exitAction={this.exitAction}
            bordered
          >
            {includes([
              PERMIT_APPLICATION_STATUS_REGISTERED,
              PERMIT_APPLICATION_STATUS_VERIFICATION,
              PERMIT_APPLICATION_STATUS_ACCEPTED,
            ], applicationState) &&
              userHasAdminAuthorization() &&
              <Button
                key="btnCancelApplication"
                id="cancelApplication"
                className="pull-right"
                bsStyle="default"
                onClick={() => this.setState({ showCancelModal: true })}
                id-qa-test="button-cancel-application"
              >
                <FormattedMessage {...messages.cancelApplication} />
              </Button>
            }
            {includes([
              PERMIT_APPLICATION_STATUS_NOT_ACCEPTED,
              PERMIT_APPLICATION_STATUS_WITHDRAWN,
              PERMIT_APPLICATION_STATUS_NON_FAVOURABLE,
            ], applicationState) &&
              userIsAuthorizedToCopyApplication() &&
              <Button
                key="btnCopyApplication"
                className="pull-right"
                bsStyle="default"
                onClick={() => this.copyApplication()}
                id-qa-test="button-copy-application"
              >
                <Icon name="add" />
                <FormattedMessage {...messages.copyApplication} />
              </Button>
            }
          </PermitButtonToolbar>}
        </form>
      </ErrorBoundary>
    )
  }
}
