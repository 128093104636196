/**
 * Authentication requirement for React components
 * @module containers/AuthenticatedComponent
 */

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { defineMessages } from 'react-intl'
import { find } from 'lodash'
import Loader from 'src/components/Loader'
import JumbotronMessage from 'src/components/JumbotronMessage'
import { UID_TYPE, AUTH_TYPES } from 'src/constants'
import { getSelectedAuthorization, redirectToLoginService } from '../utils/auth'
import LoginHelp from '../components/LoginHelp'

// Define authentication related intl messages
const messages = defineMessages({
  authenticationRequired: {
    id: 'auth.authenticationRequired',
    description: 'Message shown to unauthenticated users when authentication is required',
    defaultMessage: 'Authentication is required to view this content',
  },
  authorizationRequired: {
    id: 'auth.authorizationRequired',
    description: 'Message shown to unauthenticated users when authentication is required',
    defaultMessage: 'Permission is required to view this content',
  },
  forbiddenForForeigners: {
    id: 'auth.forbiddenForForeigners',
    description: 'Message shown to foreigners is they are not allowed to use the service.',
    // eslint-disable-next-line max-len
    defaultMessage: 'You are not authorised to view this content. The foreign citizen identification service (Finnish Authenticator) is not in use in this service.',
  },
  title: {
    id: 'auth.information.title',
    description: 'Title shown with authentication information',
    defaultMessage: 'Online services',
  },
  loadingLogin: {
    id: 'auth.login.loading',
    description: 'Loading description shown while redirecting to login service',
    defaultMessage: 'Redirecting to login service...',
  },
  loginButton: {
    id: 'auth.login.button',
    description: 'Login button shown with login required information',
    defaultMessage: 'Login',
  },
  authenticating: {
    id: 'auth.authenticating',
    description: 'Loading description shown while authenticating user',
    defaultMessage: 'Authenticating...',
  },
})

function getFullPath(location) {
  const { hash, pathname, search } = location
  return pathname.concat(search, hash)
}

function isForeignFriendlyPath(path, friendlyPaths) {
  const hasFriendlyPaths = Array.isArray(friendlyPaths) && friendlyPaths.length > 0
  return hasFriendlyPaths && !!find(friendlyPaths, p => path.indexOf(p) === 0)
}

function isBusiness(authorizationType) {
  return authorizationType === AUTH_TYPES.business
}

/**
 * Higher-order authenticated component creator
 * @param {Object} Component - React child component to wrap with authentication
 * @param {function} checkAuthorization - function used in asserting user authorizations for current service
 * @return {Object} authenticated higher-order component connected to Redux authentication state
 */
export default function requireAuthentication(Component, checkAuthorization, redirectToLogin = false) {
  function AuthenticatedComponent(props) {
    const {
      isAuthenticated, isAuthenticating, selectedAuthorizationObject, locale, router,
      foreignFriendlyPaths, isForeigner, selectedAuthorization,
    } = props
    const fullPath = getFullPath(router.location)
    const authorizationType = selectedAuthorizationObject ? selectedAuthorizationObject.type : null
    const isForeignerAndServiceForbidden =
      (isForeigner && !isForeignFriendlyPath(router.location.pathname.substr(1), foreignFriendlyPaths)) ||
      (isForeigner && isForeignFriendlyPath(router.location.pathname.substr(1), foreignFriendlyPaths)
        && !isBusiness(authorizationType))

    const isAuthorized = checkAuthorization(selectedAuthorizationObject, selectedAuthorization)

    if (isAuthenticating) {
      return <Loader blocking message={messages.authenticating} />
    }
    if (isAuthenticated && isAuthorized && !isForeignerAndServiceForbidden) {
      return <Component {...props} />
    }
    if (redirectToLogin && !isAuthenticated) {
      redirectToLoginService(locale, fullPath)
      return <Loader blocking message={messages.loadingLogin} />
    }
    if (isAuthenticated && isForeignerAndServiceForbidden) {
      return (
        <JumbotronMessage
          showDisruptionNotices
          titleMessage={messages.title}
          contentMessage={messages.forbiddenForForeigners}
          iconName="info"
          fullpage
        />
      )
    }
    if (isAuthenticated && !isAuthorized) {
      return (
        <JumbotronMessage
          showDisruptionNotices
          titleMessage={messages.title}
          contentMessage={messages.authorizationRequired}
          iconName="info"
          fullpage
        />
      )
    }
    return (
      <LoginHelp
        titleMessage={messages.title}
        onClickButton={() => redirectToLoginService(locale, fullPath)}
        locale={locale}
        fullPath={fullPath}
      />
    )
  }

  const mapStateToProps = state => ({
    locale: state.locale,
    username: state.auth.username,
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating,
    authorizations: state.auth.authorizations,
    selectedAuthorization: state.auth.selectedAuthorization,
    selectedAuthorizationObject: getSelectedAuthorization(),
    isForeigner: state.auth.uidType === UID_TYPE.FOREIGNER,
    foreignFriendlyPaths: state.auth.foreignFriendlyPaths,
  })

  return withRouter(connect(mapStateToProps)(AuthenticatedComponent))
}
