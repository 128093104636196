import React from 'react'
import { Col, Button, Row, ButtonToolbar } from 'react-bootstrap'
import { FormattedMessage, defineMessages } from 'react-intl'
import { Form } from 'react-form'
import { get, includes } from 'lodash'
import Icon from 'src/components/Icon'
import Modal from 'src/components/Modal'
import showNotification from 'src/utils/notifications'
import { focusFirstInputElementWithError } from 'src/utils'
import { checkFormValues } from '../utils'
import deleteMessages from '../messages'
import permitMessages from '../../../messages'
import { fetchCustomerName } from '../../BusinessIdInput'
import { getBootstrapConfig } from '../../../permitHelper'

const messages = {
  ...permitMessages,
  ...deleteMessages,
}

// This function performs necessary actions before the actual save()
async function beforeSave(formApi, formData, infoElement) {
  let dataNew = formData

  const errMessages = defineMessages({
    companyNotFound: {
      id: 'permits.companyNotFound',
      description: '',
      defaultMessage: 'Yritystä ei löytynyt',
    },
  })

  if (infoElement.code === 'useOfMessageDeclarantProvider' && formData.messageDeclarantProviderIdentification) {
    try {
      const bootstrapConfig = getBootstrapConfig()
      const url = bootstrapConfig.tm_customer_ext_url
      const customerName = await fetchCustomerName(formData.messageDeclarantProviderIdentification, url)
      dataNew = {
        ...dataNew,
        'messageDeclarantProviderIdentification-name': customerName,
      }
      formApi.setValue('messageDeclarantProviderIdentification-name', customerName)
    } catch (e) {
      formApi.setValue('messageDeclarantProviderIdentification-name', '')
      formApi.setError('messageDeclarantProviderIdentification', errMessages.companyNotFound)
    }
  }
  return dataNew
}

// TODO: Needs refactoring, similar logic in multiple locations
export default class ItemForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNew: null,
    }
    this.onClickSave = this.onClickSave.bind(this)
    this.save = this.save.bind(this)
    this.cancel = this.cancel.bind(this)
    this.connectFormApi = this.connectFormApi.bind(this)
  }

  onClickSave(event) {
    this.formApi.submitForm(event)
      .then(focusFirstInputElementWithError)
  }

  cancel(e) {
    if (e) {
      e.preventDefault()
    }

    if (this.state.isNew) {
      this.setState({ isNew: false })
    } else {
      this.props.cancelEditing()
    }
  }

  async save(formData) {
    const { addNew, updateItem, selectedItem, infoElement } = this.props
    const { isNew } = this.state

    const modFormData = await beforeSave(this.formApi, formData, infoElement)

    const isValid = checkFormValues(
      this.formApi,
      infoElement,
    )

    if (isValid) {
      if (isNew) {
        addNew(modFormData)
        this.afterDoneSaving()
      } else if (selectedItem) {
        updateItem(selectedItem, modFormData)
        this.afterDoneSaving()
      }
    } else {
      focusFirstInputElementWithError()
    }
  }

  afterDoneSaving() {
    this.cancel()
    showNotification({
      level: 'success',
      message: messages.doneSavingItem,
      autoDismiss: true,
    })
  }

  connectFormApi(formApi) {
    this.formApi = formApi
  }

  renderFields(formApi) {
    const {
      infoElement,
      renderField,
      combined,
    } = this.props

    const infoElementFields = infoElement.fields

    return infoElementFields.map((field, i) =>
      <Row key={`row-${field.code}`}>
        <Col xs={12} key={`col-${field.code}`}>
          {renderField({
            field,
            formApi,
            autoFocus: !combined && i === 0,
          })}
        </Col>
      </Row>
    )
  }

  renderPlaceOfUseFields(formApi) {
    const {
      infoElement,
      renderField,
    } = this.props

    const infoElementFields = infoElement.fields
    const visibleFields = infoElementFields.filter(field => field.visible)
    const nameUseField = visibleFields.find(({ code }) => code === 'nameUse')
    const streetAddressUseField = visibleFields.find(({ code }) => code === 'streetAddressUse')
    const postCodeUseField = visibleFields.find(({ code }) => code === 'postCodeUse')
    const cityUseField = visibleFields.find(({ code }) => code === 'cityUse')

    const staticFields = [
      nameUseField,
      streetAddressUseField,
      postCodeUseField,
      cityUseField,
    ].map(field => field && field.code)

    const otherFields = visibleFields
      .filter(field => !includes(staticFields, field.code))

    return (
      <React.Fragment>
        <Row key="itemRow-1">
          <Col xs={12}>
            {nameUseField && renderField({
              field: nameUseField,
              formApi,
              autoFocus: true,
            })}
          </Col>
        </Row>
        <Row key="itemRow-2">
          <Col xs={12}>
            {streetAddressUseField && renderField({
              field: streetAddressUseField,
              formApi,
            })}
          </Col>
        </Row>
        <Row key="itemRow-3">
          <Col xs={12} sm={6}>
            {postCodeUseField && renderField({
              field: postCodeUseField,
              formApi,
            })}
          </Col>
          <Col xs={12} sm={6}>
            {cityUseField && renderField({
              field: cityUseField,
              formApi,
            })}
          </Col>
        </Row>
        {otherFields.map((field, i) => (
          <Row key={`row-${i}`}>
            <Col xs={12} key={`col-${i}`}>
              {renderField({
                field,
                formApi,
              })}
            </Col>
          </Row>
        ))}
      </React.Fragment>
    )
  }

  render() {
    const {
      selectedItem,
      error,
      infoElement,
      shouldRenderCustomPlaceOfUseForm,
    } = this.props

    const { isNew } = this.state
    const infoElementCode = this.props.infoElement.code
    const isMessageDeclarantProvider = (infoElementCode === 'useOfMessageDeclarantProvider')

    let titleMessage = isNew ? messages.newItemHeading : messages.itemEditHeading
    if (isMessageDeclarantProvider) {
      titleMessage = messages.messageDeclarantProviderIdentificationTitle
    }
    const saveButtonMessage = isMessageDeclarantProvider ? messages.saveMessageDeclarantProviderIdentification : messages.save
    const cancelButtonMessage = isMessageDeclarantProvider ? messages.cancelMessageDeclarantProviderIdentification : messages.cancel

    const slidingContentStyle = {
      transition: 'transform 200ms ease-in-out',
    }

    const modalButtons = [
      <Button
        key="modalCancel"
        className="pull-left"
        onClick={this.cancel}
        id-qa-test="button-cancel"
      >
        <FormattedMessage {...cancelButtonMessage} />
      </Button>,
      <Button
        key="modalSaveAndClose"
        bsStyle="primary"
        className="pull-right"
        onClick={this.onClickSave}
        id-qa-test="button-save"
      >
        <Icon name="addnew" />
        <FormattedMessage {...saveButtonMessage} />
      </Button>,
    ]

    if (get(this.props, 'combined', false)) {
      const formApi = this.props.formApi
      this.connectFormApi(formApi)
      return shouldRenderCustomPlaceOfUseForm ? this.renderPlaceOfUseFields(formApi) : this.renderFields(formApi)
    }

    return (
      <div>
        <ButtonToolbar>
          <Button
            id={`new-row-${infoElement.code}`}
            aria-describedby={this.props.buttonDescriptionId}
            bsStyle="primary"
            onClick={() => this.setState({ isNew: true })}
            id-qa-test={`button-add-${this.props.infoElement.code}`}
          >
            <Icon name="add" /><FormattedMessage {...messages.add} />
          </Button>
        </ButtonToolbar>
        <Modal
          show={Boolean(isNew || selectedItem)}
          size="lg"
          showCancel={false}
          showContinue={false}
          onEscKey={this.cancel}
          titleMessage={titleMessage}
          animation={false}
          contentRef={(ref) => { this.modalContentRef = ref }}
          customFooterButtons={modalButtons}
        >
          <div
            ref={(ref) => { this.contentRef = ref }}
            style={slidingContentStyle}
            onTransitionEnd={this.onScrollToCN8TransitionEnd}
          >
            <Form
              getApi={this.connectFormApi}
              defaultValues={selectedItem && selectedItem}
              onSubmit={this.save}
              render={formApi =>
                <form>
                  {shouldRenderCustomPlaceOfUseForm ? this.renderPlaceOfUseFields(formApi) : this.renderFields(formApi)}
                </form>
              }
            />
            <Row>
              <Col md={8} sm={12}>
                {error && error.id &&
                  <div className="text-danger text-right" role="alert" id-qa-test="label-error">
                    <FormattedMessage {...error} />
                  </div>
                }
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    )
  }
}
