import moment from 'moment'
import download from 'downloadjs'
import { get, findIndex } from 'lodash'
import showNotification from 'src/utils/notifications'
import api from '../api'
import {
  PERMITS_ROUTE_PATH,
  PERMIT_APPLICATION_ROUTE_PATH,
} from '../constants'
import messages from './messages'
import {
  APPLICATION_ALREADY_EXISTS,
  APPLICATION_DRAFT_ALREADY_EXISTS,
  APPLICATION_TYPE_AMENDMENT_ALIAS,
  APPLICATION_TYPE_REVOCATION_ALIAS,
  applicationTypeHelpTexts,
} from './constants'

export const parseString = value =>
  (value === '' ? null : value)

export const getReferenceNumberAndVersionFromRoute = props =>
  getReferenceNumberAndVersionFromParams(props.params)

export const getReferenceNumberAndVersionFromParams = ({ permitId }) => {
  const [referenceNumber, rawVersion] = permitId.split(':')
  const encodedReferenceNumber = encodeReferenceNumber(rawVersion)
  return {
    referenceNumber,
    version: encodedReferenceNumber,
  }
}

export const encodeReferenceNumber = referenceNumber =>
  (referenceNumber ? referenceNumber.replace(/[/]/g, '@_@') : referenceNumber)

export const decodeReferenceNumber = referenceNumber =>
  (referenceNumber ? referenceNumber.replace(/@_@/g, '/') : referenceNumber)

export const getAdjacentStepPath = (structure, params, indexDelta) => {
  const adjacentIndex = findIndex(structure.groups, { code: params.pathId }) + indexDelta
  if (structure.groups[adjacentIndex]) {
    const permitReferenceNumberAndVersion = getReferenceNumberAndVersionFromParams(params)
    return getStepPath(structure.groups[adjacentIndex].code, permitReferenceNumberAndVersion)
  }
  return ''
}

export const getStepPath = (stepName, { referenceNumber, version }) =>
  // eslint-disable-next-line max-len
  `/${PERMITS_ROUTE_PATH}/${PERMIT_APPLICATION_ROUTE_PATH}/${referenceNumber}:${version}/${stepName}`

export const fetchDecisionFile = (params) => {
  const {
    permitData,
    setLoader,
    clearLoader,
    formatMessage,
  } = params
  setLoader()
  return api.fetchDecisionFile(
    permitData.authorizationOrDecisionHolderIdentification,
    encodeReferenceNumber(permitData.referenceNumber),
    permitData.authorisationTypeCode,
    permitData.version
  )
    .then((response) => {
      const decisionDate = moment(permitData.decisionDate).format('YYYY-MM-DD')
      // eslint-disable-next-line max-len
      const filename = `${formatMessage(messages.decisionFilename)}_${encodeReferenceNumber(permitData.referenceNumber)}_${permitData.authorisationTypeCode}_${decisionDate}.pdf`
      download(`data:application/pdf;base64,${encodeURI(response.content)}`, filename, 'application/pdf')
      clearLoader()
    })
    .catch((err) => {
      clearLoader()
      if (get(err, 'value.code') === 'notFound') {
        showNotification({
          level: 'error',
          message: messages.permitDoesNotHavePDF,
          modal: true,
          title: messages.errorModalTitle,
        })
      }
    })
}

export const getErrorsForInfoElement = (errors, infoElement) => {
  const foundErrors = []

  if (errors && infoElement) {
    infoElement.fields.forEach((field) => {
      const error = errors[infoElement.code] && errors[infoElement.code][field.code]

      if (error) {
        foundErrors.push(error)
      }
    })
  }

  return foundErrors
}

export const companySelectionChanged = (currentProps, nextProps) => {
  if (!currentProps.selectedOrganizationId) {
    return false
  }

  return nextProps.selectedOrganizationId !== currentProps.selectedOrganizationId
}

export const getPermitTypeFromReferenceNumber = (referenceNumber) => {
  if (!referenceNumber) { return undefined }
  const isVVI = !!referenceNumber.match(/FI\/\d+\/\d+/)
  return isVVI ? 'VVI' : referenceNumber.substring(2, 5)
}

export function handleApplicationExistsError(applicationType, errorValue, texts, defaultError) {
  const { existingApplication } = errorValue
  const draftExists = errorValue.code === APPLICATION_DRAFT_ALREADY_EXISTS
  const applicationExists = errorValue.code === APPLICATION_ALREADY_EXISTS

  if (draftExists || applicationExists) {
    const modalTexts = applicationTypeHelpTexts[applicationType]

    if (applicationType === existingApplication?.type) {
      return {
        error: false,
        state: {
          applicationExistsModalTitle: get(texts, modalTexts.errorModalTitle, ''),
          applicationExistsModalText: get(texts, draftExists ? modalTexts.draftErrorModalBody : modalTexts.applicationErrorModalBody, ''),
          applicationExistsModalContinue: get(texts, draftExists ? 'yesOpenDraft' : 'yesOpenApplication', ''),
          showApplicationExistsModal: true,
          existingApplicationReferenceNumber: existingApplication?.referenceNumber,
          existingApplicationVersion: existingApplication?.version,
          showContinueButton: true,
        },
      }
    }

    if (applicationType === APPLICATION_TYPE_AMENDMENT_ALIAS && existingApplication?.type === APPLICATION_TYPE_REVOCATION_ALIAS) {
      const errorMessageKey = draftExists ? 'amendDecisionRevocationDraftExistsErrorMessage' : 'amendDecisionRevocationApplicationExistsErrorMessage'
      return {
        error: false,
        state: {
          applicationExistsModalTitle: get(texts, modalTexts.errorModalTitle, ''),
          applicationExistsModalText: get(texts, errorMessageKey, ''),
          showApplicationExistsModal: true,
          showContinueButton: false,
        },
      }
    } else if (applicationType === APPLICATION_TYPE_REVOCATION_ALIAS && existingApplication?.type === APPLICATION_TYPE_AMENDMENT_ALIAS) {
      const errorMessageKey = draftExists ? 'revokeDecisionAmendDraftExistsErrorMessage' : 'revokeDecisionAmendApplicationExistsErrorMessage'
      return {
        error: false,
        state: {
          applicationExistsModalTitle: get(texts, modalTexts.errorModalTitle, ''),
          applicationExistsModalText: get(texts, errorMessageKey, ''),
          showApplicationExistsModal: true,
          showContinueButton: false,
        },
      }
    }
    return {
      error: true,
      errorDetails: {
        level: 'error',
        message: get(texts, modalTexts.growlErrorMessage, modalTexts.growlErrorMessage),
        autoDismiss: true,
      },
    }
  }
  return defaultError
}
