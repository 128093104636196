import React from 'react'
import { HelpBlock, FormControl } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Field } from 'react-form'
import { get, find, has, isObject } from 'lodash'
import Radio from './Radio'
import ErrorMessage from './ErrorMessage'
import { getValidationErrors, required } from '../../utils/validation'

function RadioList(props) {
  const { name } = props
  const errorFieldId = `${name}-error`

  const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
    if (relatedTarget === null) {
      return false
    }

    let node = relatedTarget.parentNode

    while (node !== null) {
      if (node === currentTarget) {
        return true
      }

      node = node.parentNode
    }

    return false
  }

  const onBlur = (e) => {
    const inputBlur = get(props, 'input.onBlur')

    if (!focusInCurrentTarget(e) && inputBlur) {
      inputBlur()
    }
  }


  return (
    <div onBlur={onBlur}>
      {props.options && props.options.map(item =>
        <Radio
          key={`radio-${item.value}`}
          {...props}
          option={item}
          checked={props.input.value === item.value}
          aria-describedby={errorFieldId}
          externalBlurControl
        />
      )}
    </div>
  )
}

export class RadioListWithField extends React.Component {
  constructor(props) {
    super(props)
    this.validate = this.validate.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
  }

  componentWillUnmount() {
    const { name, formApi } = this.props
    formApi.setError(name, undefined)
  }

  handleValidationAndSetFieldError = (value) => {
    const { input, formApi } = this.props
    const fieldValidationErrors = getValidationErrors(value, input, this.validate)
    formApi.setError(input.name, fieldValidationErrors)
  }

  validate = (value) => {
    const { validation } = this.props.input
    if (get(validation, 'mandatory')) {
      const requiredCheck = required(value)
      if (validation.mandatory && requiredCheck) {
        return requiredCheck
      }
    }
    return null
  }

  handleValidation = (value) => {
    const { input } = this.props
    return getValidationErrors(value, input, this.validate)
  }

  // eslint-disable-next-line class-methods-use-this
  renderStatic = (selectOptions, value) => {
    const item = find(selectOptions, option => option.value === value)
    let title = '--'
    if (has(item, 'title')) {
      if (isObject(item.title)) {
        title = <FormattedMessage {...item.title} />
      } else {
        title = item.title
      }
    }
    return (
      <FormControl.Static id-qa-test={`label-${this.props.input.name}`}>{title}</FormControl.Static>
    )
  }

  render() {
    const { input, options, locale, label } = this.props

    const errorFieldId = `${this.props.input.name}-error`
    return (
      <Field
        validate={input.validate && this.handleValidation}
        field={input.name}
        locale={locale}
      >
        {(fieldApi) => {
          const { value, error, setValue, setTouched } = fieldApi
          this.fieldApi = fieldApi
          if (options) {
            return (
              <div>
                {input.static && this.renderStatic(options, value)}
                {!input.static && options.map(item =>
                  <Radio
                    key={`radio-${item.value}`}
                    {...this.props}
                    input={{
                      ...input,
                      onChange: (newValue) => {
                        setValue(newValue)
                        if (input.onChange) {
                          input.onChange(newValue)
                        }
                      },
                      onBlur: () => {
                        setTouched()
                        if (input.validate) {
                          this.handleValidationAndSetFieldError(value)
                        }
                      },
                    }}
                    inline={this.props.input.inline}
                    error={error}
                    option={item}
                    checked={value === item.value}
                    aria-describedby={errorFieldId}
                  />
                )}
                {error &&
                  <HelpBlock id={errorFieldId}>
                    <ErrorMessage error={error} fieldName={label} />
                  </HelpBlock>
                }
              </div>
            )
          }
          return <span />
        }}
      </Field>
    )
  }
}

export default RadioList
