import logger from 'src/utils/logger'
import { apiCall } from 'src/utils/http'

export function get(url) {
  return apiCall(url, { method: 'GET' }, { }, undefined, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}

export function post(url, body = {}) {
  return apiCall(url, { method: 'POST', body: JSON.stringify(body) }, { }, undefined, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}

export function put(url, body = {}) {
  return apiCall(url, { method: 'PUT', body: JSON.stringify(body) }, { }, undefined, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}

export function deleteMethod(url) {
  return apiCall(url, { method: 'DELETE' }, { }, undefined, false)
    .catch((error) => {
      logger.error('API call failed', error)
      throw error
    })
}

