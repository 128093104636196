import { injectReducer } from 'src/reducers/root'
import { PERMITS_ROUTE_PATH } from './constants'
import permitRoute from './permit'

export default store => ({
  // permits list is the default component
  path: PERMITS_ROUTE_PATH,
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: "permitsList" */ './container'),
      import(/* webpackChunkName: "permitsList" */ './reducer'),
      import(/* webpackChunkName: "permitsList" */ './list/reducer'),
    ]).then(([containerModule, commonReducerModule, listReducerModule]) => {
      injectReducer(store, { key: 'permits.common', reducer: commonReducerModule.default })
      injectReducer(store, { key: 'permits.list', reducer: listReducerModule.default })
      next(null, containerModule.default)
    })
  },
  childRoutes: [
    permitRoute(store),
  ],
})
