import { IAT_ROUTE_PATH } from './constants'

export default () => ({
  path: IAT_ROUTE_PATH,
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: 'iat' */ './container'),
    ]).then(([containerModule]) => {
      next(null, containerModule.default)
    })
  },
})
