import {
  INDIVIDUAL_GUARANTEE_ROUTE_PATH,
  PAYMENT_PENDING_ROUTE,
  PAYMENT_CANCEL_ROUTE,
  STOP_PAGE_ROUTE,
} from './constants'

export default () => ({
  path: INDIVIDUAL_GUARANTEE_ROUTE_PATH,
  getComponents(nextState, next) {
    Promise.all([
      import(/* webpackChunkName: "guarantee" */ './container'),
      import(/* webpackChunkName: "guarantee" */ './routes/pending/container'),
      import(/* webpackChunkName: "guarantee" */ './routes/cancel/container'),
      import(/* webpackChunkName: "guarantee" */ './routes/stop/container'),
    ]).then((modules) => {
      let ContainerComponent
      if (nextState.routes.find(it => it.path.startsWith(PAYMENT_PENDING_ROUTE))) {
        ContainerComponent = modules[1].default
      } else if (nextState.routes.find(it => it.path.startsWith(PAYMENT_CANCEL_ROUTE))) {
        ContainerComponent = modules[2].default
      } else if (nextState.routes.find(it => it.path.startsWith(STOP_PAGE_ROUTE))) {
        ContainerComponent = modules[3].default
      } else {
        ContainerComponent = modules[0].default
      }
      next(null, ContainerComponent)
    })
  },
  getChildRoutes(location, next) {
    Promise.all([
      import(/* webpackChunkName: "guarantee" */ './routes/pending'),
      import(/* webpackChunkName: "guarantee" */ './routes/cancel'),
      import(/* webpackChunkName: "guarantee" */ './routes/stop'),
    ]).then((modules) => {
      let ContainerComponent
      if (location.location.pathname.indexOf(PAYMENT_PENDING_ROUTE) > -1) {
        ContainerComponent = modules[0].default
      } else if (location.location.pathname.indexOf(PAYMENT_CANCEL_ROUTE) > -1) {
        ContainerComponent = modules[1].default
      } else if (location.location.pathname.indexOf(STOP_PAGE_ROUTE) > -1) {
        ContainerComponent = modules[2].default
      }
      next(null, [ContainerComponent])
    })
  },
})
